import React from 'react';


export class SvgIntro extends React.Component{  

  render(){
    return(
      
    <svg version="1.1" id="intro" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="510px" height="540px" viewBox="2 -90 510 540" enableBackground="new 2 -90 508 540" >        
     <g id="Calque_2">
       <path fill="none" stroke="#FFFFFF" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
         M98.174,53.665c-6.79,2.167-18.091,14.245-19.963,20.882c-3.18,11.271-4.321,16.601-4.321,16.601
         c-4.335-17.915,3.18-31.209,31.209-39.879s62.999,12.137,49.416,65.887c-13.583,53.754-59.846,67.631-69.815,67.919
         c-19.54,0.565-19.191-6.945-19.191-6.945c23.187-73.097,38.146-110.101,38.146-110.101s-3.19-0.999-5.066,3.335
         c-1.879,4.335-29.909,84.814-32.51,94.642c-2.601,9.828-5.635,9.828,0.189,14.755c0,0,8.659,11.453,39.581-1.836
         c30.922-13.295,44.905-57.275,46.06-74.18c1.154-16.906,6.359-36.703-18.392-52.142"/>
       <path fill="none" stroke="#FFFFFF" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
         M367.567,141.847c-13,20.75-25.693,29.632-31.193,33.382s-11.307,3.368-12.307-4.882s7.537-34.061,19.875-42.875
         c29.455-21.044,26.125,7.75,26.125,7.75c2.25-6.5,1.35-14.497-8.65-17.247s-38.485,34.16-40.85,37.622
         c-2.365,3.462-23,29.375-34,18.875s1.625-41.75,4.5-46.25s13.417-9.655,10.625-6c-9.28,12.151-21.642,41.946-41,51.25
         c-16.125,7.75-19.375-5.75-14.75-14.125s9.625-13.5,9.375-22.625s-6.257-11.552-14.25-15c-6.375-2.75-5.125-10.75-1.625-13.133
         c3.5-2.383,2.375,4.258-1.875,14.383c-6.457,15.382-18.125,32.5-27.375,35s-17.875-4.625-14.5-24.375s8.814-6.856,5.125,4.5
         c-8,24.625-26.624,36.632-31.75,37.625c-5.126,0.993-8.116-6.208-6.5-17.625c1.617-11.417,7.342-23.94,19-33.375
         c11.659-9.435,16.25-4.514,16.25-4.514c-6-8.986-42,15.014-39,44.014s39.5-2.25,44-38c0,0-4.75-9.75-6,4c0,0-5,25.25,10.75,26.5
         s26.271-23.661,33.5-46.25c2-6.25-10.5,6.5,0,13.25s15.75,16,4.5,32.75c-9.111,13.566-2,24.25,15.25,17.645
         c16.587-6.352,37.5-46.145,42.5-52.895s-6.75-0.5-10.5,6s-18,36.789-6,45.895c12,9.105,28-9.645,31.75-15.895
         s26.1-41.997,42.85-39.247s9.9,14.497,8.65,17.247c0,0,7.85-15.511-8.65-17.247s-40.85,24.997-40.35,45.247s14.25,17.75,29-0.75
         s16.25-22.25,16.25-22.25s-15.479,20.475-9.25,29.75c5.875,8.75,15.641,7.401,26.75-4.75c16-17.5,16-33.5,16-33.5
         s6.75,17.27-8.5,42.125c0,0,29.75-48.125,45-45.125s12.062,11.517,2.25,32.625c-4.25,9.145,4.482,16.122,14,11.77
         c11.25-5.145,18.75-24.645,18.75-24.645s-9.367,28.703-28.5,25.375c-8.625-1.5-6.625-10.875-1-26.125s-3.629-14.919-9.123-15.711
         c-13.875-2-32.502,28.98-42.877,41.105c0,0,9.492-11.021,13.625-27.02c4.875-18.875-3.625-14.375-3.625-14.375"/>
       <path fill="none" stroke="#FFFFFF" strokeWidth="7" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
         M312.802,98.266c3.143-0.667,5.428-3.427,4.854-5.522c-0.569-2.095-3.235-2.952-7.33-0.381c-4.096,2.571-1.282,6.481,1.429,5.903"
         />
       <path fill="none" stroke="#FFFFFF" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
         M42.722,206.265c20-3.333,80.667-7.333,238,0s195.333-4.132,195.333-4.132"/>
       <path fill="none" stroke="#FFFFFF" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
         M61.737,228.08c-1.958,0.625-5.217,4.108-5.757,6.022c-0.917,3.25-1.246,4.787-1.246,4.787c-1.25-5.166,0.917-9,9-11.5
         s18.167,3.5,14.25,19c-3.917,15.501-17.258,19.503-20.133,19.586c-5.635,0.163-5.534-2.003-5.534-2.003
         c6.687-21.079,11-31.75,11-31.75s-0.92-0.288-1.461,0.962c-0.542,1.25-8.625,24.458-9.375,27.292s-1.625,2.834,0.055,4.255
         c0,0,2.497,3.303,11.414-0.53s12.949-16.516,13.282-21.391s1.834-10.584-5.304-15.036"/>
       <path fill="none" stroke="#FFFFFF" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
         M83.234,256.722c0,0,8.622-1.415,10.5-6.25c1.352-3.479,1.166-7.667-4.417-4.417s-11.25,13.668-5.583,17.084
         c5.667,3.416,12.968-4.981,15.917-10.25c1.167-2.084-1-3.084-1.083,2.833s0.667,9.14,4.333,6.611c3.666-2.529,5.833-6.861,7-9.611
         s2.25-4.083,3.167-4.833s-2.114-1.859-2.167,0.5c-0.052,2.359-0.667,8.999,1.917,10.166c2.584,1.167,6.583-1.75,9.167-2.417
         s8.417-4,9.083-7s0.667-5.916-4.583-3.416s-8.333,9.582-7.917,13.666s4.417,5.334,8.25,3.667
         c3.833-1.667,11.833-10.333,14.417-13.333s7.75-10.584,9.083-16.583c1.333-6-1.5-6.083-3.5-2.083s-8.667,14.75-9.917,21.333
         s-1.666,12.639,4.417,9.944c6.083-2.695,5.795-10.057,9.345-11.528c1.405-0.583-3.929-0.166-3.512,7.584s5.583,3.75,7.333,2.083
         s9.5-12.499,3.833-14.833c-5.667-2.334-7.055,15.104,4.333,12.083c4.083-1.083,5.833-11.334,6-12.25s-1.978-0.65-2.167,3.75
         c-0.188,4.4-3.707,20.778-5.833,24.084c-0.75,1.166,1,2.424,3.333-7.5c2.333-9.924,4.084-12.141,5.917-14.612
         c1.833-2.471,8.75-8.471,10.417-0.888s-6.705,13.349-11.417,12.583"/>
       <path fill="none" stroke="#FFFFFF" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
         M179.9,274.085c0,0,8.333-21.445,8.333-28.196c0-3.167-2-0.583-2.25,4.25s-5.567,23.166-5.45,24.583
         c0.116,1.417,6.252-22.425,10.033-25.083c6.167-4.334,9.5-2.834,9.5,3.166s-7.584,11.584-12.417,10.084"/>
       <path fill="none" stroke="#FFFFFF" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
         M206.067,256.556c1.5-0.25,9.083-2.666,10.25-6.833s0.25-7.084-6.5-2.334s-7,13.251-4.5,15.334s9.25,0.999,13.75-4.834
         s5-6.917,5.333-7.75s-1.344-3.021-3.417,6.75c-1.75,8.25,5.917,8.334,9.75-1.583c3.443-8.907,3.833-4.833,3.25-3.5
         s-5.25,8.638-2.083,10.527s9.657-1.086,14.749-16.361c1.583-4.75-3-2.917,0.917,1s4.75,3.751,1.5,11.334
         c-3.25,7.583,7.833,4.084,11.25-3.25"/>
       <path fill="none" stroke="#FFFFFF" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
         M289.384,228.07c-4.25,7.917-12.985,32.488-12.485,35.988s2.79,1.583,1.833-2.834c-0.551-2.546,9.167-31.25,11.25-32.667
         s8.333,0.75,11-0.833c0,0-3.084,2.167-6,1.25s-9.5-2.333-14.25,0.167s-4.874,5.834-3.854,8.167c0,0,0.228-7.371,5.003-8.683"/>
       <path fill="none" stroke="#FFFFFF" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
         M277.482,245.974c0,0,4.333-2.084,13.75-0.75c0,0-7.858-1.584-11.833-0.728C277.482,244.91,277.482,245.974,277.482,245.974"/>
       <path fill="none" stroke="#FFFFFF" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
         M295.816,255.974c0,0,3.75-8.416,4.583-11.333s-2.75-0.335,0.583,2.041s4.667,3.876,2.5,9.626s-1.333,8.051,3.667,6.025
         s10.416-11.609,10.416-11.609s-3.916,2.084-4.082,8.417c-0.168,6.333,5.832,3.583,8.416,0.583s8.25-12.118,3.25-14.601
         s-7.377,14.551,1.994,13.351c6.506-0.833,8.506-9.499,7.256-9.166c-0.205,0.055-0.58,0.842-0.58,0.842s-1.748,9.472-3.836,11.491
         c0,0,8.584-11.667,13.5-12.25s4.832,3.917,2.082,8.75s3.957,8.604,9.418-2.167c5.5-10.851,10.166-19.833,10.666-21.083
         s-4,3.383-6.75,11.083c-2.75,7.7-6.326,18.826,1.168,17.727c6.082-0.893,9.166-8.81,9.166-8.81"/>
       <path fill="none" stroke="#FFFFFF" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
         M355.233,246.682c0,0,5.082,1.791,13.5,0"/>
       <path fill="none" stroke="#FFFFFF" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
         M373.317,254.724c0,0,2.75-1.75,12.666-1.167c2.5,0.146,2.916-0.119,2.916-0.119S377.983,256.055,373.317,254.724"/>
       <path fill="none" stroke="#FFFFFF" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
         M417.809,230.093c0.006,3.551-1.914,9.878-3.076,7.964c-0.816-1.344,0.582-0.487,1.916-3.368s2.334-6.382-1.584-7.548
         c-3.916-1.167-7.832,2.105-9.082,6.833c-1.918,7.248,2.416,11,2.416,11s-11.082,2-10.666,12.25s8.666,9.604,13,6.552
         c4.332-3.052,10.189-10.433,11.25-13.385c0.416-1.159-1.666-0.5-2,1s-2.752,9.666-3.084,10.416
         c-0.334,0.75,4.584-5.201,6.834-7.726s5.332-6.19,8.332-4.44s1.918,4.25,0,9.25c-1.916,5,2.834,4.25,5.168,2.75
         c2.332-1.5,6.203-10.197,8.787-12.788s4.004-4.298,9.295-2.962c0,0-6.758-2.844-10.924,5.573c-4.168,8.417-2.492,13.927,3.924,9.26
         c6.418-4.667,7.166-11.333,12.334-19.083c5.166-7.75,5.334-10.5,5.916-12.583c0.584-2.083-1.5-0.083-3,3.917
         s-8.334,18.833-8.416,24.583c-0.084,5.75,3.75,7.75,10.416-2.833"/>
       <path fill="none" stroke="#FFFFFF" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
         M95.817,240.41c0,0,2.625-3.688,3.5-4.938s1.941-0.114,0.312,0.875c-1.629,0.989-3.018,2.943-3.018,2.943"/>
       <path fill="none" stroke="#FFFFFF" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
         M9.817,184.864C15.834,55.561,122.84-56.315,253.633-56.315c134.669,0,243.829,109.162,243.829,243.823
         c0,134.663-109.16,243.823-243.829,243.823c-134.655,0-243.816-109.162-243.816-243.823"/>
       <path fill="none" stroke="#FFFFFF" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
         M282.668,341.395l-27.571,13.604l-27.589-13.434l27.589,13.434"/>
       <path fill="none" stroke="#FFFFFF" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
         M227.508,341.565"/>
     </g>
   </svg>
      
    )
  }
}