import React from 'react';
import { NavLink } from 'react-router-dom';/*NavLink is for */

export class Navigation extends React.Component{

  render() {    
    let currentPage =  window.location.pathname;
    let link = currentPage === '/' ? <li><NavLink to="/historic" >Historique <i className="arrow arrow--right" ></i></NavLink></li> 
                                   : <li><NavLink to="/" ><i className="arrow arrow--left" ></i> Home</NavLink></li>;
    
    return (        
          <ul className="header__navigation" >
            {link}
          </ul>        
    );
  }

}

export default Navigation;