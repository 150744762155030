import React from 'react';

export class AutoDrawSvg extends React.Component{  
  constructor(props){
    super(props);
    this.state = {
      svg: {}
    }
  }


  componentDidMount(){
    
    this.setState({ svg: this.props.svg })          
    
  }


  componentDidUpdate(prevProps, prevState){
    if( this.state.svg !== prevState.svg ){

      // Init drawing svg
      this.props.selector.map( (e) =>{
        return this.initLines(e.element, e.speedDraw, e.displaySpeed);
      })

    }
  }


  /**
   * 
   * @param {Array} linesObject 
   * @param {Int} displaySpeed 
   * @param {Int} speedDrawing 
   */
  async autoDraw(linesObject, displaySpeed, speedDrawing){
    
    // Init start
    let start = null;            
    
    // -------------------------
    // Draw lines animation
    let drawLinesAnimation = (timestamp) => {
      if (!start) start = timestamp;
      let progress = timestamp - start;        
      let progressPercent = 0;

      if (progress <= displaySpeed) {
        // -------------------------
        // Get line ID on current % time
        progressPercent = Math.round(progress * 100 / displaySpeed);
        let linesCount  = linesObject.length;
        let lineId      = Math.round(  progressPercent * linesCount / 100 );
        // -------------------------

        // -------------------------
        // If exist then execute the drawing function's stored in the line object
        if ( linesObject[lineId] ){            
          linesObject[lineId].draw();
        }
        // -------------------------

        window.requestAnimationFrame(drawLinesAnimation);

      } // End if

      // Security : Show all at the end animation 
      //console.log(progressPercent)      
      if ( progressPercent >= 99 ){        
        setTimeout(() => {
          
          for (let l = 0; l < linesObject.length; l++) {           
            let line = linesObject[l].element;          
            line.style.strokeDashoffset = 0 ;
          }    

        }, speedDrawing);
              
      }

    } // End drawLinesAnimation                 
    // -------------------------


    // -------------------------
    // Start animation
    window.requestAnimationFrame(drawLinesAnimation); 
    // -------------------------
    
  } // End autoDraw



  initLines(lines, speedDrawing = 150, displaySpeed = 5000){

    var linesObject = []; // Init lines objects array
    var nodes = Array.prototype.slice.call( lines ); // Get the node objects in order to get positions    
    
    lines.forEach(line => {
      // Get position object
      let positionId = nodes.indexOf( line )

       // Init the drawable lines
       let length = line.getTotalLength();
       line.style.strokeDasharray  = length;
       line.style.strokeDashoffset = length;
      
      // Build line objects with the drawing function
      linesObject[positionId] = { 
                      element: line, 
                      
                      // -------------------------
                      // Draw function
                      draw: async function (){

                              // Init main vars
                              let line   = this.element;
                              let length = line.getTotalLength();                                         
                              let start  = null;
      
                              // -------------------------
                              // Draw line animation
                              let drawLine = (timestamp) => {
                                if (!start) start = timestamp;
                                let progress = timestamp - start;                                  

                                if (progress <= displaySpeed) {
                                  // -------------------------
                                  // Init drawing line speed
                                  let scrollpercent  = Math.round(progress * 100 / displaySpeed);
                                  let scrollingSpeed = scrollpercent * speedDrawing // Calcul speed of drawing
                                  let draw           = scrollingSpeed <= length ? scrollingSpeed : length; // Condition if end of drawing
                                  // -------------------------

                                  // -------------------------
                                  // Drawing
                                  line.style.strokeDashoffset = length - draw;
                                  window.requestAnimationFrame(drawLine);
                                  // -------------------------
                                } // End if

                              } // End drawLine
                              // -------------------------

                              window.requestAnimationFrame(drawLine);                            

                      } // End function draw
                      // -------------------------

                    } // End object lines     
    });

    // Start drawing objects
    this.autoDraw(linesObject, displaySpeed, speedDrawing);

  }


  render(){
    let Svg = this.props.svg
    return(
      <Svg />
    )
  }


}