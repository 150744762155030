import React from 'react';
import {History} from '../modules/svg/containers/History';
import {Header} from './Header';
import {WorkPoints} from './WorkPoints';


export class Historic extends React.Component{ 
  render(){

    // Set title
    document.title = "Derrecalde : Historique";
    
    return(
      <div className="body" >  
        <Header />     
        <main>
          <WorkPoints />
          <History className="svg--historic" />
        </main>
      </div>
    )
  }
}

export default Historic;