import React from "react";
import { Call } from "../components/Call"

export async function GetPhotos(url, size = false){     
   
  // Init call url
  let call   = new Call();

  // =========================
  // Call function api
  return await call.callApi(url).then(
    
    (respond) => {

      // Init data results
      var photos     = [];
      
      // -------------------------
      // Respond API
      if (respond !== false){
        
        // -------------------------
        // Success        

        // Set homeMade index insted of auto index from map(e,index) because of empty callback in data
        var u = 0;         
        respond.map( (element) => {
          let img         = element._embedded['wp:featuredmedia'];  
          let wpTerms     = element._embedded['wp:term'][0];  
          let wpTags      = element._embedded['wp:term'][1];  
          let gallery     = 'acf' in element && element.acf.gallery !== 0 ? element.acf.gallery : false;  
          let content     = element.content.rendered;      
          let galleryImgs = [];
          let categories  = [];
          let tags  = [];
          
          if ( gallery ) {
            
            // Build imgs section
             gallery.map( (img) =>{

                if ( img.picture.sizes.large ){
                  galleryImgs.push({ 
                                    src: img.picture.sizes.large,
                                    caption: img.title
                                  });
                }
                return true
              });

          } // End if

          // Build category section
          wpTerms.map( (term) => {
            if ( term ){
              categories.push({ 
                                id: term.id,
                                name: term.name,
                                slug: term.slug
                              });
              
            }
            return true
          });

          // Build tags section
          wpTags.map( (term) => {
            if ( term ){
              tags.push({ 
                                id: term.id,
                                name: term.name,
                                slug: term.slug
                              });
            }
            return true
          });
          
          img.map( (e) => {
            
            // Get photos size desired
            let thumbnail = (size === 'thumbnail')  ? e.media_details.sizes.thumbnail 
                                                    : e.media_details.sizes.medium_large 
                                                      ? e.media_details.sizes.medium_large 
                                                      : e.media_details.sizes.large 
                                                        ? e.media_details.sizes.large 
                                                        : e.media_details.sizes.full;
                               
            if ( thumbnail ) {
                
              let defaultLightbox = (e.media_details.sizes.medium_large) ? e.media_details.sizes.medium_large.source_url : thumbnail.source_url;

              // Build main data section
              photos[u] = {
                src     : thumbnail.source_url,
                caption : thumbnail.file,
                content : content,
                width   : thumbnail.width,
                height  : thumbnail.height,
                lightbox: galleryImgs.length > 0 ? galleryImgs : [{src: defaultLightbox, caption: e.title.rendered}],
                title   : e.title,
                category: categories,
                tags: tags,
                id: u
              }
              u++;

            } // End if
            return true
          }) // End images map  

          return true
                  
        }) // End dataResult map

        return photos;     
        

        // -------------------------        
        
      } else {

        // -------------------------
        // Error
        return <p>{'Error on API call :('}</p> 
        // -------------------------

      }
      // End respond Api
      // -------------------------

    }); 
    // End callBackPhotos Call Api
    // =========================  

}

