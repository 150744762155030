import React from 'react';
import './App.css';
import {Routes} from './components/Routes';



function App() {

  return (
    <Routes />
  )

}

export default App;
