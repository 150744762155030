import React from "react";
import { Call } from "../../../components/Call";


export class Filters extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      categories: []
    };

    this.handleHover = this.handleHover.bind(this);
  }

  handleHover(e){
    let elToMove  = document.querySelectorAll('.gallery__filters > ul > li > .bar');
    let widthBars = document.querySelector('.gallery__filters > ul ').offsetWidth / elToMove.length;
    
    elToMove.forEach( (element, i) => {
      element.style.left     = widthBars * i+"px";
      element.style.maxWidth = widthBars+"px"      
    });

  } 

  componentDidMount(){
    // --- Store categories from Api call --- //
    
    // Init api calling catogories
    let call = new Call();
    call.callApi(process.env.REACT_APP_API_URL+'categories?include='+this.props.categories).then(
      
      (respond)=>{        
        if (respond !== false){
          // Success
          
          let categories = []

          // Store categories wp meta from api in categories
          respond.map( 
            (category) => {

              categories.push({
                name: category.name,
                slug: category.slug,
                id  : category.id
              }); 
              return true
            }
          );
            
          this.setState({
            categories: categories
          });

        } else {
          console.log('error')
        } // End if
      } // End respond

    );
  } // End componentDidMount

  render() {
    // --- Build categories list --- //

    // Init <li> to render
    const listFilters = this.state.categories.map(
      (filter)=>{

        return <li key={filter.id} data-slug={filter.slug} onMouseOver={this.handleHover} onClick={this.props.handleClick} >
                {filter.name}<div className="bar" ></div>
              </li>

      }
    );
      
    return (
      <div className="gallery__filters">        
        <ul>
        <li key="0" data-slug="all" className="iconfilter board" onClick={this.props.handleClick} onMouseOver={this.handleHover} >
          <div className="bar" ></div>
        </li>
          {listFilters}
        </ul>
      </div>
    );

  } // End render
} // End class Lightbox