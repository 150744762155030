import React from 'react';


export class SvgHistory extends React.Component{  

  render(){
    return(
      
        <svg id="draw" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100%" height="100%" viewBox="-380 200 1533 2587" preserveAspectRatio="xMaxYMin meet" >
        
          <g id="Calque_1_1_">

            <path fill="none" stroke="#FFFFFF" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" d="M-69.243,285.538
              c16.009,10.386,33.045,21.931,50.832,34.071"/>

               { /*  Bordeaux  */  }
                           
                <path fill="none" stroke="#FFFFFF" strokeWidth="10" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M-69.243,285.538
                  c16.009,10.386,33.045,21.931,50.832,34.071"/>
                <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M112.704,339.048
                  c1.483,1.579,2.34,3.431,2.34,5.414c0,5.76-7.214,10.429-16.111,10.429c-8.896,0-16.109-4.668-16.109-10.429
                  c0-1.889,0.775-3.662,2.137-5.189"/>
                <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M115.407,334.565
                  c5.164,2.953,8.421,7.271,8.421,12.083c0,8.9-11.146,16.115-24.896,16.115s-24.896-7.215-24.896-16.115
                  c0-4.646,3.039-8.833,7.898-11.775"/>
                <path fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinejoin="round" strokeMiterlimit="10" d="M99.603,343.115
                  c-0.018,0.035-0.294,0.117-0.294,0.117s-17.444-13.011-17.444-29.946s14.851-17.25,17.25-17.25l0.393,0.091
                  c2.4,0,17.25,0.315,17.25,17.25c0,15.581-14.938,28.006-17.299,29.855"/>
                <path fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinejoin="round" strokeMiterlimit="10" d="M99.379,300.67c4.656,0.078,8.406,3.877,8.406,8.552
                  c0,4.724-3.83,8.554-8.555,8.554s-8.555-3.83-8.555-8.554c0-4.725,3.83-8.554,8.555-8.554L99.379,300.67"/>
                <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinejoin="round" strokeLinecap="round" strokeMiterlimit="10" d="M120.475,331.947
                  c7.192,3.957,11.756,9.854,11.756,16.444c0,11.915-14.924,21.574-33.331,21.574c-18.406,0-33.33-9.659-33.33-21.574
                  c0-6.59,4.563-12.49,11.76-16.446"/>
                                           
            
              <path fill="none" className="titi" stroke="#FFFFFF" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                M-5.482,394.498c23.176-15.359,32.858-3.565,15.174,2.356l-2.137,1.525l-7.872,18.889c0,0-8.884,1.265-6.268,1.718
                c11.143,1.927,38.021-12.494,17.622-19.584"/>
              <path fill="none" className="titi" stroke="#FFFFFF" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                M12.686,384.954"/>
              <path fill="none" className="titi" stroke="#FFFFFF" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                M149.527,390.264c0,0-3.529,26.102,15.578,9.744c0,0,7.896-13.609,12.896,3.035s-18.5,17.644-24,18.144S27,421.733-20,440.71"/>
              <path fill="none" stroke="#FFFFFF" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                M160.48,389.075c0,0-16.568,18.113-20.681,17.101c-4.113-1.01,2.408-14.248,2.408-14.248l-5.166,11.259
                c-20.006,12.143-7.992-11.282-7.992-11.282l-4.387,12.961c-14.701,12.472-8.371-10.98-8.371-10.98l-1.734,2.223
                c-10.074-2.102-22.157,22.034-0.846,8.545"/>
              <path fill="none" stroke="#FFFFFF" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                M101.07,404.667c0,0-9.831,10.829-15.635,3.472c-5.804-7.356,12.113-17.901,9.427-10.125c-2.688,7.776-17.373,15.254-18.631,11.942
                c0,0-5.209,5.714,4.065-25.151l-8.001,22.14c0,0-7.081,12.219-8.058-2.231c-0.581-8.619,11.634-5.307,11.634-5.307"/>
              <path fill="none" stroke="#FFFFFF" strokeWidth="5" strokeLinecap="round" strokeMiterlimit="10" d="M83.207,381.538"/>
              <path fill="none" stroke="#FFFFFF" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                M62.098,407.126c0,0-3.583,5.387-9.135,8.205c-5.551,2.819,2.149-16.078,4.387-16.692c2.236-0.613-6.85,3.169-6.85,3.169
                s-1.854-2.228-5.29-1.558l0.781,2.191c1.795,5.631-3.86,9.987-3.86,9.987l1.462-4.023l-9.818,0.9c0,0,1.513-8.184-4.942-8.282
                c-6.453-0.097-11.299,15.288-1.522,17.158c5.785,1.105,6.465-8.875,6.465-8.875"/>
            

            <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinejoin="round" strokeLinecap="round" strokeMiterlimit="10" d="M115.734,334.759"/>                
            <path fill="none" stroke="#FFFFFF" strokeWidth="5" strokeLinejoin="round" strokeLinecap="round" strokeMiterlimit="10" d="M-18.67,301.531"/>
            <path fill="none" stroke="#FFFFFF" strokeWidth="5" strokeLinejoin="round" strokeLinecap="round" strokeMiterlimit="10" d="M46.85,301.115"/>
            
           { /*  Bordeaux  */  }

              <path fill="none" stroke="#FFFFFF" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" d="M235.213,465.939
                c17.848,6.341,36.332,12.065,55.217,16.857"/>
              <path fill="none" stroke="#FFFFFF" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" d="M394.027,497.733
                c21.865,0.508,43.711-0.854,65.209-4.524"/>
           { /*  velo   */  }
            <g id="Calque_2_5_">
              <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                M544.277,404.867c0.919-0.648,1.839-1.297,2.759-1.947c1.327-0.935,2.653-1.871,3.98-2.807c0.328-0.232,0.652-0.444,1.051-0.535
                c0.471-0.104,0.969-0.047,1.417,0.126c0.353,0.136,0.708,0.346,0.947,0.645c0.222,0.275,0.34,0.645,0.43,0.981
                c0.232,0.874,0.29,2.026-0.395,2.723c-0.749,0.763-1.425,1.604-2.083,2.447c-0.049,0.062-0.098,0.125-0.148,0.19
                c0.382,0.249,0.764,0.499,1.147,0.748c0.972,0.635,1.943,1.269,2.917,1.903c1.298,0.848,2.598,1.697,3.897,2.545
                c1.366,0.891,2.731,1.782,4.096,2.673c1.167,0.761,2.337,1.525,3.506,2.288c0.708,0.463,1.42,0.926,2.13,1.39
                c0.098,0.064,0.196,0.146,0.302,0.198c0.195,0.094,0.428,0.142,0.632,0.21c1.266,0.422,2.532,0.845,3.798,1.268
                c1.463,0.487,2.926,0.975,4.389,1.463c0.459,0.152,0.917,0.305,1.374,0.457c0.101,0.034,0.2,0.067,0.299,0.101
                c0.064,0.021,0.087-0.106,0.115-0.154c0.383-0.669,1.025-1.191,1.792-1.331c1.005-0.187,2.135,0.193,2.639,1.118
                c0.417,0.765,0.396,1.758-0.064,2.499c-0.247,0.395-0.615,0.701-1.045,0.878c-0.54,0.221-1.114,0.209-1.687,0.189
                c-0.959-0.035-1.469-0.886-1.685-1.726c-0.083-0.325-0.133-0.659-0.156-0.994c-0.004-0.055,0.025-0.213-0.035-0.232
                c-0.884-0.291-1.768-0.582-2.652-0.872c-1.519-0.5-3.039-0.998-4.557-1.498c-0.984-0.324-1.968-0.646-2.952-0.97
                c-0.029-0.01-0.059-0.02-0.089-0.028c-0.177,0.893-0.24,1.818-0.262,2.725c-0.047,1.979,0.183,3.989,0.844,5.862
                c0.381,1.08,0.909,2.109,1.6,3.022c0.625,0.828,1.385,1.536,2.205,2.166c1.479,1.133,3.169,2.002,4.955,2.538
                c1.863,0.558,3.845,0.742,5.77,0.438c2.112-0.333,4.086-1.26,5.736-2.615c0.977-0.802,1.837-1.739,2.59-2.752
                c0.713-0.961,1.347-1.99,1.821-3.092c0.739-1.717,1.069-3.593,0.888-5.458c-0.162-1.651-0.706-3.247-1.502-4.697
                c-0.728-1.328-1.662-2.542-2.732-3.616c-0.84-0.841-1.771-1.622-2.807-2.216c-0.336-0.193-0.692-0.375-1.067-0.484
                c-0.371-0.106-0.758-0.172-1.134-0.248c-1.214-0.248-2.447-0.409-3.688-0.42c-1.649-0.015-3.3,0.234-4.856,0.786
                c-1.961,0.696-3.719,1.866-5.193,3.329c-1.328,1.317-2.428,2.851-3.346,4.478c-0.848-0.523-1.696-1.046-2.544-1.569
                c-1.231-0.759-2.463-1.52-3.696-2.278c-0.159-0.099-0.318-0.197-0.478-0.294c-0.002,0.496-0.006,0.991-0.008,1.488
                c-0.008,1.237-0.015,2.474-0.023,3.711c-0.01,1.597-0.02,3.196-0.03,4.794c-0.009,1.579-0.02,3.16-0.029,4.739
                c-0.007,1.183-0.015,2.366-0.022,3.549c-0.001,0.324-0.004,0.649-0.004,0.973c-0.001,0.074-0.003,0.149-0.003,0.223
                c0,0.053,0.006,0.039,0.063,0.045c1.651,0.183,3.383,0.986,4.328,2.391c0.562,0.835,0.911,1.874,1.005,2.873
                c0.088,0.953-0.084,1.929-0.593,2.748c-0.596,0.953-1.561,1.569-2.597,1.952c-0.513,0.19-1.021,0.39-1.531,0.592
                c-1.521,0.603-3.035,1.225-4.549,1.849c-1.791,0.738-3.579,1.482-5.364,2.229c-1.594,0.667-3.186,1.336-4.778,2.004
                c-0.931,0.39-1.859,0.783-2.79,1.17c-0.195,0.08-0.384,0.137-0.599,0.104c-0.313-0.048-0.594-0.234-0.825-0.444
                c-0.582-0.532-0.982-1.295-1.178-2.054c-0.162-0.63-0.215-1.458,0.307-1.945c0.182-0.169,0.385-0.32,0.581-0.47
                c0.833-0.635,1.687-1.244,2.538-1.854c1.245-0.889,2.493-1.77,3.745-2.649c1.397-0.981,2.8-1.959,4.203-2.936
                c1.303-0.908,2.608-1.813,3.915-2.718c0.958-0.662,1.917-1.326,2.876-1.987c0.271-0.188,0.543-0.374,0.815-0.562
                c0.029-0.021,0.275-0.154,0.276-0.191c0.011-0.512,0.021-1.024,0.033-1.534c0.026-1.24,0.053-2.481,0.08-3.724
                c0.036-1.597,0.069-3.195,0.104-4.792c0.035-1.582,0.068-3.165,0.103-4.748c0.026-1.193,0.051-2.386,0.078-3.58
                c0.007-0.335,0.014-0.67,0.021-1.004c0.001-0.056,0.042-0.277-0.011-0.311c-1.015-0.646-2.031-1.292-3.045-1.937
                c-0.839-0.534-1.68-1.069-2.519-1.603c-0.027,0.417-0.053,0.834-0.08,1.25c-0.069,1.082-0.138,2.163-0.207,3.244
                c-0.095,1.489-0.19,2.977-0.285,4.465c-0.104,1.637-0.208,3.277-0.313,4.916c-0.097,1.534-0.196,3.066-0.292,4.599
                c-0.075,1.17-0.149,2.342-0.224,3.512c-0.035,0.552-0.07,1.104-0.105,1.656c-0.002,0.033-0.008,0.038,0.019,0.059
                c0.182,0.149,0.366,0.299,0.549,0.449c0.408,0.333,0.816,0.667,1.225,1.001c-0.431-0.284-0.864-0.568-1.295-0.851
                c-1.063-0.701-2.125-1.4-3.189-2.101c-1.348-0.887-2.696-1.774-4.045-2.661c-1.286-0.846-2.572-1.693-3.858-2.541
                c-0.879-0.577-1.758-1.156-2.635-1.733c-0.188-0.125-0.376-0.247-0.564-0.372c0.401-0.459,0.783-0.937,1.147-1.427
                c0.307-0.407,0.598-0.825,0.86-1.261c0.214-0.356,0.452-0.753,0.536-1.166c0.166-0.815-0.442-1.656-1.027-2.152
                c-0.306-0.258-0.669-0.48-1.066-0.557c-0.254-0.049-0.583-0.039-0.794,0.134c-0.983,0.811-1.967,1.621-2.952,2.429
                c-0.592,0.485-1.185,0.971-1.778,1.454c-0.375,0.306-0.82,0.573-0.933,1.078c-0.149,0.686,0.186,1.425,0.542,1.991
                c0.418,0.665,0.993,1.279,1.682,1.666c0.289,0.162,0.611,0.287,0.945,0.306c0.334,0.018,0.612-0.096,0.873-0.299
                c0.652-0.511,1.169-1.218,1.646-1.888c0.039-0.054,0.077-0.109,0.115-0.164c0.001,0,0.067,0.044,0.071,0.048
                c0.178,0.119,0.357,0.237,0.535,0.357c0.633,0.421,1.266,0.845,1.898,1.268c0.538,0.36,1.076,0.72,1.614,1.08
                c0.021,0.015,0.194,0.104,0.191,0.133c-0.004,0.054-0.008,0.108-0.012,0.162c-0.062,0.854-0.123,1.707-0.184,2.56
                c-0.107,1.487-0.215,2.973-0.321,4.46c-0.117,1.612-0.233,3.224-0.348,4.835c-0.089,1.226-0.177,2.455-0.265,3.682
                c-0.027,0.363-0.053,0.725-0.08,1.088c0.054-0.846,0.106-1.69,0.158-2.534c0.1-1.59,0.198-3.181,0.298-4.772
                c0.058-0.922,0.115-1.844,0.172-2.768c0.001-0.002,0.001-0.005,0.001-0.009c-1.547,0.168-3.097,0.561-4.563,1.07
                c-1.291,0.447-2.551,1.021-3.685,1.789c-0.668,0.452-1.247,0.996-1.755,1.624c-1.162,1.43-1.983,3.141-2.528,4.895
                c-0.616,1.983-0.877,4.099-0.631,6.167c0.126,1.058,0.387,2.101,0.8,3.085c0.427,1.019,1.021,1.945,1.714,2.801
                c1.313,1.625,2.965,2.979,4.85,3.885c1.833,0.88,3.863,1.318,5.896,1.25c2.188-0.073,4.322-0.729,6.223-1.803
                c2.02-1.14,3.786-2.717,4.986-4.716c1.06-1.765,1.649-3.786,1.767-5.837c0.111-1.918-0.177-3.865-0.847-5.666
                c-0.31-0.832-0.702-1.635-1.185-2.378c-0.634-0.976-1.434-1.831-2.337-2.559c-1.636-1.32-3.578-2.238-5.577-2.86
                c-0.727-0.225-1.473-0.43-2.227-0.547"/>
              <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                M564.324,439.76c0.635,0.902,1.272,1.804,1.909,2.707c0.12,0.169,1.88,2.666,1.882,2.665c0.67-0.474,1.345-0.949,2.013-1.421
                c-1.019,0.719-2.038,1.438-3.055,2.157c-0.259,0.182-0.516,0.364-0.774,0.546"/>
                </g>
               { /*  velo   */  }

              <path fill="none" stroke="#FFFFFF" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" d="M564.917,452.804
                c16.788-9.248,33.577-18.167,50.379-26.329"/>
              <path fill="none" stroke="#FFFFFF" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" d="M700.76,393.609
                c22.021-5.708,44.089-8.893,66.225-8.599"/>
           { /*  city  */  }
              <g id="Calque_2_5_city">
                <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                  M723.667,383c-0.591-7.438-1.182-14.876-1.772-22.314c-0.992-12.486-1.984-24.973-2.976-37.46c-0.306-3.853-0.612-7.707-0.918-11.56
                  c9.551-0.718,19.101-1.436,28.651-2.154c5.228-0.393,10.455-0.786,15.682-1.179c0.436,7.461,0.872,14.922,1.307,22.383
                  c0.73,12.501,1.46,25.002,2.191,37.503c0.223,3.816,0.446,7.631,0.668,11.447c-0.532-9.938-1.065-19.877-1.597-29.815
                  c-0.134-2.506-0.269-5.012-0.403-7.518c8.846,1.432,17.692,2.863,26.538,4.294c8.32,1.347,16.642,2.693,24.962,4.039
                  c-1.717,9.822-3.434,19.645-5.151,29.468c-0.282,1.619-0.565,3.237-0.849,4.856c1.564-8.963,3.129-17.927,4.693-26.89
                  c1.324-7.589,2.649-15.178,3.974-22.767c4.805,1.125,9.61,2.249,14.415,3.374c0.084,0.02,1.257,0.267,1.249,0.308
                  c-0.045,0.247-0.09,0.494-0.136,0.74c-0.268,1.461-0.534,2.921-0.802,4.382c-2.167,11.829-4.333,23.658-6.499,35.488
                  c-0.854,4.661-1.707,9.322-2.561,13.983c1.349-7.164,2.696-14.328,4.045-21.491c2.34-12.43,4.679-24.86,7.019-37.291
                  c0.867-4.609,1.735-9.218,2.603-13.827c9.447,3.12,18.895,6.24,28.342,9.36c0.6,0.198,8.049,2.394,7.964,2.761
                  c-0.605,2.626-1.212,5.252-1.818,7.879c-2.733,11.846-5.467,23.691-8.201,35.537c-1.96,8.494-3.921,16.989-5.881,25.483
                  c-0.024,0.104-0.048,0.209-0.072,0.313c1.467-6.373,2.934-12.745,4.4-19.117c2.877-12.501,5.754-25.002,8.631-37.503
                  c2.004-8.705,4.007-17.41,6.011-26.114c0.031-0.135,0.179-1.244,0.323-1.272c2.29-0.436,4.58-0.872,6.87-1.309
                  c2.347-0.447,4.693-0.894,7.04-1.341c0.34-0.064,1.091,0.487,1.383,0.628c8.896,4.289,17.794,8.578,26.69,12.866
                  c10.529,5.076,21.06,10.152,31.589,15.228c1.799,0.867,3.597,1.734,5.396,2.601c-3.014,5.003-6.027,10.005-9.041,15.008
                  c-6.438,10.688-12.878,21.377-19.316,32.066c-5.961,9.895-11.922,19.79-17.883,29.684c-1.809,3.003-3.617,6.005-5.426,9.008
                  c4.735-7.76,9.47-15.521,14.205-23.281c4.043-6.625,8.085-13.251,12.128-19.877c6.105,7.896,12.21,15.791,18.315,23.687
                  c1.45,1.876,2.901,3.752,4.352,5.628c-7.79,5.441-15.58,10.882-23.37,16.323c-4.654,3.251-9.31,6.502-13.964,9.753"/>
                <path fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M728.38,323.659
                  c2.415-0.198,4.83-0.396,7.245-0.594c0.175-0.014,0.389-0.122,0.405,0.078c0.042,0.518,0.085,1.035,0.127,1.553
                  c0.126,1.538,0.252,3.075,0.378,4.613c0.028,0.341,0.056,0.683,0.084,1.024c0.001,0.016-0.793,0.072-0.845,0.077
                  c-1.505,0.124-3.011,0.247-4.516,0.37c-0.281,0.023-2.268,0.336-2.29,0.066c-0.109-1.331-0.218-2.662-0.328-3.992
                  c-0.069-0.844-0.138-1.688-0.208-2.532"/>
                <path fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M746.453,323.027
                  c2.532-0.279,5.063-0.559,7.595-0.838c0.053-0.006,0.752,6.572,0.831,7.286c-2.541,0.28-5.081,0.561-7.621,0.841
                  c-0.244-2.208-0.488-4.417-0.731-6.626"/>
                <path fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M748.132,348.689
                  c2.415-0.198,4.83-0.396,7.245-0.593c0.608-0.05,0.421,0.273,0.469,0.854c0.101,1.225,0.201,2.449,0.301,3.673
                  c0.25,3.042,0.499,6.084,0.748,9.126c0.02,0.247,0.323,1.627,0.149,1.822c-0.088,0.099-0.722,0.059-0.846,0.069
                  c-0.73,0.06-1.459,0.12-2.189,0.179c-0.324,0.027-4.604,0.394-4.607,0.363c-0.106-1.292-0.212-2.585-0.318-3.877
                  c-0.279-3.403-0.558-6.806-0.837-10.208"/>
                <path fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M729.752,366.88
                  c2.415-0.198,4.83-0.396,7.245-0.594c0.175-0.014,0.389-0.122,0.405,0.078c0.042,0.518,0.084,1.035,0.127,1.553
                  c0.126,1.538,0.252,3.075,0.378,4.613c0.028,0.341,0.056,0.683,0.084,1.024c0.001,0.016-0.793,0.072-0.845,0.077
                  c-1.505,0.124-3.011,0.247-4.516,0.37c-0.281,0.023-2.268,0.336-2.29,0.066c-0.109-1.331-0.218-2.662-0.328-3.992
                  c-0.069-0.844-0.138-1.688-0.208-2.532"/>
                <path fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M858.921,341.725
                  c2.465,0.641,4.93,1.283,7.395,1.924c0.052,0.014-1.64,6.409-1.82,7.104c-2.474-0.644-4.947-1.287-7.421-1.93
                  c0.56-2.151,1.119-4.301,1.679-6.452"/>
                <path fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M832.65,377.667
                  c1.184,0.208,2.367,0.417,3.55,0.625c0.438,0.077,0.874,0.154,1.311,0.23c0.021,0.004-0.057,0.381-0.062,0.414
                  c-0.442,2.517-0.886,5.033-1.328,7.549c-0.478,2.713-0.955,5.427-1.432,8.14c-0.125,0.707,0.034,0.834-0.698,0.706
                  c-1.361-0.239-2.723-0.479-4.084-0.718c-0.076-0.013-0.153-0.027-0.23-0.041c0.901-5.123,1.802-10.246,2.703-15.369"/>
                <path fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M909.098,403.904
                  c1.324,0.754,2.649,1.507,3.975,2.261c0.36,0.206,0.016,0.457-0.169,0.782c-0.462,0.812-0.924,1.625-1.386,2.437
                  c-0.474,0.832-0.946,1.664-1.42,2.496c-0.127,0.224-0.302,0.858-0.578,0.701c-1.35-0.768-2.698-1.535-4.048-2.302
                  c1.099-1.932,2.197-3.864,3.296-5.795"/>
                <path fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M923.515,377.635
                  c1.357,0.693,2.714,1.387,4.071,2.08c0.37,0.189,0.037,0.456-0.133,0.789c-0.426,0.833-0.851,1.665-1.275,2.497
                  c-0.436,0.852-0.871,1.705-1.307,2.557c-0.117,0.229-0.264,0.87-0.547,0.726c-1.383-0.706-2.765-1.411-4.147-2.117
                  c1.011-1.979,2.022-3.958,3.033-5.938"/>
                <path fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M939.34,351.752
                  c1.316,0.769,2.633,1.537,3.949,2.305c0.354,0.207,0.022,0.438-0.165,0.758c-0.492,0.843-0.984,1.687-1.476,2.53
                  c-0.471,0.807-0.941,1.613-1.412,2.419c-0.129,0.222-0.299,0.828-0.572,0.669c-1.34-0.782-2.681-1.563-4.021-2.346
                  c1.12-1.92,2.24-3.839,3.36-5.759"/>
                <path fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M898.522,346.582
                  c1.471,0.401,2.94,0.802,4.411,1.204c0.4,0.109,0.129,0.439,0.03,0.799c-0.245,0.902-0.491,1.803-0.737,2.705
                  c-0.252,0.923-0.504,1.847-0.756,2.771c-0.067,0.249-0.079,0.906-0.387,0.822c-1.497-0.409-2.995-0.817-4.493-1.225
                  c0.585-2.144,1.171-4.289,1.756-6.433"/>
                <path fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M939.515,415.202
                  c1.294-0.806,2.588-1.611,3.882-2.417c0.354-0.22,0.41,0.204,0.607,0.521c0.494,0.793,0.988,1.587,1.482,2.38
                  c0.506,0.812,1.012,1.625,1.518,2.438c0.137,0.219,0.61,0.674,0.341,0.843c-1.317,0.82-2.636,1.641-3.953,2.462
                  c-1.175-1.887-2.35-3.773-3.524-5.66"/>
              </g>
           { /*  city  */  }

              <path fill="none" stroke="#FFFFFF" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" d="M872.015,415.743
                c19.697,11.681,35.616,24.921,48.288,39.362"/>
              <path fill="none" stroke="#FFFFFF" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" d="M958.418,532.523
                c4.063,19.615,5.071,40.026,3.784,60.718"/>

             { /*  city  */  }
                    <g id="Calque_2_5_city2">
                <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                  M787.467,789.519c-0.115-3.845-0.228-7.689-0.343-11.534c0.349,10.008,0.697,20.016,1.047,30.024
                  c0.087,2.524,0.175,5.047,0.263,7.571c9.113-2.547,18.227-5.092,27.34-7.638c8.57-2.395,17.144-4.79,25.714-7.185
                  c-2.688-9.608-5.377-19.218-8.065-28.826c-0.444-1.583-0.885-3.167-1.331-4.75c2.453,8.768,4.903,17.537,7.354,26.305
                  c2.075,7.424,4.15,14.849,6.227,22.274c4.917-1.73,9.836-3.46,14.754-5.191c0.087-0.03,1.289-0.425,1.277-0.465
                  c-0.068-0.241-0.139-0.482-0.21-0.724c-0.412-1.427-0.822-2.855-1.236-4.282c-3.341-11.559-6.683-23.119-10.023-34.678
                  c-1.316-4.555-2.632-9.11-3.948-13.665c2.062,6.996,4.122,13.992,6.184,20.987c3.578,12.139,7.152,24.278,10.729,36.417
                  c1.326,4.501,2.653,9.001,3.98,13.502c9.587-4.311,19.174-8.622,28.762-12.933c0.608-0.274,8.191-3.408,8.068-3.764
                  c-0.872-2.551-1.744-5.102-2.616-7.653c-3.937-11.503-7.87-23.009-11.807-34.513c-2.821-8.25-5.645-16.499-8.467-24.748
                  c-0.034-0.102-0.068-0.204-0.104-0.305c2.113,6.189,4.227,12.378,6.338,18.568c4.146,12.141,8.292,24.283,12.436,36.425
                  c2.888,8.454,5.772,16.91,8.658,25.364c0.047,0.131,0.302,1.222,0.455,1.232c2.432,0.147,4.865,0.295,7.298,0.443
                  c2.492,0.151,4.985,0.303,7.479,0.455c0.36,0.022,1.095-0.625,1.387-0.801c8.906-5.411,17.813-10.822,26.718-16.232
                  c10.54-6.404,21.081-12.807,31.621-19.211c1.799-1.094,3.599-2.187,5.4-3.281c-3.604-4.625-7.209-9.25-10.814-13.874
                  c-7.699-9.88-15.403-19.76-23.104-29.641c-7.129-9.146-14.26-18.293-21.389-27.439c-2.165-2.776-4.327-5.552-6.491-8.327
                  c5.655,7.165,11.31,14.331,16.964,21.498c4.827,6.118,9.655,12.236,14.482,18.354c5.66-8.667,11.321-17.333,16.981-26
                  c1.345-2.06,2.689-4.119,4.034-6.178c-8.637-4.461-17.271-8.922-25.906-13.384c-5.159-2.665-10.32-5.331-15.48-7.996"/>
                <path fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M887.15,804.295
                  c2.519-0.952,5.035-1.904,7.553-2.857c0.053-0.019-2.298-6.203-2.55-6.875c-2.525,0.956-5.052,1.91-7.577,2.865
                  c0.779,2.082,1.56,4.162,2.342,6.242"/>
                <path fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M856.427,771.652
                  c1.218-0.358,2.434-0.716,3.65-1.073c0.45-0.131,0.899-0.264,1.349-0.396c0.022-0.006-0.093-0.374-0.103-0.406
                  c-0.691-2.461-1.384-4.922-2.076-7.384c-0.745-2.653-1.491-5.308-2.237-7.961c-0.194-0.691-0.04-0.839-0.794-0.618
                  c-1.4,0.411-2.802,0.823-4.201,1.233c-0.077,0.023-0.158,0.047-0.237,0.07c1.409,5.011,2.816,10.021,4.225,15.032"/>
                <path fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M933.917,735.779
                  c1.315-0.921,2.631-1.842,3.946-2.762c0.358-0.251-0.025-0.459-0.247-0.761c-0.557-0.754-1.113-1.508-1.669-2.263
                  c-0.571-0.773-1.142-1.545-1.712-2.318c-0.152-0.208-0.394-0.82-0.668-0.628c-1.34,0.938-2.681,1.876-4.02,2.813
                  c1.324,1.794,2.647,3.588,3.971,5.382"/>
                <path fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M951.374,760.239
                  c1.355-0.865,2.709-1.729,4.064-2.594c0.37-0.235-0.003-0.46-0.211-0.771c-0.521-0.78-1.04-1.559-1.559-2.337
                  c-0.534-0.797-1.066-1.596-1.6-2.394c-0.143-0.215-0.354-0.837-0.638-0.657c-1.38,0.88-2.76,1.76-4.14,2.64
                  c1.235,1.853,2.473,3.705,3.711,5.558"/>
                <path fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M970.268,784.134
                  c1.305-0.933,2.611-1.868,3.916-2.802c0.352-0.251-0.017-0.44-0.241-0.738c-0.591-0.781-1.182-1.563-1.772-2.345
                  c-0.565-0.748-1.131-1.494-1.695-2.242c-0.154-0.206-0.389-0.791-0.658-0.597c-1.33,0.951-2.659,1.902-3.989,2.853
                  c1.345,1.78,2.692,3.558,4.036,5.338"/>
                <path fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M928.086,794.448
                  c1.501-0.587,3.001-1.173,4.5-1.76c0.408-0.159,0.095-0.455-0.041-0.802c-0.337-0.872-0.678-1.742-1.017-2.614
                  c-0.348-0.892-0.693-1.784-1.042-2.676c-0.093-0.24-0.165-0.896-0.478-0.774c-1.527,0.598-3.057,1.194-4.584,1.792
                  c0.808,2.07,1.613,4.143,2.421,6.212"/>
                <path fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M964.67,720.646
                  c1.426,0.643,2.853,1.286,4.278,1.928c0.388,0.176,0.409-0.255,0.586-0.597c0.444-0.855,0.888-1.711,1.332-2.567
                  c0.456-0.876,0.909-1.752,1.364-2.629c0.122-0.236,0.576-0.751,0.278-0.885c-1.451-0.655-2.903-1.31-4.354-1.964
                  c-1.056,2.035-2.11,4.069-3.167,6.105"/>
              </g>
               { /*  city  */  }

              <path fill="none" stroke="#FFFFFF" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" d="M936.735,685.846
                c-8.884,14.956-19.988,28.448-32.87,40.197"/>
              <path fill="none" stroke="#FFFFFF" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" d="M828.322,768.492
                c-17.534,5.312-36.123,8.42-55.321,9.046"/>
              <path fill="none" stroke="#FFFFFF" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" d="M681.604,774.448
                c-15.786-1.851-32.395-4.567-49.807-8.474"/>
              <path fill="none" stroke="#FFFFFF" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" d="M540.292,735.981
                c-13.453-5.818-27.256-12.414-41.404-19.886"/>
              <path fill="none" stroke="#FFFFFF" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" d="M419,666.538
                c-16.406-11.688-33.103-21.651-49.959-30.078"/>
              <path fill="none" stroke="#FFFFFF" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" d="M296.491,609.289
                c-16.582-4.31-33.138-7.417-49.548-9.494"/>
              <path fill="none" stroke="#FFFFFF" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" d="M141.022,599.311
                c-14.779,1.606-29.165,3.825-43.047,6.499"/>
              <path fill="none" stroke="#FFFFFF" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" d="M-2.272,634.648
                c-2.501,0.963-4.954,1.927-7.358,2.89c-10.19,4.082-20.804,9.105-31.647,15.032"/>
              <path fill="none" stroke="#FFFFFF" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" d="M-103.344,695.109
                c-13.186,10.969-26.184,23.124-38.661,36.4"/>
              <path fill="none" stroke="#FFFFFF" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" d="M-200.485,810.301
                c-8.223,14.403-15.658,29.531-22.102,45.342"/>

               { /*  collines  */  }
                <g id="calque_2_5_colines">
                  <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M267,596.5c-24.25-29.25-102.75-61.16-164.25,3.295"/>
                  <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M178.694,562.229C127.198,511.994-81.25,515-113.5,696.5"/>
                  <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M134.25,520.25c0,3.396,2.284,6.452,5.571,7.35c3.528,0.963,7.144-1.104,8.162-4.628c0.917-3.172-0.839-7.202-3.83-8.631
                    c-1.689-0.807-3.745-0.733-5.479-0.105c-1.717,0.621-4.038,2.361-4.038,4.4"/>
                  <path fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M134.25,513.761
                    c-1.168-1.168-2.337-2.337-3.505-3.505"/>
                  <path fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M149.5,527.875
                    c1.417,0.708,2.833,1.417,4.25,2.125"/>
                  <path fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M149.5,517.25
                    c1.417-1.163,2.833-2.326,4.25-3.489"/>
                  <path fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M135.25,528.938
                    c-0.917,1.271-1.835,2.542-2.753,3.812"/>
                  <path fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M141.022,509
                    c0-2.583,0-5.167,0-7.75"/>
                  <path fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M130.745,523.25
                    c-1.165,0-2.33,0-3.495,0"/>
                  <path fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M22.168,524
                    c-7.317,2.17-14.635,4.34-21.953,6.511c-11.833,3.509-23.667,7.019-35.5,10.528c-2.953,0.876-5.907,1.752-8.86,2.628
                    c0.967-1.87,2.114-3.662,3.349-5.364c2.146-2.959,4.778-6.01,8.176-7.545c0.753-0.34,1.549-0.593,2.369-0.71
                    c0.483-0.069,0.397-0.022,0.382-0.454c-0.037-1.074,0.023-2.154,0.162-3.219c0.434-3.331,1.775-6.84,4.817-8.607
                    c3.867-2.246,9.066-1.953,13.055-0.184c0.695,0.308,0.714,0.457,1.391,0.207c1.259-0.466,2.557-0.836,3.87-1.116
                    c3.836-0.82,8.388-0.988,11.676,1.504c0.693,0.525,1.57,0.104,2.429,0.004c1.649-0.191,3.326-0.25,4.978-0.071
                    c1.22,0.132,2.444,0.396,3.56,0.918c0.501,0.235,0.979,0.524,1.407,0.876c0.333,0.275,0.586,0.704,0.934,0.939
                    c1.309,0.885,2.617,1.771,3.925,2.656"/>
                </g>
               { /*  collines  */  }

              <path fill="none" stroke="#FFFFFF" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" d="M-244.189,931.117
                c-2.613,15.351-4.26,31.167-4.811,47.421"/>
              <path fill="none" stroke="#FFFFFF" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" d="M-231.21,1064.637
                c8.005,15.927,18.429,30.12,30.727,42.654"/>

                 { /*  Panneau  */  }
              <g id="Calque_2_6_">
                <path fill="none" stroke="#FFFFFF" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                  M-50.846,611.918c0.761,1.785,1.523,3.569,2.284,5.354c0.032,0.076,0.131,0.45,0.225,0.411c0.382-0.163,0.765-0.326,1.147-0.489
                  c0.923-0.394,1.845-0.787,2.768-1.181"/>
                <path fill="none" stroke="#FFFFFF" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                  M-41.647,615.458c-0.273-0.641-0.547-1.282-0.82-1.923c-0.179-0.419-0.274-0.923-0.652-1.19c-1.175-0.83-2.35-1.659-3.525-2.489
                  c1.25,0.891,2.499,1.782,3.748,2.673c0.234-1.524,0.469-3.048,0.703-4.572"/>
                <path fill="none" stroke="#FFFFFF" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                  M-35.293,612.118c4.22-1.799-0.103-8.173-3.292-4.864C-40.735,609.484-38.151,613.337-35.293,612.118
                  C-33.543,611.372-37.765,613.173-35.293,612.118z"/>
                <path fill="none" stroke="#FFFFFF" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                  M-30.196,610.574c-0.93-2.181-1.861-4.362-2.791-6.543c2.264,1.583,4.527,3.165,6.791,4.748c-0.919-2.156-1.839-4.312-2.759-6.467"
                  />
                <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                  M-19.75,610.398c-9.016,4.242-18.032,8.484-27.047,12.726c-1.558,0.733-3.117,1.466-4.675,2.2c-0.313,0.147-0.32,0.206-0.62,0.034
                  c-1.018-0.585-2.035-1.17-3.053-1.755c-2.117-1.217-4.233-2.434-6.351-3.651c-0.083-0.048,0.518-1.941,0.555-2.078
                  c0.408-1.515,0.816-3.03,1.224-4.545c0.345-1.282,0.69-2.565,1.036-3.847c0.09-0.335,0.051-0.626,0.378-0.741
                  c1.461-0.515,2.923-1.03,4.385-1.545c5.82-2.051,11.64-4.102,17.46-6.152c3.812-1.343,7.625-2.687,11.438-4.03
                  c0.259-0.091,5.249,11.31,5.781,12.465c0.08,0.173,0.16,0.346,0.239,0.52"/>
                <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                  M-44.635,602.311c-0.404-0.667-0.808-1.333-1.211-2c1.695-0.77,3.391-1.541,5.086-2.311c0.304,1.113,0.608,2.225,0.912,3.338"/>
                <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                  M-32.266,617.5c1.786,4.417,3.572,8.834,5.358,13.25c0.937,2.315,1.873,4.63,2.809,6.946c0.169,0.418,0.485,0.708,0.071,0.955
                  c-1.056,0.629-2.112,1.259-3.168,1.889c-3.268-7.263-6.536-14.527-9.804-21.79"/>
                </g>
                 { /*  Panneau  */  }

              <path fill="none" stroke="#FFFFFF" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" d="M-133.168,1153.284
                c16.713,7.64,34.467,13.74,52.72,18.374"/>

                 { /*  Feu tricolor  */  }
              <g id="calque_2_5_feu">
                <path fill="#FFFFFF" stroke="#FFFFFF" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                  M-209.485,819.936c-7.732-6.275-45.195-37.913-46.712-39.261"/>
                <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                  M-252.803,775.335c-7.406-6.652-14.812-13.304-22.219-19.956c-1.868-1.677-3.735-3.354-5.603-5.032
                  c-3.805,4.624-7.609,9.247-11.414,13.87c8.125,5.75,16.25,11.499,24.375,17.249c1.557,1.102,3.113,2.203,4.669,3.304
                  c0.368,0.26,0.735,0.52,1.102,0.78c0.235,0.167,0.301-0.076,0.492-0.277c3.023-3.194,6.046-6.388,9.07-9.582"/>
                <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                  M-289.112,760.051c-0.995-1.27-1.161-3.048,0.035-4.256c1.021-1.032,2.615-1.067,3.884-0.506"/>
                <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                  M-277.202,764.562c1.537-1.868,1.261-4.742-0.987-5.956c-1.445-0.78-3.944-0.354-4.803,1.141c-0.939,1.635-0.829,3.847,0.574,5.181
                  C-281.118,766.163-278.333,766.018-277.202,764.562"/>
                <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                  M-268.447,771.534c1.537-1.867,1.262-4.742-0.987-5.956c-1.445-0.78-3.944-0.354-4.803,1.141c-0.938,1.634-0.829,3.847,0.574,5.181
                  C-272.378,773.121-269.467,773.185-268.447,771.534"/>
                <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                  M-260.471,778.097c1.537-1.867,1.262-4.742-0.987-5.956c-1.445-0.78-3.944-0.354-4.803,1.141c-0.938,1.634-0.83,3.847,0.574,5.181
                  C-264.459,779.629-261.471,779.651-260.471,778.097"/>
                <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                  M-261.543,767.485c0.958-1.164,1.916-2.328,2.873-3.491c1.917,1.683,2.011,4.518,1.058,6.727"/>
                <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                  M-269.887,759.114c0.409-0.354,2.713-2.654,2.995-2.38c1.787,1.738,1.881,4.425,0.936,6.614"/>
                <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                  M-277.735,752.958c0.958-1.164,1.916-2.328,2.873-3.492c1.916,1.683,2.011,4.518,1.058,6.726"/>
                <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                  M-272.001,779.837c-0.399,0.536-0.799,1.072-1.199,1.607c-0.282,0.378-0.702,0.586-0.254,0.875c1.986,1.28,4.459,1.028,6.384-0.248"
                  />
                <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                  M-281.17,772.468c-0.198,0.391-1.741,3.059-1.534,3.211c1.967,1.449,4.615,1.188,6.579-0.115"/>
                <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                  M-288.912,766.399c-0.905,1.1-1.809,2.199-2.714,3.298c2.033,1.421,4.766,1.12,6.759-0.203"/>
              </g>
                 { /*  Feu tricolor  */  }

              <path fill="none" stroke="#FFFFFF" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" d="M1.676,1182.836
                c18.694,0.485,37.085-0.407,54.621-2.601"/>

             { /*  Vache  */  }
              <g id="calque_2_5_cow">
                <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M-261.588,1080.771
                  c1.166-1.668,2.332-3.336,3.498-5.005c0.915,1.298,1.83,2.596,2.744,3.895c-1.101,0.257-2.203,0.515-3.304,0.772
                  c-0.818,0.191-2.051,0.242-2.784,0.67c-1.162,0.679-2.251,1.526-3.201,2.478c-1.616,1.62-2.381,3.338-2.501,5.615
                  c-0.139,2.618,0.288,5.182,1.471,7.535c0.572,1.138,1.298,2.181,2.02,3.227c1.579,2.288,3.181,4.561,4.782,6.833
                  c4.293,6.094,8.605,12.175,12.925,18.25c-5.834-8.282-11.803-16.518-17.257-25.057c-0.793-1.242-1.152-1.866-1.025-3.373
                  c0.143-1.698,0.706-3.352,1.585-4.809c1.397-2.314,3.54-3.836,5.671-5.419c3.484-2.586,7.003-5.126,10.545-7.632
                  c1.371-0.969,3.208-1.492,4.736-2.164c2.652-1.168,5.304-2.335,7.957-3.503c0.834-0.368,0.737-0.088,1.291,0.699
                  c0.734,1.042,1.469,2.085,2.203,3.128c-3.466,2.292-6.932,4.585-10.397,6.877c0.631,0.899,0.82,2.109,0.835,3.18
                  c0.008,0.582-0.041,1.164-0.134,1.739c-0.032,0.201-0.385,1.265-0.295,1.385c0.125,0.167,0.249,0.333,0.373,0.499
                  c1.594,2.13,3.187,4.261,4.78,6.392c1.594,2.13,3.187,4.261,4.781,6.391c0.28,0.375,0.256,0.58,0.661,0.349
                  c0.876-0.5,1.752-0.999,2.628-1.498c2.691-1.534,5.382-3.068,8.073-4.602c0.748-0.426,1.496-0.853,2.244-1.279
                  c0.257-0.147,1.405,1.79,1.589,2.051c0.237,0.336,1.469,1.595,1.104,1.924c-0.693,0.624-1.387,1.247-2.08,1.87
                  c-2.313,2.08-4.626,4.16-6.939,6.239c-0.782,0.703-1.563,1.406-2.345,2.108c0.021-0.019,1.054,2.334,1.104,2.504
                  c0.624,2.1,0.565,4.357-0.273,6.392c-1.045,2.537-3.049,4.326-5.239,5.869c0.752,1.001,1.396,2.302,1.347,3.58
                  c-0.072,1.864-2.581,3.684-4.352,2.783c-0.551-0.28-0.851-0.805-1.194-1.291c-0.778-1.104-1.555-2.208-2.333-3.312
                  c-0.886-1.257-1.771-2.514-2.657-3.771c-0.348-0.493-0.9-1.039-1.065-1.632c-0.537-1.917,0.115-3.925,2.396-4.061
                  c2.078-0.125,3.302,1.704,4.408,3.164c1.073,1.417,2.113,2.859,3.138,4.311"/>
                <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M-234.163,1131.541
                  c-1.806,1.671-3.611,3.343-5.417,5.015c-1.318,1.22-3.539,4.039-5.193,1.847c-1.853-2.455-3.566-5.021-5.306-7.556
                  c-0.499-0.728-0.703-1.437-0.459-2.354c0.453-1.702,2.598-2.537,4.018-3.277c1.934-1.008,3.902-1.952,5.883-2.863
                  c-2.883,1.434-5.766,2.867-8.649,4.3c0.262-1.082,0.239-2.276-0.068-3.344c-0.764-2.664-3.348-3.823-5.92-4.094
                  c-0.809,1.854-0.704,4.138,0.321,5.881c0.466,0.792,1.104,1.472,1.826,2.036c0.662,0.517,2.191,0.946,2.388,1.73
                  c0.243,0.963,0.423,1.425,0.97,2.221c1.614,2.349,3.227,4.698,4.841,7.047c0.085,0.124,0.208,0.413,0.349,0.482
                  c0.185,0.091,1.336,0.515,1.348,0.679c0.053,0.742,0.228,1.485,0.46,2.189c0.968,2.936,3.331,4.831,6.496,4.7
                  c0,0,2.588-4.812-3.428-8.046"/>
                <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M-248.791,1144.537
                  c1.161-0.601,2.177-1.654,2.605-2.898c1.043-3.031-1.628-5.816-3.229-8.089c-0.556-0.79-1.112-1.579-1.669-2.368
                  c-1.514-2.148-5.245-1.184-6.995,0.172"/>
                <line fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="-245.312" y1="1127.86" x2="-245.882" y2="1127.094"/>
                <line fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="-240.613" y1="1134.74" x2="-241.276" y2="1133.799"/>
                <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M-233.25,1097.868
                  c0.482-0.72,0.822-1.327,1.523-1.82c0.84-0.592,1.68-1.184,2.52-1.775c-1.013,0.614-2.025,1.227-3.038,1.841
                  c0.375-1.567,0.509-3.469-0.205-4.956c0.067-0.258,1.053-0.742,1.267-0.893c0.477-0.336,0.953-0.671,1.429-1.007
                  c-0.952,0.628-1.905,1.255-2.857,1.883c-1.222-2.476-3.542-5.3-6.513-5.424c0.759,0.285,1.518,0.571,2.276,0.856
                  c0.416,0.157,0.983,0.584,1.377,0.307c1.523-1.073,3.046-2.146,4.569-3.22c0.821-0.578,1.642-1.157,2.463-1.735
                  c0.149-0.105,1.139-0.627,1.161-0.818c0.042-0.362-1.073-1.522-1.28-1.816c-0.464-0.66-0.929-1.319-1.394-1.979"/>
                <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M-213.274,1100.98
                  c0.322,0.458,2.804,3.688,2.591,3.864c-1.302,1.078-2.603,2.155-3.905,3.233c-3.08,2.549-6.159,5.099-9.238,7.648"/>
                <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M-252.32,1103.682
                  c-1.117-1.586-2.235-3.171-3.352-4.757c-0.514-0.729-1.241-1.436-1.124-2.39c0.119-0.965,0.598-1.911,1.103-2.731"/>
              </g>
               { /*  Vache  */  }

              <path fill="none" stroke="#FFFFFF" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" d="M167.833,1155.972
                c17.487-3.725,35.987-7.397,55.275-10.838"/>

                 { /*  barierres  */  }
                <g id="calque_2_barirreres">
                  <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeMiterlimit="10" d="M-261.588,1080.771
                    c1.166-1.668,2.332-3.336,3.498-5.005c0.915,1.298,1.83,2.596,2.744,3.895c-1.101,0.257-2.203,0.515-3.304,0.772
                    c-0.818,0.191-2.051,0.242-2.784,0.67c-1.162,0.679-2.251,1.526-3.201,2.478c-1.616,1.62-2.381,3.338-2.501,5.615
                    c-0.139,2.618,0.288,5.182,1.471,7.535c0.572,1.138,1.298,2.181,2.02,3.227c1.579,2.288,3.181,4.561,4.782,6.833
                    c4.293,6.094,8.605,12.175,12.925,18.25c-5.834-8.282-11.803-16.518-17.257-25.057c-0.793-1.242-1.152-1.866-1.025-3.373
                    c0.143-1.698,0.706-3.352,1.585-4.809c1.397-2.314,3.54-3.836,5.671-5.419c3.484-2.586,7.003-5.126,10.545-7.632
                    c1.371-0.969,3.208-1.492,4.736-2.164c2.652-1.168,5.304-2.335,7.957-3.503c0.834-0.368,0.737-0.088,1.291,0.699
                    c0.734,1.042,1.469,2.085,2.203,3.128c-3.466,2.292-6.932,4.585-10.397,6.877c0.631,0.899,0.82,2.109,0.835,3.18
                    c0.008,0.582-0.041,1.164-0.134,1.739c-0.032,0.201-0.385,1.265-0.295,1.385c0.125,0.167,0.249,0.333,0.373,0.499
                    c1.594,2.13,3.187,4.261,4.78,6.392c1.594,2.13,3.187,4.261,4.781,6.391c0.28,0.375,0.256,0.58,0.661,0.349
                    c0.876-0.5,1.752-0.999,2.628-1.498c2.691-1.534,5.382-3.068,8.073-4.602c0.748-0.426,1.496-0.853,2.244-1.279
                    c0.257-0.147,1.405,1.79,1.589,2.051c0.237,0.336,1.469,1.595,1.104,1.924c-0.693,0.624-1.387,1.247-2.08,1.87
                    c-2.313,2.08-4.626,4.16-6.939,6.239c-0.782,0.703-1.563,1.406-2.345,2.108c0.021-0.019,1.054,2.334,1.104,2.504
                    c0.624,2.1,0.565,4.357-0.273,6.392c-1.045,2.537-3.049,4.326-5.239,5.869c0.752,1.001,1.396,2.302,1.347,3.58
                    c-0.072,1.864-2.581,3.684-4.352,2.783c-0.551-0.28-0.851-0.805-1.194-1.291c-0.778-1.104-1.555-2.208-2.333-3.312
                    c-0.886-1.257-1.771-2.514-2.657-3.771c-0.348-0.493-0.9-1.039-1.065-1.632c-0.537-1.917,0.115-3.925,2.396-4.061
                    c2.078-0.125,3.302,1.704,4.408,3.164c1.073,1.417,2.113,2.859,3.138,4.311"/>
                  <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeMiterlimit="10" d="M-234.163,1131.541
                    c-1.806,1.671-3.611,3.343-5.417,5.015c-1.318,1.22-3.539,4.039-5.193,1.847c-1.853-2.455-3.566-5.021-5.306-7.556
                    c-0.499-0.728-0.703-1.437-0.459-2.354c0.453-1.702,2.598-2.537,4.018-3.277c1.934-1.008,3.902-1.952,5.883-2.863
                    c-2.883,1.434-5.766,2.867-8.649,4.3c0.262-1.082,0.239-2.276-0.068-3.344c-0.764-2.664-3.348-3.823-5.92-4.094
                    c-0.809,1.854-0.704,4.138,0.321,5.881c0.466,0.792,1.104,1.472,1.826,2.036c0.662,0.517,2.191,0.946,2.388,1.73
                    c0.243,0.963,0.423,1.425,0.97,2.221c1.614,2.349,3.227,4.698,4.841,7.047c0.085,0.124,0.208,0.413,0.349,0.482
                    c0.185,0.091,1.336,0.515,1.348,0.679c0.053,0.742,0.228,1.485,0.46,2.189c0.968,2.936,3.331,4.831,6.496,4.7
                    c0,0,2.588-4.812-3.428-8.046"/>
                  <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeMiterlimit="10" d="M-248.791,1144.537
                    c1.161-0.601,2.177-1.654,2.605-2.898c1.043-3.031-1.628-5.816-3.229-8.089c-0.556-0.79-1.112-1.579-1.669-2.368
                    c-1.514-2.148-5.245-1.184-6.995,0.172"/>
                  <line fill="none" stroke="#FFFFFF" strokeWidth="2" strokeMiterlimit="10" x1="-245.312" y1="1127.86" x2="-245.882" y2="1127.094"/>
                  <line fill="none" stroke="#FFFFFF" strokeWidth="2" strokeMiterlimit="10" x1="-240.613" y1="1134.74" x2="-241.276" y2="1133.799"/>
                  <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeMiterlimit="10" d="M-233.25,1097.868
                    c0.482-0.72,0.822-1.327,1.523-1.82c0.84-0.592,1.68-1.184,2.52-1.775c-1.013,0.614-2.025,1.227-3.038,1.841
                    c0.375-1.567,0.509-3.469-0.205-4.956c0.067-0.258,1.053-0.742,1.267-0.893c0.477-0.336,0.953-0.671,1.429-1.007
                    c-0.952,0.628-1.905,1.255-2.857,1.883c-1.222-2.476-3.542-5.3-6.513-5.424c0.759,0.285,1.518,0.571,2.276,0.856
                    c0.416,0.157,0.983,0.584,1.377,0.307c1.523-1.073,3.046-2.146,4.569-3.22c0.821-0.578,1.642-1.157,2.463-1.735
                    c0.149-0.105,1.139-0.627,1.161-0.818c0.042-0.362-1.073-1.522-1.28-1.816c-0.464-0.66-0.929-1.319-1.394-1.979"/>
                  <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeMiterlimit="10" d="M-213.274,1100.98
                    c0.322,0.458,2.804,3.688,2.591,3.864c-1.302,1.078-2.603,2.155-3.905,3.233c-3.08,2.549-6.159,5.099-9.238,7.648"/>
                  <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeMiterlimit="10" d="M-252.32,1103.682
                    c-1.117-1.586-2.235-3.171-3.352-4.757c-0.514-0.729-1.241-1.436-1.124-2.39c0.119-0.965,0.598-1.911,1.103-2.731"/>
                  <path fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M-85.431,1175.08
                    c-0.462,4.446-0.924,8.892-1.386,13.338c-0.31,2.979-0.619,5.958-0.929,8.938c-0.096,0.927-0.193,1.854-0.289,2.78
                    c-0.023,0.227,0.042,0.775-0.195,0.873c-1.214,0.504-2.429,1.008-3.644,1.512c-0.254,0.106-2.373,1.167-2.521,0.973
                    c-0.777-1.026-1.555-2.052-2.332-3.077c-0.202-0.267-1.516-1.606-1.418-1.938c1.357-4.615,2.715-9.229,4.072-13.845
                    c1.081-3.674,2.162-7.349,3.242-11.023c-0.681,2.062-1.361,4.123-2.042,6.184c-9.137-3.456-18.273-6.911-27.41-10.366
                    c-3.16-1.195-6.32-2.39-9.48-3.585c-0.659-0.249-1.319-0.499-1.978-0.748c-0.336-0.127-1.574,2.323-1.818,2.719
                    c9.076,3.352,18.152,6.703,27.229,10.054c3.892,1.438,7.784,2.875,11.676,4.312c0.362,0.134,0.724,0.267,1.085,0.401
                    c0.303,0.112,0.079,0.382,0.005,0.668c-0.552,2.125-1.105,4.25-1.657,6.375c-8.942-3.304-17.884-6.608-26.825-9.913
                    c-3.93-1.452-7.859-2.904-11.789-4.356c-1.042-0.385-2.083-0.77-3.125-1.155c-0.193-0.071-0.386-0.143-0.579-0.214
                    c-0.133-0.049-1.044,1.413-1.182,1.605c8.881,3.368,17.762,6.735,26.643,10.103c5.249,1.99,10.497,3.981,15.746,5.971
                    c0.001,0,0.003,0.001,0.004,0.001c-0.003-0.001-0.006-0.002-0.01-0.004c-9.058-3.435-18.116-6.87-27.174-10.304
                    c-4.583-1.738-9.165-3.476-13.748-5.213c-0.427-0.162-0.854-0.324-1.282-0.486c-0.341-0.129-0.403,0.295-0.576,0.578
                    c-1.365,2.23-2.73,4.46-4.095,6.69c-0.016,0.026-0.032,0.052-0.048,0.078c4.806-7.777,9.611-15.555,14.417-23.333
                    c0.016-0.026,0.032-0.052,0.048-0.079c-1.14-0.456-2.279-0.912-3.418-1.368c-0.564-0.226-0.828-0.566-1.224-0.119
                    c-1.317,1.486-2.635,2.973-3.952,4.459c-3.989,4.501-7.978,9.003-11.967,13.504c-0.454,0.513-0.908,1.025-1.362,1.538
                    c-0.084,0.095-0.449,0.389-0.435,0.528c0.193,1.954,0.387,3.907,0.58,5.86c2.211-0.299,4.422-0.599,6.633-0.898"/>
                  <path fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M-250,931.263
                    c-4.143-1.772-8.286-3.544-12.428-5.315c-2.739-1.171-5.477-2.342-8.215-3.514c-0.824-0.352-1.648-0.705-2.473-1.058
                    c-0.386-0.165-0.533-0.355-0.864-0.097c-1.116,0.871-2.231,1.742-3.347,2.613c-0.347,0.271-0.694,0.542-1.041,0.813
                    c-0.072,0.056,0.355,0.708,0.4,0.791c0.726,1.307,1.451,2.615,2.177,3.922c0.287,0.518,0.266,0.625,0.792,0.754
                    c5.209,1.287,10.418,2.573,15.627,3.86c2.87,0.708,5.739,1.417,8.609,2.126c-2.069-0.446-4.138-0.893-6.207-1.339
                    c-0.5,9.932-1.001,19.864-1.501,29.797c-0.108,2.135-0.215,4.269-0.323,6.403c-0.019,0.366-0.037,0.732-0.055,1.098
                    c-0.002,0.052-2.77,0.027-3.074,0.028c0.583-9.908,1.166-19.816,1.749-29.725c0.16-2.716,0.319-5.433,0.479-8.148
                    c0.046-0.775-1.1-0.696-1.746-0.875c-1.685-0.467-3.369-0.935-5.054-1.402c-0.574,9.805-1.148,19.61-1.723,29.416
                    c-0.17,2.915-0.341,5.829-0.512,8.744c-0.039,0.659-0.077,1.318-0.116,1.978c-0.032,0.546-1.892,0.337-2.374,0.368
                    c0.604-9.79,1.209-19.579,1.814-29.368c0.231-3.748,0.463-7.496,0.694-11.244c-0.485,9.824-0.97,19.647-1.456,29.472
                    c-0.173,3.506-0.346,7.012-0.52,10.517c-0.01,0.201-0.02,0.401-0.029,0.602c-0.001,0.019-1.904,0.006-2.019,0.007
                    c-2.073,0.005-4.146,0.011-6.219,0.016c8.688-0.058,17.374-0.115,26.062-0.172c-0.037,1.245-0.075,2.489-0.112,3.733
                    c-0.019,0.64-0.048,0.528-0.649,0.635c-2.234,0.397-4.468,0.795-6.702,1.192c-5.506,0.979-11.013,1.959-16.519,2.938
                    c-0.845,0.15-0.875,0.074-1.646-0.431c-1.362-0.892-2.723-1.783-4.085-2.675c1.104-1.579,2.208-3.157,3.311-4.736"/>
                  <path fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M-138.144,1163.387
                    c-8.395-4.994-16.791-9.988-25.186-14.981c-2.903-1.727-5.807-3.454-8.71-5.181c-0.606-0.36-1.211-0.721-1.817-1.081
                    c-0.31-0.184-1.955,2.014-2.264,2.361c8.378,4.958,16.757,9.917,25.136,14.876c3.634,2.151,7.269,4.302,10.903,6.453
                    c-0.413,0.47-3.804,4.672-4.022,4.551c-0.941-0.521-1.883-1.043-2.825-1.564c-4.937-2.734-9.874-5.468-14.811-8.203
                    c-4.73-2.619-9.459-5.239-14.189-7.858c-1.428-0.791-2.856-1.582-4.284-2.373c-0.286-0.158-0.572-0.316-0.858-0.475
                    c-0.225-0.125-1.272,1.141-1.488,1.352c8.391,4.952,16.781,9.903,25.172,14.854c3.588,2.118,7.176,4.235,10.764,6.352
                    c0.003,0.002,0.006,0.003,0.009,0.005c0.001,0,0.001,0,0,0c-8.443-4.983-16.886-9.965-25.33-14.948
                    c-3.339-1.97-6.678-3.941-10.017-5.911c-0.198-0.117-0.396-0.234-0.594-0.351c-0.062-0.036-1.295,1.457-1.383,1.556
                    c-1.46,1.65-2.921,3.301-4.382,4.952c6.086-6.822,12.173-13.644,18.26-20.465c0.021-0.023,0.041-0.046,0.062-0.069
                    c-1.042-0.647-2.085-1.295-3.128-1.942c-0.516-0.321-0.716-0.702-1.184-0.331c-1.556,1.234-3.112,2.469-4.668,3.704
                    c-4.712,3.738-9.424,7.476-14.135,11.213c-0.537,0.425-1.073,0.851-1.609,1.277c-0.1,0.079-0.51,0.305-0.521,0.444
                    c-0.15,1.957-0.299,3.914-0.449,5.871c2.229,0.09,4.458,0.18,6.688,0.271"/>
                </g>
               { /*  barierres  */  }

                 { /*  Lyon  */  }
                <path fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                  M344.821,1167.979c36.54,1.955,70.708-13.188,79.367-13.156c11.045,0.042,10.56,8.867,1.28,14.694"/>
                <path fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                  M295.348,1115.693c0,0-7.681,13.323,15.486,18.875c8.048,1.929,12.587-4.342,14.106-10.916c2.16-9.352-8.778-10.277-10.784-1.738
                  c0,0-7.591,32.782-10.348,35.699c-2.757,2.917-4.441,6.636-10.082,3.334c-5.642-3.303,1.363-5.193,2.142-5.205
                  c4.175-0.062,13.803,4.177,18.677,6.045c3.604,1.382,7.25,2.504,10.911,3.398"/>
                <path fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                  M402.326,1142.968c0,0-3.203,9.33-9.226,9.323c-6.024-0.007-0.924-7.728-0.936-10.739c-0.012-3.01-4.124-10.115-14.973,6.725
                  c-1.456,2.26-4.263,5.793-4.263,5.793l3.913-15.583l-3.077,7.889c0,0-3.753,5.889-9.331,2.36c-5.577-3.529-3.403-13.139,0.35-13.273
                  c3.752-0.134,5.358,10.154-4.149,18.623c-4.658,4.148-12.115-0.795-3.807-12.012c0,0-7.152,12.966-14.407,16.108
                  c-7.254,3.141-14.137,11.771-12.644,13.9c1.493,2.128,4.731,1.847,8.011-3.709c3.281-5.556,7.436-26.997,7.436-26.997
                  s-5.284,14.245-11.802,14.319c-6.518,0.075-2.99-11.274-0.326-15.27"/>
                <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M378.718,1082.799
                  c1.483,1.579,2.34,3.431,2.34,5.414c0,5.76-7.214,10.429-16.111,10.429c-8.896,0-16.109-4.668-16.109-10.429
                  c0-1.889,0.775-3.662,2.137-5.189"/>
                <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M381.421,1078.316
                  c5.164,2.953,8.421,7.271,8.421,12.083c0,8.9-11.146,16.115-24.896,16.115s-24.896-7.215-24.896-16.115
                  c0-4.646,3.039-8.833,7.898-11.775"/>
                <path fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinejoin="round" strokeMiterlimit="10" d="M365.616,1086.867
                  c-0.018,0.035-0.294,0.117-0.294,0.117s-17.444-13.011-17.444-29.946s14.851-17.25,17.25-17.25l0.393,0.091
                  c2.4,0,17.25,0.315,17.25,17.25c0,15.581-14.938,28.006-17.299,29.855"/>
                <path fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinejoin="round" strokeMiterlimit="10" d="M365.393,1044.421
                  c4.656,0.078,8.406,3.877,8.406,8.552c0,4.724-3.83,8.554-8.555,8.554s-8.555-3.83-8.555-8.554c0-4.725,3.83-8.554,8.555-8.554
                  L365.393,1044.421"/>
                <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinejoin="round" strokeLinecap="round" strokeMiterlimit="10" d="M386.489,1075.699
                  c7.192,3.957,11.756,9.854,11.756,16.444c0,11.915-14.924,21.574-33.331,21.574c-18.406,0-33.33-9.659-33.33-21.574
                  c0-6.59,4.563-12.49,11.76-16.446"/>
                <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinejoin="round" strokeLinecap="round" strokeMiterlimit="10" d="M381.747,1078.51"/>
                   { /*  Lyon  */  }


               { /*  <path fill="none" stroke="#FFFFFF" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" d="M312.378,1132.059
                c17.695-1.988,35.711-3.626,53.915-4.81"/>  */  }
              <path fill="none" stroke="#FFFFFF" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" d="M475.564,1126.395
                c18.519,1.04,36.962,2.751,55.194,5.243"/>
              <path fill="none" stroke="#FFFFFF" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" d="M626.31,1153.279
                c14.24,4.718,28.163,10.117,41.69,16.259"/>
              <path fill="none" stroke="#FFFFFF" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" d="M738.099,1206.259
                c14.292,8.659,27.557,17.547,39.807,26.617"/>
              <path fill="none" stroke="#FFFFFF" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" d="M838.449,1288.056
                c12.479,14.398,22.743,29.001,30.844,43.651"/>

                 { /*  bg city  */  }
                <g id="calque2_5_bg_city">
                  <path fill="none" stroke="#FFFFFF" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M982.742,1223.32c6.129,7.258,12.259,14.517,18.388,21.774c3.623,4.291,7.247,8.582,10.87,12.873
                    c-5.884,4.828-11.768,9.656-17.65,14.484c-9.557,7.842-19.113,15.685-28.67,23.526c-2.361,1.938-4.722,3.876-7.083,5.813
                    c-0.458,0.376,0.091,0.713,0.396,1.156c1.181,1.719,2.36,3.437,3.54,5.154c3.584,5.22,7.169,10.439,10.753,15.659
                    c1.38,2.009,2.76,4.018,4.139,6.026c0.175,0.255,1.158,2.022,1.441,1.841c6.263-4.012,12.526-8.023,18.789-12.035
                    c4.343,8.902,8.686,17.805,13.028,26.707c0.439,0.9,0.878,1.8,1.317,2.7c-4.592,1.886-9.184,3.771-13.774,5.656
                    c-0.93,0.382-1.859,0.764-2.789,1.145c-0.435,0.179-0.518,0.098-0.374,0.538c0.779,2.396,1.558,4.793,2.337,7.188
                    c0.842,2.592,1.685,5.184,2.527,7.774c0.077,0.237,0.081,0.725,0.371,0.672c0.586-0.104,1.172-0.21,1.758-0.315
                    c5.765-1.035,11.529-2.07,17.294-3.105c2.414-0.433,4.828-0.866,7.242-1.3c0.46-0.083,0.92-0.165,1.379-0.248
                    c0.071-0.013,0.174,0.571,0.191,0.641c1.232,4.909,2.465,9.819,3.697,14.729c1.315,5.24,2.632,10.48,3.947,15.721
                    c0.099,0.393,0.197,0.786,0.296,1.179c0.029,0.118,1.747,0.024,1.898,0.024c2.639,0,5.278,0,7.918,0
                    c0.163,0,0.081,11.545,0.081,12.838c0,0.008-11.312,0.004-12.034,0.004c-5.726,0-11.452,0-17.178,0c-0.552,0-1.103,0-1.653,0
                    c-0.289,0-0.3,0.573-0.368,0.809c-0.797,2.761-1.594,5.521-2.391,8.282c-0.458,1.589-0.917,3.179-1.376,4.769"/>
                  <path fill="none" stroke="#FFFFFF" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M1008,1489.01c0,4.98,0,9.961,0,14.94c0,0.721,0,1.441,0,2.162c0,0.123,1.148,0.157,1.278,0.17c2.928,0.277,5.855,0.555,8.783,0.831
                    c1.391,0.132,2.781,0.264,4.172,0.396c0.265,0.024,1.58-0.043,1.767,0.167c0.103,0.116,0,0.793,0,0.938c0,0.849,0,1.697,0,2.546
                    c0,6.045,0,12.09,0,18.134c0,3.797,0,7.593,0,11.39c-4.591-1.262-9.183-2.523-13.773-3.786c-2.407-0.661-4.813-1.323-7.221-1.984
                    c-0.146-0.04-0.805-0.328-0.859-0.15c-0.238,0.785-0.476,1.571-0.714,2.356c-0.903,2.977-1.806,5.954-2.709,8.931
                    c-0.312,1.029-0.624,2.06-0.937,3.089c-0.156,0.514-0.142,0.434,0.354,0.655c4.546,2.041,9.092,4.081,13.638,6.122
                    c0.183,0.082-1.687,5.255-1.826,5.676c-0.484,1.46-0.969,2.92-1.452,4.38c-0.11,0.331-0.81,1.688-0.673,2.028
                    c0.104,0.261,1.148,0.64,1.381,0.769c1.131,0.629,2.261,1.258,3.392,1.888c2.765,1.538,5.53,3.077,8.295,4.616
                    c0.406,0.226,3.22,1.46,3.025,1.914c-0.808,1.89-1.615,3.778-2.424,5.668c-1.832,4.283-3.664,8.567-5.496,12.851
                    c-8.581-4.908-17.161-9.816-25.742-14.725c-0.24-0.138-2.405-1.563-2.564-1.366c-1.524,1.889-3.049,3.777-4.573,5.666
                    c-1.452,1.8-2.905,3.6-4.357,5.399c-0.103,0.127,0.633,1.275,0.708,1.418c0.661,1.264,1.321,2.527,1.982,3.791
                    c1.204,2.302,2.408,4.604,3.611,6.905c0.182,0.348-0.197,0.417-0.494,0.627c-0.868,0.612-1.736,1.226-2.604,1.838
                    c-4.125,2.912-8.249,5.823-12.374,8.735c-0.145,0.102,2.72,5.483,2.925,5.896c0.252,0.504,2.572,4.465,2.172,4.752
                    c-4.24,3.033-8.481,6.067-12.723,9.101c-2.06,1.474-4.119,2.946-6.179,4.42"/>
                  <path fill="none" stroke="#FFFFFF" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M792.476,1691c-9.571,1.901-19.142,3.803-28.713,5.705c-0.495,0.098-0.99,0.196-1.485,0.295c-1.392-9.412-2.784-18.825-4.176-28.237
                    c-0.136-0.917-0.301-0.674-1.258-0.674c-2.271,0-4.543,0-6.814,0c-3.977,0-7.954,0-11.93,0c0.197-4.796,0.395-9.593,0.592-14.389
                    c0.096-2.337,0.192-4.674,0.289-7.011c0.022-0.529,0.09-0.496-0.441-0.608c-1.286-0.272-2.572-0.545-3.858-0.817
                    c-1.893-0.4-3.786-0.802-5.68-1.202c0,2.485,0,4.971,0,7.456c0,0.565,0.196,0.809-0.365,0.809c-0.814,0-1.629,0-2.444,0
                    c-5.949,0-11.897,0-17.846,0c-0.814,0-1.629,0-2.444,0c-0.311,0-0.365,0.081-0.365-0.223c0-1.632,0-3.264,0-4.896
                    c0-6.22,0-12.44,0-18.66c0-2.461,0-4.923,0-7.384c0-0.512,0.321-1.994-0.272-2.136c-5.035-1.201-10.069-2.402-15.104-3.604
                    c-3.387-0.809-6.773-1.616-10.16-2.425"/>
                </g>
                 { /*  bg city  */  }

                 { /*  Lampadaire  */  }
                <g id="calque_2_5_lampadaire">
                  <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M651.49,1151.542c0.824,0.325,1.647,0.649,2.47,0.974c0.547,0.216,1.094,0.432,1.642,0.647c0.115,0.045,0.777,0.208,0.828,0.327
                    c0.037,0.087-0.194,0.491-0.226,0.574c-0.203,0.513-0.405,1.026-0.607,1.538c-0.449,1.14-0.899,2.28-1.349,3.421
                    c-0.13,0.331-0.149,0.94-0.544,0.785c-0.658-0.26-1.316-0.519-1.974-0.778c-2.332-0.918-4.664-1.839-6.996-2.759
                    c-1.458-0.575-2.915-1.149-4.371-1.724c-0.292-0.114-0.427-0.062-0.309-0.36c0.305-0.776,0.612-1.555,0.919-2.332
                    c0.38-0.963,0.759-1.925,1.139-2.888c0.098-0.248,0.223-1.036,0.531-0.914c1.438,0.567,2.937,1.157,4.341,1.711
                    c-0.967-0.382-1.934-0.763-2.901-1.144c3.334-7.043,6.667-14.086,10.001-21.13c0.126-0.268,0.254-0.535,0.38-0.804
                    c-0.574-0.226-1.217-0.48-1.752-0.691c3.619,1.427,7.356,2.901,10.906,4.301c-0.434-0.171-0.869-0.343-1.303-0.514
                    c-2.322,7.317-4.644,14.635-6.965,21.953c-0.055,0.174-0.111,0.349-0.166,0.522c2.309-7.29,4.619-14.581,6.929-21.871
                    c0.161-0.508,0.224-0.447-0.323-0.663c-0.305-0.12-1.115-0.175-0.957-0.578c0.369-0.935,0.737-1.868,1.104-2.801
                    c1.623-4.117,3.247-8.235,4.87-12.352c2.902-7.359,5.804-14.719,8.706-22.078c0.093-0.233-0.766-0.411-0.941-0.479
                    c-1.115-0.44-2.229-0.88-3.344-1.319c-0.325-0.128-0.397-0.281-0.517,0.025c-0.137,0.348-0.275,0.696-0.412,1.043
                    c-1.399,3.545-2.796,7.09-4.193,10.635c-3.238,8.209-6.474,16.418-9.712,24.627c-0.114,0.288-0.228,0.577-0.341,0.866
                    c2.669-6.863,5.338-13.726,8.007-20.588c2.184-5.617,4.369-11.234,6.553-16.85c3.26,1.285,6.521,2.571,9.781,3.857
                    c1.902,0.75,3.804,1.5,5.707,2.25c0.358,0.141,0.718,0.283,1.077,0.425c0.032,0.013-0.678,1.766-0.735,1.911
                    c0.224-0.568,0.448-1.137,0.672-1.706c0.13-0.328,0.443-0.188,0.782-0.246c2.287-0.401,2.877-2.657,3.619-4.537
                    c0.316-0.802,0.633-1.604,0.949-2.406c0.106-0.27,0.65,0.105,0.867,0.191c1.16,0.457,2.318,0.914,3.477,1.371
                    c-0.419-0.199-0.839-0.397-1.26-0.595c1.583-3.304,3.165-6.61,4.749-9.915c0.981-2.048,1.962-4.097,2.943-6.146
                    c0.236-0.492,0.473-0.985,0.708-1.479c0.146-0.304,1.297,0.364,1.588,0.479c-0.523-0.206-1.046-0.412-1.569-0.619
                    c-0.091-0.036-0.149-0.509-0.172-0.595c-0.328-1.26-0.655-2.52-0.983-3.779c-0.593-2.281-1.187-4.56-1.781-6.84
                    c-2.096,1.409-4.191,2.818-6.288,4.227c-0.982,0.661-1.965,1.322-2.949,1.982c-0.279,0.188-0.378,0.056-0.712-0.076
                    c-0.54-0.213-1.136-0.448-1.647-0.65c4.77,1.882,9.539,3.762,14.309,5.643c-4.14-1.649-8.279-3.297-12.419-4.947
                    c-1.896,6.301-3.792,12.602-5.688,18.903c-0.595-0.234-1.277-0.502-1.823-0.719c2.861,1.129,5.724,2.257,8.585,3.386
                    c-1.474-0.598-2.949-1.196-4.422-1.793c-0.614,1.555-1.227,3.11-1.84,4.665c-0.583,1.48-0.528,3.062,0.781,4.135
                    c-7.464-2.943-14.928-5.886-22.392-8.829c-1.585-0.625-3.17-1.25-4.755-1.875c-0.26-0.102-0.519-0.205-0.777-0.306
                    c-0.009-0.004-0.887,2.233-0.98,2.471c0.278-0.729,0.555-1.459,0.833-2.188c0.175-0.462-0.461-0.882-0.684-1.318
                    c-0.676-1.32-0.333-2.521,0.201-3.822c0.514-1.252,1.056-2.495,1.629-3.721c-1.604-0.632-3.222-1.271-4.819-1.901
                    c4.508,1.779,9.11,3.593,13.565,5.35c-0.623-0.245-1.245-0.491-1.867-0.737c1.659-3.306,3.319-6.612,4.979-9.92
                    c1.022-2.038,2.045-4.076,3.068-6.113c0.253-0.504,0.505-1.007,0.757-1.511c0.184-0.364,1.38,0.364,1.709,0.494
                    c0.001,0.001,0.138,0.055,0.123,0.049c-5.583-2.202-11.166-4.404-16.75-6.605c4.865,1.935,9.73,3.87,14.594,5.805
                    c-0.828-3.729-1.657-7.457-2.486-11.184c-3.163,2.084-6.326,4.168-9.488,6.254c-0.212,0.14-0.214,0.303-0.292,0.55
                    c-0.211,0.68-0.422,1.36-0.633,2.039c-0.716,2.303-1.434,4.604-2.15,6.906c-0.715,2.302-1.432,4.604-2.148,6.906
                    c-0.212,0.68-0.422,1.36-0.634,2.039c-0.106,0.341-0.241,0.411,0.099,0.545c2.076,0.819,4.152,1.639,6.229,2.457
                    c0.273,0.108,0.547,0.216,0.82,0.323c0.143,0.056-0.133,0.521-0.177,0.632c-0.582,1.476-1.164,2.951-1.745,4.426
                    c-0.604,1.531-2.093,2.314-3.647,1.667"/>
                </g>
                 { /*  Lampadaire  */  }

                 { /*  City1  */  }
                <g id="calque_2_5_city1">
                  <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M850.213,1298.237c18.618-18.714,62.225-62.236,62.02-62.531c-0.554-0.799-42.862-43.011-43.864-44.055
                    c-0.535-0.557,1.787-2.384,2.202-2.812c3.789-3.898,7.579-7.794,11.368-11.692c3.164,3.148,6.328,6.296,9.492,9.444
                    c-2.102-2.167-8.315-8.574-9.32-9.611c-0.009-0.009,4.049-4.103,5.901-5.958c1.539-1.542,3.157-3.034,4.629-4.641
                    c0.297-0.324,0.83-1.137,1.292-1.213c0.248-0.041,7.361,7.289,9.471,9.388c-3.165-3.149-6.331-6.299-9.496-9.449
                    c1.363-1.345,2.727-2.689,4.09-4.035c-7.364-7.326-14.717-14.665-22.092-21.98c4.7-4.724,20.239-20.639,20.842-20.962
                    c0.439-0.236,41.309,40.85,44.572,44.097c-1.867-1.858-3.734-3.715-5.602-5.573c-0.934-0.929-4.079-3.742-3.64-4.184
                    c0.469-0.471,9.096-9.141,12.94-13.005c-3.893-3.872-12.824-12.501-12.569-12.809c0.354-0.426,8.1-8.093,10.015-10.017
                    c-1.644,1.712-9.837,10.109-10.077,10.111c-0.587,0.004-11.809-11.833-12.015-11.705c-0.973,0.605-9.666,9.702-13.177,13.229
                    c11.456,11.399,22.912,22.798,34.369,34.196c-16.912,16.995-33.82,33.993-50.731,50.989c18.712-18.808,63.107-63.637,63.35-63.482
                    c0.53,0.341,34.927,34.773,43.472,43.275c-18.754,18.848-124.953,125.542-125.7,126.337"/>
                  <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M762.278,1208.666c2.299-2.311,4.599-4.622,6.898-6.932c4.661-4.685,9.322-9.371,13.984-14.056
                    c9.352-9.399,18.703-18.798,28.055-28.197c9.337-9.384,18.674-18.769,28.012-28.153c4.62-4.644,9.242-9.285,13.86-13.93
                    c0.473-0.475,0.957,0.412,1.323,0.776c0.562,0.559,1.123,1.117,1.685,1.676c1.217,1.21,2.434,2.421,3.65,3.632
                    c2.34,2.328,4.68,4.656,7.02,6.984c9.387,9.339,18.773,18.679,28.16,28.019c0.266,0.264,3.009,2.901,2.934,2.983
                    c-0.795,0.882-1.682,1.693-2.519,2.535c-2.331,2.343-4.662,4.686-6.992,7.028c-4.701,4.725-9.402,9.45-14.104,14.174
                    c-18.721,18.816-37.443,37.629-56.162,56.446c-3.257,3.273-6.513,6.545-9.77,9.818c-0.799,0.803-4.066,1.031-4.322,0.777
                    c-1.696-1.687-12.822-12.758-13.011-12.945c-0.375-0.186,7.98-8.587,11.286-11.909c-4.238-4.216-8.476-8.433-12.713-12.649
                    c-0.432-0.429-0.471-0.27-0.898,0.159c-0.539,0.542-1.077,1.083-1.616,1.625c-1.077,1.083-2.155,2.166-3.232,3.249
                    c-2.065,2.075-4.13,4.15-6.194,6.225"/>
                  <path fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M827.206,1257.875
                    c6.886-7.212,13.771-14.424,20.657-21.636c5.776-6.05,11.943-11.719,17.979-17.509c2.282-2.189,4.565-4.379,6.848-6.569"/>
                  <path fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M836.573,1267.506
                    c7.031-6.83,14.063-13.659,21.095-20.489c2.819-2.738,5.639-5.477,8.458-8.215c2.935-2.85,5.625-6,8.42-8.985
                    c2.504-2.674,5.007-5.349,7.511-8.023"/>
                  <path fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M846.251,1276.824
                    c6.779-6.607,13.559-13.215,20.339-19.823c5.707-5.563,11.459-11.034,16.857-16.895c2.762-2.999,5.524-5.998,8.287-8.997"/>
                  <path fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M848.874,1185.309
                    c3.6-3.308,7.199-6.615,10.799-9.922c3.211-2.951,5.968-6.48,8.883-9.719c0.441-0.49,0.883-0.98,1.324-1.471"/>
                  <path fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M839.352,1175.834
                    c3.306-3.636,6.611-7.272,9.917-10.909c0.914-1.005,1.934-1.884,2.94-2.799c2.263-2.056,4.525-4.112,6.788-6.168
                    c0.454-0.413,0.907-0.825,1.361-1.237"/>
                  <path fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M829.829,1166.359
                    c3.64-3.391,7.279-6.781,10.918-10.172c3.083-2.872,5.765-6.01,8.547-9.183c0.514-0.586,1.027-1.172,1.541-1.758"/>
                  <path fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M816.814,1217.531
                    c3.37-3.656,6.739-7.312,10.109-10.968c2.858-3.102,6.003-5.802,9.161-8.604c0.579-0.513,1.157-1.027,1.736-1.541"/>
                  <path fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M807.291,1208.057
                    c3.618-3.324,7.236-6.648,10.854-9.972c1.001-0.919,1.875-1.944,2.785-2.955c2.046-2.273,4.092-4.546,6.138-6.819
                    c0.41-0.456,0.82-0.912,1.23-1.368"/>
                  <path fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M797.768,1198.582
                    c3.64-3.383,7.28-6.766,10.92-10.148c3.098-2.879,5.787-6.092,8.587-9.265c0.5-0.567,1.001-1.134,1.501-1.701"/>
                  <path fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M896.021,1142.205
                    c1.766,1.553,3.53,3.105,5.296,4.657c1.227,1.08,2.564,2.098,3.679,3.296c3.688,3.965,7.374,7.929,11.061,11.894
                    c0.359,0.386,0.719,0.772,1.078,1.159"/>
                  <path fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M953.827,1213.619
                    c3.642-3.382,7.284-6.765,10.926-10.147c3.101-2.879,5.79-6.108,8.592-9.279c0.497-0.562,0.993-1.124,1.489-1.685"/>
                  <path fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M944.304,1204.144
                    c3.625-3.382,7.25-6.764,10.875-10.146c1.045-0.975,2.217-1.9,3.158-2.978c1.798-2.059,3.595-4.117,5.392-6.176
                    c0.527-0.604,1.055-1.208,1.582-1.812"/>
                  <path fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M934.781,1194.669
                    c3.289-3.527,6.579-7.054,9.868-10.58c1.577-1.691,3.154-3.382,4.731-5.073c1.264-1.354,2.846-2.464,4.26-3.654
                    c0.716-0.602,1.432-1.204,2.147-1.806"/>
                  <path fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M922.087,1245.519
                    c3.659-3.39,7.319-6.779,10.979-10.168c3.135-2.904,5.843-6.233,8.68-9.425c0.449-0.506,0.899-1.012,1.349-1.518"/>
                  <path fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M912.565,1236.044
                    c3.268-3.503,6.536-7.006,9.804-10.509c1.593-1.707,3.185-3.414,4.777-5.121c1.231-1.32,2.761-2.401,4.14-3.56
                    c0.762-0.641,1.524-1.281,2.286-1.922"/>
                  <path fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M903.042,1226.57
                    c3.621-3.382,7.241-6.764,10.862-10.146c1.088-1.016,2.329-1.981,3.307-3.106c1.745-2.005,3.489-4.011,5.233-6.016
                    c0.534-0.615,1.069-1.229,1.604-1.844"/>
                  <path fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M890.504,1277.262
                    c3.281-3.611,6.563-7.221,9.846-10.832c0.906-0.997,1.917-1.87,2.914-2.777c2.266-2.061,4.53-4.122,6.795-6.182
                    c0.484-0.44,0.968-0.881,1.452-1.321"/>
                </g>
                 { /*  City1  */  }

                 { /*  bridge  */  }
                <g id="calque_2_5_bridge">
                  <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeMiterlimit="10" d="M900.094,1293.125
                    c0.179,0.323,1.801,3.281,1.83,3.266c1.012-0.562,2.024-1.123,3.037-1.684c2.639-1.464,5.278-2.927,7.918-4.391
                    c-1.404,0.779-2.809,1.557-4.213,2.336c-0.432,0.239-0.692-0.754-0.885-1.102c-0.381-0.686-0.761-1.371-1.141-2.057
                    c2.564,4.624,5.129,9.249,7.693,13.873c5.785,10.432,11.57,20.863,17.355,31.295c6.144,11.077,12.286,22.154,18.43,33.23
                    c3.638,6.561,7.275,13.121,10.914,19.681c0.183,0.329,0.365,0.659,0.548,0.988c-0.382-0.688-0.764-1.377-1.145-2.065
                    c-0.142-0.256-0.932-1.289-0.629-1.457c1.428-0.791,2.855-1.583,4.283-2.375c-2.724,1.511-5.448,3.021-8.173,4.533
                    c-1.042,0.577-2.084,1.155-3.126,1.733c-0.027,0.016,1.709,3.113,1.896,3.452c-9.434,2.221-18.867,4.442-28.301,6.664
                    c-0.355,0.083-0.712,0.167-1.068,0.251c-0.214,0.051-0.576-1.403-0.663-1.654c3.763-1.191,7.809-3.874,9.043-7.794
                    c1.179-3.743-0.581-7.832-2.916-10.745c-2.356-2.938-5.753-4.656-9.49-4.957c-2.627-0.212-5.305,0.193-7.804,1.007
                    c-0.454,0.148-0.909,0.306-1.347,0.499c-0.043,0.02-1.406-2.532-1.502-2.704c-0.591-1.066-1.183-2.134-1.774-3.201
                    c-0.027-0.05,0.875-0.635,0.956-0.695c2.063-1.541,3.879-3.495,5.119-5.76c3.936-7.186-0.11-17.365-7.837-20.1
                    c-2.867-1.015-6.013-0.907-8.927-0.15c-0.92,0.238-1.826,0.541-2.7,0.914c-0.439,0.188-0.569,0.447-0.811,0.011
                    c-0.306-0.551-3.394-5.898-3.285-5.97c1.53-1.012,2.897-2.344,4.057-3.756c2.189-2.67,3.632-5.974,3.49-9.475
                    c-0.172-4.241-2.602-9.24-6.447-11.302c-4.203-2.254-9.328,0.03-12.555,3.009c-0.169-0.243-1.623-2.161-1.532-2.25
                    c0.317-0.312,0.636-0.623,0.953-0.934c1.693-1.658,3.388-3.316,5.081-4.975c4.335-4.245,8.67-8.489,13.005-12.733
                    c0.772-0.756,1.544-1.511,2.315-2.267c2.564,4.624,5.129,9.249,7.693,13.873c5.785,10.432,11.57,20.863,17.355,31.295
                    c6.143,11.076,12.286,22.153,18.429,33.23c3.638,6.56,7.276,13.12,10.914,19.68c0.183,0.33,0.365,0.659,0.548,0.988
                    c-6.144,1.38-12.286,2.759-18.43,4.139c4.393-3.569,6.47-9.653,5.001-15.12c-1.985-7.388-8.683-14.192-16.232-15.838
                    c-0.516-0.112-1.039-0.205-1.566-0.241c1.997-7.034,1.287-15.561-3.119-21.535c-3.425-4.644-8.911-8.129-14.443-9.726
                    c1.986-6.769,1.966-15.773-2.431-21.603c-3.731-4.946-10.698-6.424-16.593-5.996c-0.106,0.008-0.214,0.017-0.32,0.027
                    c4.42-4.403,8.839-8.807,13.259-13.209c2.302,4.151,4.604,8.301,6.905,12.453c0.306,0.55,0.792-0.051,1.271-0.316
                    c1.342-0.743,2.683-1.487,4.024-2.231c0.358-0.198,1.392-1.088,1.641-0.637c0.582,1.049,1.164,2.098,1.746,3.146
                    c0.213,0.385,3.113,5.601,3.102,5.606c-2.035,1.13-4.071,2.259-6.106,3.388c-0.593,0.329-0.949,0.265-0.616,0.864
                    c0.562,1.015,1.125,2.029,1.688,3.043c1.083,1.954,2.167,3.907,3.25,5.86c2.353-1.305,4.706-2.61,7.059-3.915
                    c1.666,3.004,3.331,6.007,4.996,9.011c0.2,0.359,0.368,0.405,0.004,0.607c-0.77,0.427-1.539,0.854-2.309,1.28
                    c-1.479,0.82-2.958,1.641-4.437,2.461c0.996,1.796,1.992,3.591,2.988,5.387c0.569,1.027,1.139,2.054,1.709,3.081
                    c0.257,0.464,0.188,0.649,0.647,0.395c3.72-2.062,7.438-4.125,11.158-6.188c-1.521,0.843-3.04,1.686-4.561,2.528
                    c1.667,3.007,3.335,6.014,5.002,9.021c0.166,0.299,0.429,0.471,0.106,0.649c-0.747,0.415-1.495,0.83-2.243,1.244
                    c-1.496,0.83-2.992,1.659-4.488,2.489c1.215,2.098,2.43,4.196,3.645,6.294c0.543,0.937,1.085,1.873,1.628,2.81
                    c0.138,0.237,1.669-0.789,1.905-0.92c1.337-0.741,2.675-1.483,4.012-2.225c0.776-0.431,0.763-0.655,1.186,0.107
                    c1.384,2.495,2.769,4.991,4.152,7.486c0.164,0.296,0.328,0.592,0.492,0.888c-2.24,1.242-4.479,2.484-6.72,3.727
                    c1.784,3.216,3.567,6.433,5.352,9.648c2.297-1.274,4.594-2.548,6.891-3.822"/>
                </g>
                 { /*  bridge  */  }

                 { /*  city2  */  }
                <g id="claque-2_5_city2" >
                  <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeMiterlimit="10" d="M900.094,1293.125
                    c0.179,0.323,1.801,3.281,1.83,3.266c1.012-0.562,2.024-1.123,3.037-1.684c2.639-1.464,5.278-2.927,7.918-4.391
                    c-1.404,0.779-2.809,1.557-4.213,2.336c-0.432,0.239-0.692-0.754-0.885-1.102c-0.381-0.686-0.761-1.371-1.141-2.057
                    c2.564,4.624,5.129,9.249,7.693,13.873c5.785,10.432,11.57,20.863,17.355,31.295c6.144,11.077,12.286,22.154,18.43,33.23
                    c3.638,6.561,7.275,13.121,10.914,19.681c0.183,0.329,0.365,0.659,0.548,0.988c-0.382-0.688-0.764-1.377-1.145-2.065
                    c-0.142-0.256-0.932-1.289-0.629-1.457c1.428-0.791,2.855-1.583,4.283-2.375c-2.724,1.511-5.448,3.021-8.173,4.533
                    c-1.042,0.577-2.084,1.155-3.126,1.733c-0.027,0.016,1.709,3.113,1.896,3.452c-9.434,2.221-18.867,4.442-28.301,6.664
                    c-0.355,0.083-0.712,0.167-1.068,0.251c-0.214,0.051-0.576-1.403-0.663-1.654c3.763-1.191,7.809-3.874,9.043-7.794
                    c1.179-3.743-0.581-7.832-2.916-10.745c-2.356-2.938-5.753-4.656-9.49-4.957c-2.627-0.212-5.305,0.193-7.804,1.007
                    c-0.454,0.148-0.909,0.306-1.347,0.499c-0.043,0.02-1.406-2.532-1.502-2.704c-0.591-1.066-1.183-2.134-1.774-3.201
                    c-0.027-0.05,0.875-0.635,0.956-0.695c2.063-1.541,3.879-3.495,5.119-5.76c3.936-7.186-0.11-17.365-7.837-20.1
                    c-2.867-1.015-6.013-0.907-8.927-0.15c-0.92,0.238-1.826,0.541-2.7,0.914c-0.439,0.188-0.569,0.447-0.811,0.011
                    c-0.306-0.551-3.394-5.898-3.285-5.97c1.53-1.012,2.897-2.344,4.057-3.756c2.189-2.67,3.632-5.974,3.49-9.475
                    c-0.172-4.241-2.602-9.24-6.447-11.302c-4.203-2.254-9.328,0.03-12.555,3.009c-0.169-0.243-1.623-2.161-1.532-2.25
                    c0.317-0.312,0.636-0.623,0.953-0.934c1.693-1.658,3.388-3.316,5.081-4.975c4.335-4.245,8.67-8.489,13.005-12.733
                    c0.772-0.756,1.544-1.511,2.315-2.267c2.564,4.624,5.129,9.249,7.693,13.873c5.785,10.432,11.57,20.863,17.355,31.295
                    c6.143,11.076,12.286,22.153,18.429,33.23c3.638,6.56,7.276,13.12,10.914,19.68c0.183,0.33,0.365,0.659,0.548,0.988
                    c-6.144,1.38-12.286,2.759-18.43,4.139c4.393-3.569,6.47-9.653,5.001-15.12c-1.985-7.388-8.683-14.192-16.232-15.838
                    c-0.516-0.112-1.039-0.205-1.566-0.241c1.997-7.034,1.287-15.561-3.119-21.535c-3.425-4.644-8.911-8.129-14.443-9.726
                    c1.986-6.769,1.966-15.773-2.431-21.603c-3.731-4.946-10.698-6.424-16.593-5.996c-0.106,0.008-0.214,0.017-0.32,0.027
                    c4.42-4.403,8.839-8.807,13.259-13.209c2.302,4.151,4.604,8.301,6.905,12.453c0.306,0.55,0.792-0.051,1.271-0.316
                    c1.342-0.743,2.683-1.487,4.024-2.231c0.358-0.198,1.392-1.088,1.641-0.637c0.582,1.049,1.164,2.098,1.746,3.146
                    c0.213,0.385,3.113,5.601,3.102,5.606c-2.035,1.13-4.071,2.259-6.106,3.388c-0.593,0.329-0.949,0.265-0.616,0.864
                    c0.562,1.015,1.125,2.029,1.688,3.043c1.083,1.954,2.167,3.907,3.25,5.86c2.353-1.305,4.706-2.61,7.059-3.915
                    c1.666,3.004,3.331,6.007,4.996,9.011c0.2,0.359,0.368,0.405,0.004,0.607c-0.77,0.427-1.539,0.854-2.309,1.28
                    c-1.479,0.82-2.958,1.641-4.437,2.461c0.996,1.796,1.992,3.591,2.988,5.387c0.569,1.027,1.139,2.054,1.709,3.081
                    c0.257,0.464,0.188,0.649,0.647,0.395c3.72-2.062,7.438-4.125,11.158-6.188c-1.521,0.843-3.04,1.686-4.561,2.528
                    c1.667,3.007,3.335,6.014,5.002,9.021c0.166,0.299,0.429,0.471,0.106,0.649c-0.747,0.415-1.495,0.83-2.243,1.244
                    c-1.496,0.83-2.992,1.659-4.488,2.489c1.215,2.098,2.43,4.196,3.645,6.294c0.543,0.937,1.085,1.873,1.628,2.81
                    c0.138,0.237,1.669-0.789,1.905-0.92c1.337-0.741,2.675-1.483,4.012-2.225c0.776-0.431,0.763-0.655,1.186,0.107
                    c1.384,2.495,2.769,4.991,4.152,7.486c0.164,0.296,0.328,0.592,0.492,0.888c-2.24,1.242-4.479,2.484-6.72,3.727
                    c1.784,3.216,3.567,6.433,5.352,9.648c2.297-1.274,4.594-2.548,6.891-3.822"/>
                  <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M908.333,1493.674c0.496-6.772,0.993-13.545,1.489-20.316c0.938-12.803,1.877-25.605,2.815-38.408
                    c0.554-7.561,1.108-15.122,1.663-22.684c0.003-0.04,0.006-0.079,0.009-0.119c0-0.002,0-0.004,0-0.006
                    c-0.344,4.692-0.688,9.385-1.031,14.077c-0.193,2.646-0.388,5.294-0.581,7.94c-0.034,0.459-0.067,0.919-0.102,1.379
                    c0,0.005,0.978,0.073,1.032,0.077c5.599,0.411,11.197,0.821,16.796,1.231c2.57,0.188,5.141,0.377,7.711,0.565
                    c0.536,0.039,1.073,0.079,1.61,0.118c0.153,0.011,0.236-2.261,0.254-2.504c0.057-0.771,0.114-1.542,0.171-2.312
                    c-0.701,9.561-1.401,19.122-2.103,28.683c-0.384,5.233-0.767,10.466-1.15,15.699c0.136-1.849,0.271-3.697,0.407-5.546
                    c-9.063-0.584-18.128-1.169-27.191-1.753c-0.016-0.001-0.032-0.002-0.048-0.003c0.421-5.749,0.841-11.497,1.262-17.246
                    c4.479,0.329,8.958,0.657,13.437,0.986c0.049-0.685,0.099-1.368,0.147-2.053c-0.112,1.527-0.224,3.056-0.336,4.583
                    c0.086-0.742,0.173-1.484,0.259-2.227c0.044-0.383,0.096-0.282,0.464-0.256c1.5,0.109,3,0.22,4.501,0.329
                    c2.953,0.216,5.907,0.432,8.86,0.647"/>
                  <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M922.465,1422.079c8.303,0.608,16.604,1.218,24.907,1.826c10.924,0.801,21.848,1.602,32.772,2.402
                    c0.297,0.022,1.802,0.354,2.048,0.15c0.09-0.074,0.053-0.698,0.061-0.807c0.056-0.741,0.112-1.482,0.168-2.223
                    c0.432-5.729,0.863-11.456,1.295-17.184c0.374-4.975,0.749-9.949,1.124-14.924c0.085-1.121,0.169-2.242,0.254-3.363
                    c0.021-0.295,3.924,0.149,4.489,0.19c-1.234-0.09-2.47-0.179-3.704-0.269c-0.315-0.022-0.628-0.084-0.938-0.024
                    c-0.821,0.158-1.642,0.316-2.462,0.475c-4.002,0.772-8.004,1.544-12.005,2.315c-12.423,2.396-24.847,4.792-37.27,7.188
                    c-8.749,1.688-17.499,3.374-26.248,5.062c-0.3,0.059-0.6,0.116-0.899,0.174c6.754-1.303,13.508-2.605,20.262-3.907
                    c12.608-2.432,25.217-4.863,37.824-7.295c6.983-1.348,13.967-2.694,20.95-4.041c0.004-0.001,0.007-0.001,0.011-0.002
                    c-0.745,9.888-1.49,19.775-2.235,29.663c-0.226,2.99-0.45,5.98-0.676,8.971c6.332,0.464,12.663,0.929,18.995,1.393
                    c-0.843-1.693-1.685-3.387-2.527-5.08c4.274,8.798,8.549,17.595,12.824,26.393c1.754,3.61,3.509,7.222,5.264,10.832
                    c0.117,0.243,0.252,0.174,0.533,0.193c0.977,0.067,1.953,0.135,2.931,0.202c3.133,0.215,6.265,0.431,9.397,0.646
                    c1.815,0.125,3.631,0.25,5.445,0.375c0.307-4.784,0.612-9.569,0.919-14.354c0.044-0.697,0.089-1.396,0.134-2.093
                    c0.007-0.118-1.683-0.204-1.848-0.219c-2.416-0.221-4.832-0.44-7.248-0.66c-0.224,3.057-0.448,6.112-0.672,9.169
                    c0.224-3.057,0.448-6.112,0.672-9.169c2.361,0.215,4.723,0.43,7.083,0.645c0.147,0.014,2.019,0.144,2.014,0.212
                    c-0.042,0.659-0.084,1.318-0.126,1.978c-0.31,4.83-0.619,9.661-0.928,14.491c-4.947-0.341-9.896-0.682-14.843-1.021
                    c-0.978-0.067-1.954-0.135-2.931-0.202c-0.279-0.02-0.404-0.105-0.555,0.117c-0.761,1.124-1.521,2.248-2.281,3.372
                    c-6.45,9.534-12.899,19.067-19.349,28.602c-0.572,0.846-1.144,1.691-1.716,2.537c0.854-1.285,1.706-2.571,2.56-3.856
                    c-6.229-0.455-12.458-0.911-18.688-1.366c-0.677,9.604-1.353,19.21-2.029,28.814c-0.349,4.963-0.698,9.925-1.048,14.888
                    c-6.678-2.495-13.356-4.99-20.034-7.485c4.528,1.692,9.057,3.384,13.586,5.076c2.06,0.77,4.12,1.539,6.181,2.31
                    c1.763,0.658,3.687,0.999,5.507,1.478c0.012,0.003,0.024,0.007,0.036,0.01c-0.623-0.164-5.293-1.133-5.269-1.484
                    c0.085-1.205,0.17-2.41,0.255-3.615c0.399-5.682,0.8-11.362,1.199-17.044c0.42-5.951,0.839-11.902,1.258-17.854
                    c0.108-1.542,0.217-3.085,0.325-4.628c0.046-0.639-0.098-0.475-0.733-0.521c-10.079-0.739-20.157-1.479-30.236-2.219
                    c-9.387-0.688-18.773-1.378-28.16-2.066c-0.083-0.006-0.167-0.013-0.251-0.019"/>
                  <path fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M980.546,1449.979
                    c0.183-2.488,0.365-4.976,0.548-7.464c0.02-0.26-0.045-1.715,0.257-1.692c0.934,0.068,1.868,0.137,2.802,0.205
                    c3.027,0.222,6.056,0.444,9.084,0.666c0.118,0.009,1.72,0.1,1.716,0.148c-0.075,1.03-0.15,2.06-0.226,3.09
                    c-0.147,2.018-0.295,4.036-0.442,6.054c-4.554-0.334-9.106-0.668-13.66-1.002"/>
                  <path fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M978.742,1474.584
                    c0.224-3.055,0.447-6.11,0.671-9.165c4.58,0.336,9.16,0.671,13.74,1.007c-0.152,2.08-0.306,4.161-0.458,6.241
                    c-0.068,0.929-0.137,1.857-0.205,2.785c-0.021,0.293-0.77,0.082-1.003,0.064c-4.222-0.31-8.444-0.619-12.666-0.929"/>
                  <path fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M952.943,1447.957
                    c0.224-3.056,0.448-6.112,0.672-9.168c4.579,0.336,9.158,0.671,13.737,1.007c-0.224,3.056-0.448,6.111-0.672,9.167
                    c-4.554-0.334-9.106-0.668-13.66-1.002"/>
                  <path fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M955.818,1410.923
                    c-0.056-2.086-0.111-4.171-0.168-6.257c-0.024-0.931-0.05-1.861-0.074-2.792c-0.008-0.292,0.746-0.158,0.979-0.164
                    c4.262-0.115,8.523-0.23,12.785-0.346c0.057,2.086,0.112,4.172,0.169,6.258c0.025,0.931,0.05,1.862,0.075,2.793
                    c0.008,0.293-0.758,0.158-0.992,0.165c-4.23,0.114-8.462,0.229-12.693,0.343"/>
                  <path fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M947.326,1500.387
                    c4.517,0.827,9.032,1.654,13.549,2.481c-0.409,2.231-0.818,4.464-1.228,6.695c-0.06,0.325-0.144,2.155-0.43,2.344
                    c-0.203,0.134-1.31-0.239-1.548-0.282c-0.516-0.094-1.031-0.188-1.547-0.282"/>
                  <path fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M928.18,1411.67
                    c-0.083-3.062-0.166-6.124-0.249-9.187c4.224-0.114,8.447-0.229,12.67-0.343c0.12-0.003,1.093-0.093,1.096,0.021
                    c0.024,0.897,0.049,1.796,0.073,2.693c0.059,2.148,0.116,4.296,0.175,6.444c-4.562,0.123-9.125,0.246-13.688,0.369"/>
                  <path fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M951.139,1472.561
                    c0.183-2.488,0.365-4.976,0.547-7.464c0.02-0.26-0.045-1.715,0.257-1.692c0.934,0.068,1.868,0.137,2.802,0.205
                    c3.028,0.222,6.057,0.443,9.084,0.665c0.119,0.009,1.721,0.1,1.717,0.148c-0.075,1.03-0.15,2.06-0.226,3.09
                    c-0.147,2.018-0.296,4.036-0.443,6.054c-4.554-0.334-9.106-0.668-13.66-1.002"/>
                  <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M897.398,1508.236c4.599,0.973,9.197,1.945,13.795,2.918c0.404,0.086,0.409-0.474,0.56-0.812c0.485-1.103,1.107-2.153,1.868-3.089
                    c2.332-2.872,5.453-3.648,8.981-2.825c3.529,0.824,7.006,1.925,10.396,3.205c3.604,1.36,7.256,2.925,10.402,5.178
                    c1.858,1.332,4.472,3.575,3.708,6.183c-0.692,2.366-3.765,3.162-5.869,3.57c-6.27,1.214-13.037,0.575-19.312-0.296
                    c-2.809-0.39-5.939-0.689-8.126-2.68c-2.274-2.07-3.01-5.445-2.388-8.387"/>
                </g>
                 { /*  city2  */  }

                 { /*  cathedral  */  }
                <g id="calque_2_5_cathedral" >
                  <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M765.682,1570.031c-0.205,0.094,0.22,2.178,0.249,2.412c0.439,3.488,0.879,6.976,1.318,10.463
                    c9.025-4.112,32.168-14.657,34.713-15.816c2.745,6.039,31.207,68.654,34.718,76.378c0.103,0.226,0.205,0.451,0.308,0.677
                    c-2.745-6.039-31.207-68.654-34.718-76.378c-0.102-0.224-0.203-0.448-0.306-0.672c-0.005-0.012,8.306-3.81,11.169-5.121
                    c2.74,6.043,31.149,68.703,34.654,76.433c0.102,0.226,0.204,0.451,0.307,0.677c-2.74-6.043-31.149-68.703-34.654-76.433
                    c-0.102-0.225-0.204-0.449-0.306-0.675c-0.003-0.006,8.837-4.049,11.853-5.43c-7.322,3.359-55.973,25.677-57.119,26.203
                    c-0.598,0.273,6.076,37.393,6.441,39.46c0.084,0.473-0.271,1.849-0.563,1.981c-2.203,1.003-4.405,2.007-6.608,3.011
                    c4.525-2.062,15.011-6.626,15.151-6.903c0.063-0.124-7.732-40.174-7.829-40.677c1.85,9.61,7.069,36.728,7.829,40.677
                    c4.39-2.02,13.864-6.239,13.773-6.445c-0.145-0.324-12.337-27.779-17.256-38.855c-1.348-3.034-2.695-6.069-4.043-9.104
                    c3.327,7.269,17.499,39.322,21.339,48.05c8.318-3.801,38.688-17.679,45.554-20.816c-6.641,3.04-66.499,30.446-67.092,30.718
                    c-0.252,0.115,1.657,10.531,2.028,12.873c2.778-1.261,5.558-2.521,8.336-3.782c2.534,9.56,8.042,30.338,8.262,31.168
                    c-2.533-9.559-8.43-31.091-8.161-31.212c1.452-0.66,25.469-11.566,27.541-12.508c-3.709-8.095-18.433-40.23-22.086-48.204
                    c2.771,6.019,31.553,68.55,35.116,76.293c0.109,0.237,0.219,0.475,0.328,0.712c-4.136-8.917-13.17-28.887-13.503-28.735
                    c-1.262,0.573-35.887,15.974-35.778,16.567c0.309,1.693,5.119,28.094,5.538,30.396c0.09,0.491-1.062,2.189-1.289,2.292
                    c-1.57,0.716-3.14,1.431-4.71,2.146c5.798-2.643,11.595-5.284,17.393-7.927c3.318,9.365,6.637,18.73,9.956,28.096
                    c0.385,1.087,0.771,2.174,1.155,3.261c2.208-0.979,9.877-3.891,9.957-3.714c2.139,4.709,10.595,23.33,12.684,27.932
                    c-4.058-8.936-12.687-27.938-12.943-28.503c-0.013-0.029,8.326-3.811,11.198-5.119c4.043,8.947,8.087,17.894,12.13,26.841
                    c0.271,0.599,0.541,1.197,0.812,1.796c-4.043-8.947-12.659-28.013-12.924-28.599c-0.038-0.084,2.034-0.973,2.202-1.05
                    c2.85-1.299,5.698-2.597,8.548-3.896c-9.039,4.119-32.034,14.597-34.492,15.717c0.943-0.469,1.886-0.937,2.829-1.405
                    c3.513,9.157,7.026,18.313,10.539,27.471c0.247,0.642,0.493,1.284,0.739,1.926c7.529-3.432,15.06-6.862,22.589-10.294
                    c-2.082,0.948-4.164,1.897-6.246,2.846c-1.294,0.59-2.588,1.18-3.882,1.769c-0.25,0.114-1.162,0.325-1.046,0.647
                    c1.745,4.813,5.371,14.814,5.438,15.002c1.309-0.571,5.296-2.313,5.981-2.613c0.348-0.151,2.946,5.751,4.193,8.448
                    c-1.041-2.25-4.086-8.49-3.852-8.602c1.173-0.556,6.08-2.865,6.066-2.894c-0.286-0.619-6.03-13.058-6.685-14.474
                    c2.264-1.031,8.491-3.811,8.453-3.896c-0.184-0.409-11.009-24.603-12.841-28.697c1.371-0.605,2.743-1.21,4.114-1.815
                    c-1.134,0.5-2.268,1.001-3.402,1.501c-0.567,0.251-2.373-3.292-3.122-4.917c-2.625-5.7-9.832-21.346-10.81-23.469
                    c-0.208-0.452-0.417-0.904-0.625-1.356c-0.083-0.182-0.334,0.041-0.483,0.109c-4.653,2.12-22.596,10.297-26.913,12.264
                    c6.675-3.042,43.029-19.608,54.531-24.85c4.103-1.869,8.205-3.739,12.308-5.608c-8.783,4.002-17.565,8.005-26.349,12.007
                    c-4.379,1.996-8.757,3.991-13.136,5.986c9.065,1.05,42.42,4.913,50.031,5.795c-9.862-1.167-31.877-3.772-33.021-3.908
                    c2.01,4.411,7.79,17.095,8.67,19.025c0.183,0.401,17.783-7.889,25.365-11.344c-4.437,2.021-16.951,7.725-18.772,8.555
                    c-0.174,0.079,2.052,4.719,2.239,5.13c0.965,2.117,1.929,4.234,2.894,6.352c0.005,0.01,14.288-6.463,19.296-8.729
                    c0.137-0.062,0.274-0.124,0.411-0.187c-6.568,2.974-13.137,5.946-19.705,8.92c2.659,3.979,6.312,7.553,10.345,10.107
                    c3.317,2.101,7.13,3.505,11.095,3.468c1.024-0.01,2.047-0.113,3.053-0.307c0.363-0.07,1.02-0.001,0.603-0.374
                    c-0.769-0.688-1.572-1.335-2.342-2.021c-4.536-4.051-8.663-8.812-11.202-14.386c2.038,4.474,5.024,8.502,8.428,12.03
                    c1.156,1.199,2.368,2.352,3.653,3.412c0.21,0.173,1.316,1.281,1.643,1.169c0.79-0.274,1.588-0.525,2.378-0.798
                    c0.286-0.099,1.143-0.627,1.29-0.304c0.457,1.002,0.913,2.004,1.37,3.006c1.015,2.227,2.03,4.453,3.045,6.681
                    c0.001,0.001,0.001,0.003,0.002,0.004c-1.17,0.532-2.339,1.065-3.509,1.598c0.363-0.166,3.494-1.63,3.523-1.566
                    c0.532,1.168,1.064,2.337,1.597,3.505c1.342,2.946,2.685,5.892,4.026,8.838"/>
                  <path fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M799.986,1637.179c1.497,3.122,2.926,6.247,4.296,9.427"/>
                  <path fill="none" stroke="#FFFFFF" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M856.405,1641.773c0.765,1.514,1.448,3.005,2.085,4.576"/>
                  <path fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M810.901,1668.566c-1.55,0.706-2.965,1.866-3.516,3.529c-0.603,1.824-0.048,3.859,0.911,5.458c2.084,3.472,6.722,3.525,9.425,0.686
                    c2.514-2.642,2.113-7.432-1.084-9.392c-1.604-0.983-3.739-0.943-5.457-0.27"/>
                  <path fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M782.808,1598.097c1.066,3.294,2.065,6.582,3,9.916"/>
                  <path fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M866.253,1635.874c0.64,1.57,1.316,3.064,2.085,4.575"/>
                  <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M873.163,1521.054c0.261-0.119,1.404,1.438,1.585,1.64c2.384,2.655,4.768,5.312,7.151,7.968c-9.042,4.12-32.315,14.728-34.911,15.91
                    c2.757,6.033,31.338,68.595,34.863,76.312c0.103,0.226-34.759-76.084-34.861-76.308c-0.005-0.012-8.371,3.791-11.256,5.099
                    c2.763,6.033,31.409,68.585,34.943,76.301c0.104,0.226-31.1-67.909-34.634-75.625c-0.103-0.225-9.249,3.378-12.251,4.739
                    c7.307-3.308,43.215-19.562,53.861-24.382c1.202-0.544,4.15-1.994,4.558-1.491c2.801,3.453,5.602,6.906,8.402,10.358
                    c4.006,4.938,17.415,21.423,17.815,21.24c2.356-1.073,4.712-2.147,7.068-3.221c-4.52,2.06-15.646,6.427-15.672,6.393
                    c-1.076-1.368-24.844-31.597-25.178-32.021c6.051,7.695,23.125,29.411,25.611,32.573c-4.43,2-13.885,6.406-13.98,6.202
                    c-0.15-0.322-20.815-44.531-22.222-47.539c3.303,7.28,18.196,39.006,22.264,47.629c-8.31,3.776-38.883,17.67-45.859,20.841
                    c6.629-3.007,66.918-30.352,67.566-30.646c0.27-0.122,6.771,7.993,8.425,9.959c-2.791,1.277-5.582,2.554-8.373,3.831
                    c5.561,8.18,11.121,16.359,16.682,24.539c0.482,0.71,0.965,1.421,1.448,2.131c-5.561-8.18-11.121-16.358-16.682-24.538
                    c-0.161-0.238-1.279-2.208-1.548-2.086c-1.447,0.662-25.55,11.683-27.667,12.65c-3.675-8.109-18.267-40.306-21.887-48.294
                    c2.725,6.039,31.029,68.788,34.534,76.558c0.107,0.238,0.215,0.477,0.322,0.715c-4.016-8.972-13.156-28.893-12.826-29.044
                    c1.252-0.572,29.598-13.536,34.394-15.729c0.379-0.174,1.431-0.947,1.743-0.558c1.03,1.283,19.958,24.867,20.298,25.292
                    c0.236,0.294,4.724-1.986,6.42-2.76c-5.831,2.657-11.663,5.315-17.494,7.973c4.895,8.646,15.82,27.946,16.389,28.95
                    c-2.2,1.029-8.565,4.008-9.548,4.468c-0.215,0.101,0.077,0.457,0.158,0.635c2.151,4.704,10.655,23.303,12.757,27.898
                    c-4.081-8.925-12.759-27.904-13.018-28.469c-0.013-0.029-2.449,1.1-2.61,1.173c-2.889,1.316-5.777,2.633-8.666,3.949
                    c4.1,8.921,8.199,17.843,12.299,26.764c0.274,0.598,0.549,1.194,0.823,1.791c-4.1-8.921-8.199-17.843-12.299-26.764
                    c-0.269-0.585-2.986-0.805-3.162-0.726c-2.83,1.29-5.659,2.579-8.489,3.869c9.056-4.127,18.111-8.254,27.167-12.381
                    c2.508-1.143,5.016-2.286,7.523-3.429c-0.979,0.407-1.957,0.813-2.936,1.221c4.608,8.658,14.472,27.188,14.795,27.794
                    c-7.573,3.451-15.146,6.901-22.72,10.353c1.185-0.54,11.161-5.381,11.348-5.045c2.483,4.461,4.966,8.923,7.449,13.384
                    c0.11,0.198,0.221,0.397,0.331,0.596c-1.263,0.6-5.171,2.454-5.862,2.782c-0.371,0.176-0.302,0.15-0.135,0.523
                    c1.229,2.739,2.458,5.479,3.688,8.218c-1.015-2.262-2.029-4.523-3.045-6.785c-0.104-0.233-0.68-1.87-0.919-1.766
                    c-1.195,0.523-6.186,2.725-6.199,2.696c-0.279-0.622-0.559-1.245-0.839-1.867c-1.26-2.802-5.057-11.252-5.696-12.674
                    c-2.241,1.021-4.483,2.043-6.725,3.063c-0.128,0.059-1.781,0.849-1.806,0.794c-0.179-0.385-0.357-0.769-0.536-1.153
                    c-2.318-4.998-10.787-23.25-12.702-27.378c-1.364,0.642-2.729,1.282-4.094,1.924c1.128-0.53,2.257-1.061,3.385-1.591
                    c0.563-0.265,0.785-0.165,0.547-0.691c-0.734-1.631-1.47-3.262-2.205-4.893c-2.579-5.722-5.158-11.443-7.737-17.164
                    c-0.961-2.131-1.921-4.262-2.882-6.392c-0.205-0.454-0.364-1.587-0.216-1.654c4.651-2.119,22.7-10.344,27.074-12.337
                    c-6.633,3.022-62.96,28.689-67.228,30.634c8.764-3.993,35.239-16.058,39.714-18.097c-5.157,7.491-24.276,35.266-28.68,41.662
                    c5.615-8.2,18.192-26.568,18.866-27.552c2.917,6.401,5.834,12.802,8.751,19.203c-8.421,3.838-16.842,7.675-25.263,11.513
                    c-0.057,0.025-0.112,0.051-0.168,0.076c4.426-2.017,16.994-7.744,18.853-8.591c0.237-0.108,4.33,9.209,5.32,11.383
                    c0.011,0.022-1.896,0.896-3.111,1.448c-0.511,0.232-0.899,0.409-0.972,0.442c-5.052,2.317-10.104,4.634-15.155,6.951
                    c-0.177,0.081-0.353,0.161-0.528,0.242c6.591-3.023,13.183-6.046,19.773-9.069c0.93,3.421,1.268,7.086,1.159,10.618
                    c-0.096,3.143-0.615,6.306-1.808,9.227c-0.615,1.509-1.412,2.945-2.398,4.244c-0.539,0.71-1.134,1.378-1.777,1.995
                    c-0.235,0.228-1.68,1.735-1.973,1.628c-0.162-0.06-0.011-1.791-0.009-1.944c0.014-1.645-0.026-3.289-0.14-4.93
                    c-0.34-4.923-1.29-9.888-3.346-14.398c2.181,4.786,3.271,10.062,3.613,15.294c0.105,1.604,0.152,3.222,0.051,4.827
                    c-0.021,0.341-0.037,0.693-0.114,1.026c-0.086,0.37-1.064,0.723-1.366,0.9c-0.145,0.085-2.004,1.139-2.007,1.131
                    c0.115,0.253,0.23,0.506,0.346,0.759c0.577,1.266,1.153,2.531,1.73,3.798c0.618,1.354,1.235,2.71,1.853,4.065
                    c0.162,0.354,0.323,0.709,0.485,1.063c0.122,0.268,0.312,0.039,0.555-0.071c1.015-0.462,2.028-0.923,3.042-1.385
                    c-1.176,0.535-2.353,1.071-3.528,1.606c1.88,4.125,3.759,8.25,5.639,12.375"/>
                  <path fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M901.34,1590.992c1.374,3.179,2.795,6.307,4.296,9.427"/>
                  <path fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M917.884,1619.816c1.557-0.71,3.369-1.023,4.995-0.349c1.775,0.736,2.952,2.489,3.527,4.265c1.247,3.846-1.751,7.354-5.645,7.579
                    c-3.624,0.208-6.983-3.117-6.481-6.812c0.262-1.931,1.736-3.581,3.426-4.464"/>
                  <path fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M881.112,1550.172c1.788,2.966,3.616,5.875,5.523,8.766"/>
                </g>
                 { /*  cathedral  */  }

              <path fill="none" stroke="#FFFFFF" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" d="M892.36,1414.531
                c0.081,17.826-3.07,35.263-9.359,52.007"/>
              <path fill="none" stroke="#FFFFFF" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" d="M838.448,1531.675
                c-5.316,3.914-10.897,7.157-16.736,9.79c-9.125,4.114-18.88,6.739-29.238,8.111"/>
              <path fill="none" stroke="#FFFFFF" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" d="M694.129,1537.465
                c-17.124-4.73-35.104-10.436-53.887-16.63"/>

                 { /*  Lampadaire  */  }
                <g id="calque_2_5_lampadaire">
                  <path fill="none" stroke="#FFFFFF" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M664.621,1539.072c-0.725-0.233-1.449-0.465-2.171-0.697c-0.483-0.156-0.964-0.31-1.445-0.464c-0.101-0.032-0.679-0.136-0.728-0.234
                    c-0.037-0.072,0.139-0.432,0.162-0.506c0.146-0.45,0.29-0.9,0.434-1.351c0.321-1.003,0.644-2.006,0.966-3.009
                    c0.093-0.291,0.075-0.814,0.423-0.703c0.578,0.186,1.156,0.37,1.735,0.556c2.051,0.657,4.102,1.316,6.153,1.976
                    c1.282,0.412,2.563,0.822,3.844,1.234c0.257,0.082,0.37,0.029,0.285,0.291c-0.218,0.683-0.438,1.367-0.658,2.051
                    c-0.272,0.848-0.543,1.693-0.815,2.54c-0.071,0.219-0.134,0.901-0.404,0.813c-1.265-0.406-2.583-0.829-3.818-1.224
                    c0.85,0.272,1.7,0.546,2.552,0.817c-2.466,6.226-4.932,12.45-7.397,18.677c-0.092,0.236-0.188,0.472-0.281,0.709
                    c0.505,0.163,1.071,0.345,1.541,0.495c-3.182-1.021-6.469-2.076-9.591-3.078c0.381,0.123,0.764,0.245,1.146,0.367
                    c1.583-6.403,3.165-12.808,4.747-19.212c0.037-0.152,0.076-0.305,0.113-0.456c-1.573,6.38-3.147,12.76-4.72,19.14
                    c-0.11,0.444-0.167,0.395,0.314,0.551c0.268,0.084,0.965,0.088,0.853,0.44c-0.264,0.823-0.528,1.644-0.791,2.465
                    c-1.162,3.62-2.324,7.241-3.486,10.862c-2.078,6.472-4.154,12.945-6.233,19.417c-0.066,0.205,0.681,0.309,0.834,0.357
                    c0.979,0.315,1.959,0.632,2.94,0.944c0.286,0.092,0.356,0.219,0.441-0.051c0.099-0.305,0.198-0.611,0.295-0.915
                    c1.002-3.119,2.001-6.237,3.002-9.354c2.318-7.22,4.634-14.438,6.952-21.658c0.083-0.254,0.163-0.508,0.245-0.762
                    c-1.905,6.032-3.811,12.067-5.717,18.102c-1.559,4.938-3.119,9.874-4.678,14.812c-2.867-0.92-5.735-1.841-8.602-2.761
                    c-1.673-0.536-3.346-1.073-5.019-1.611c-0.315-0.101-0.631-0.201-0.948-0.304c-0.028-0.009,0.483-1.552,0.524-1.68
                    c-0.161,0.5-0.321,1.001-0.481,1.502c-0.093,0.287-0.37,0.185-0.657,0.254c-1.938,0.471-2.318,2.438-2.85,4.092
                    c-0.227,0.705-0.453,1.41-0.679,2.116c-0.076,0.236-0.563-0.054-0.754-0.116c-1.02-0.326-2.038-0.653-3.058-0.981
                    c0.37,0.148,0.742,0.294,1.113,0.441c-1.173,2.921-2.345,5.844-3.519,8.766c-0.727,1.812-1.454,3.623-2.18,5.434
                    c-0.175,0.436-0.352,0.872-0.525,1.308c-0.108,0.27-1.132-0.239-1.388-0.322c0.459,0.147,0.919,0.296,1.379,0.443
                    c0.08,0.027,0.157,0.429,0.181,0.501c0.352,1.062,0.702,2.125,1.055,3.185c0.636,1.924,1.271,3.845,1.909,5.766
                    c1.718-1.324,3.436-2.65,5.156-3.975c0.805-0.622,1.611-1.243,2.417-1.864c0.229-0.177,0.321-0.068,0.615,0.025
                    c0.475,0.152,1,0.32,1.449,0.464c-4.195-1.347-8.389-2.692-12.584-4.039c3.642,1.184,7.283,2.365,10.924,3.549
                    c1.274-5.508,2.548-11.017,3.822-16.525c0.522,0.168,1.124,0.359,1.604,0.515c-2.516-0.809-5.035-1.615-7.55-2.424
                    c1.297,0.43,2.594,0.861,3.891,1.291c0.44-1.368,0.878-2.734,1.317-4.104c0.418-1.301,0.283-2.654-0.9-3.501
                    c6.563,2.105,13.128,4.212,19.692,6.32c1.394,0.447,2.788,0.895,4.182,1.342c0.228,0.072,0.456,0.146,0.683,0.219
                    c0.009,0.003,0.637-1.965,0.702-2.173c-0.197,0.641-0.394,1.282-0.591,1.922c-0.124,0.406,0.444,0.73,0.659,1.093
                    c0.655,1.094,0.427,2.143,0.042,3.289c-0.372,1.102-0.767,2.198-1.19,3.28c1.412,0.452,2.834,0.91,4.238,1.361
                    c-3.964-1.273-8.012-2.573-11.93-3.83c0.548,0.175,1.095,0.352,1.642,0.527c-1.237,2.928-2.476,5.855-3.714,8.784
                    c-0.763,1.805-1.526,3.609-2.29,5.412c-0.188,0.446-0.376,0.893-0.564,1.338c-0.137,0.323-1.204-0.234-1.493-0.327
                    c-0.001-0.001-0.122-0.04-0.108-0.036c4.91,1.577,9.82,3.153,14.73,4.729c-4.279-1.388-8.559-2.775-12.837-4.164
                    c0.918,3.152,1.837,6.303,2.755,9.452c2.595-1.965,5.191-3.928,7.787-5.893c0.173-0.132,0.166-0.272,0.219-0.489
                    c0.143-0.594,0.286-1.188,0.429-1.782c0.486-2.016,0.973-4.027,1.458-6.042c0.485-2.015,0.971-4.027,1.457-6.042
                    c0.144-0.594,0.286-1.188,0.43-1.783c0.071-0.298,0.184-0.365-0.116-0.461c-1.825-0.587-3.651-1.174-5.478-1.76
                    c-0.24-0.077-0.481-0.154-0.721-0.231c-0.125-0.039,0.085-0.454,0.116-0.552c0.417-1.298,0.834-2.596,1.25-3.892
                    c0.433-1.347,1.666-2.103,3.034-1.634"/>
                </g>
                 { /*  Lampadaire  */  }

              <path fill="none" stroke="#FFFFFF" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" d="M543.104,1489.01
                c-17.249-5.286-34.967-10.376-53.127-15.011"/>
              <path fill="none" stroke="#FFFFFF" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" d="M391.571,1455.352
                c-18.305-2.116-36.951-3.439-55.912-3.758"/>
              <path fill="none" stroke="#FFFFFF" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" d="M240.366,1458.755
                c-19.627,3.074-37.563,6.66-53.976,10.686"/>


                 { /*  Bird  */  }
                <g id="calque__bird" >
                  <path fill="none" stroke="#FFFFFF" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M155.564,1391.41c1.397,0.045,2.929,0.271,4.166,0.953c1.384,0.766,1.381,2.301,2.463,3.264c1.291,1.146,3.681,0.111,4.141,2.058
                    c0.2,0.845,0.362,1.672,0.929,2.363c0.832,1.016,2.27,1.693,3.527,1.989c1.722,0.406,5.057,0.329,6.121-1.362
                    c0.406-0.646,0.595-1.756,0.149-2.44c-0.744-1.143-2.548-1.453-3.766-1.663c-0.971-0.168-2.303-0.005-3.047-0.76
                    c-0.505-0.513-0.8-1.301-0.842-2.014c-0.039-0.643,2.456-0.813,2.957-0.953c2.118-0.591,4.09-1.564,5.441-3.348
                    c3.143-4.146,2.923-9.769,1.442-14.509c-2.987,3.685-6.203,7.29-9.786,10.404c-2.123,1.846-4.316,3.493-7.247,3.505
                    c-1.296,0.005-2.581-0.402-3.873-0.33c-1.532,0.087-2.382,1.396-2.976,2.669"/>
                  <path fill="none" stroke="#FFFFFF" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M167.833,1386.403c1.365-4.172,1.298-9.52-1.706-12.967c-0.904,2.188-1.942,4.347-3.172,6.37c-0.932,1.532-2.158,2.81-3.196,4.262
                    c-0.98,1.371-1.042,2.677-0.296,4.188"/>
                  <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M158.697,1390.271c0.247,0,0.354-0.424,0.061-0.424c-0.323,0-0.22,0.3-0.076,0.411"/>
                </g>
                 { /*  Bird  */  }

                 { /*  Sun  */  }
                <g id="calque_sun" >
                  <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M11.873,1512.706c-3.199-2.312-5.761-5.813-7.228-9.443c-1.43-3.537-1.782-7.408-1.099-11.157c0.787-4.316,2.713-9.179,6.146-12.067
                    c4.427-3.726,10.784-4.37,16.346-3.892c0.415,0.036,2.446,0,2.506,0.5c0.334,2.784,2.25,5.597,3.687,7.894
                    c-2.9-4.021-5.082-9.631-3.901-14.6c1.716-7.224,9.951-10.172,16.576-8.214c-0.897,2.492-1.515,5.596-0.657,8.168
                    c-0.861-4.273,0.187-9.262,3.406-12.322c2.918-2.774,7.493-4.068,11.458-3.662c3.057,0.313,5.746,1.886,7.639,4.285
                    c1.278,1.619,2.285,3.583,2.772,5.597c-0.391-1.726-1.185-3.407-2.18-4.861c-0.918-1.342-2.08-2.521-3.463-3.383
                    c-0.758-0.472-1.575-0.845-2.427-1.109c-0.261-0.081-2.991-0.646-2.997-0.511c0.115-3.06,0.545-6.205,1.449-9.133
                    c0.285-0.923,0.629-1.834,1.092-2.684c0.327-0.601,0.38-0.533,0.025-1.065c-1.095-1.646-2.433-3.139-3.849-4.514
                    c-5.553-5.393-14.378-7.288-21.882-6.102c-5.38,0.852-9.978,3.587-13.896,7.294c-3.257,3.081-5.908,6.856-7.16,11.196
                    c-1.695,5.879-0.591,12.104,1.993,17.548c1.013,2.135,2.242,4.172,3.66,6.061c0.771,1.027,1.602,2.155,2.625,2.951
                    c-7.154-7.688-11.531-19.664-6.978-29.691c2.062-4.54,5.603-8.238,9.56-11.195c4.228-3.16,9.343-4.825,14.619-4.751
                    c3.986,0.056,7.96,1.019,11.595,2.64c2.292,1.021,4.006,2.165,5.668,4.038c1.502,1.692,2.941,3.442,4.357,5.208
                    c2.531-4.036,7.235-6.771,11.792-7.757c4.371-0.945,9.277-0.628,13.367,1.247c3.316,1.521,6.015,4.096,7.918,7.188
                    c1.995,3.24,3.722,7.633,3.397,11.527c2.064-0.053,4.22,0.544,6.032,1.502c4.492,2.374,6.488,7.199,6.877,12.064
                    c-0.129-3.862-1.631-8.078-4.529-10.707c-5.237-4.751-15.112-3.599-19.282,2.037c-1.688,2.281-2.939,5.725-2.618,8.623"/>
                  <path fill="none" stroke="#FFFFFF" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M8.384,1468.83c0.728-0.296,1.456-0.592,2.185-0.888c0.637-0.259,1.251-0.851,1.821-1.24"/>
                  <path fill="none" stroke="#FFFFFF" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M10.38,1455.061c-1.544,0.193-3.088,0.387-4.632,0.58c-1.264,0.158-2.6,0.03-3.874,0.03"/>
                  <path fill="none" stroke="#FFFFFF" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M8.384,1442.793c0.434,0.462,0.788,1.053,1.412,1.213c0.865,0.222,1.729,0.443,2.594,0.665"/>
                  <path fill="none" stroke="#FFFFFF" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M18.649,1435.282c-2.664-2.929-5.341-5.836-8.061-8.713"/>
                  <path fill="none" stroke="#FFFFFF" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M28.163,1429.273c-0.593-1.696-1.219-3.267-2.003-4.882"/>
                  <path fill="none" stroke="#FFFFFF" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M39.481,1426.832c0-2.9,0-5.8,0-8.7"/>
                  <path fill="none" stroke="#FFFFFF" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M50.57,1429.273c0.403-1.632,0.816-3.258,1.252-4.882"/>
                  <path fill="none" stroke="#FFFFFF" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M59.959,1435.282c2.689-2.906,5.417-5.696,8.262-8.45"/>
                </g>
                 { /*  Sun  */  }

                 { /*  Nuages  */  }
                <g id="calque_nuages">
                  <path fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M-42,1481.504
                    c-15.752,10.298-28.579,24.742-39.289,40.076c-10.209,14.616-18.784,30.479-25.448,47.015c-2.56,6.353-4.858,12.838-6.562,19.476
                    c-0.959,3.735-1.63,7.569-3.578,10.957c-1.285,2.234-3.256,4.391-5.961,4.681c-4.077,0.437-7.481-3.202-9.235-6.471
                    c-2.031-3.784-2.829-8.833-0.26-12.57c-3.657,0.556-7.63,0.007-11.097-1.194c-8.412-2.914-13.614-10.525-15.602-18.963
                    c-1.81-7.681-0.359-16.381,5.195-22.248c0.742-0.783,1.564-1.528,2.499-2.077c0.547-0.321,0.127-0.76-0.066-1.337
                    c-1.128-3.367-1.984-6.833-2.633-10.322c-1.035-5.565-1.517-11.263-1.17-16.92c0.271-4.419,1.071-8.533,3.57-12.26
                    c4.756-7.092,13.238-10.938,21.511-11.921c4.961-0.591,10.021-0.195,14.855,1.047c2.655,0.682,5.244,1.618,7.729,2.774
                    c0.245,0.114,1.47,0.891,1.591,0.66c0.11-0.209,0.226-0.414,0.344-0.618c2.973-5.113,7.715-9.679,13.516-11.281
                    c2.934-0.811,5.797-0.616,8.781-0.252c2.069,0.252,4.248,0.429,6.254,1.031c1.233,0.37,0.243,0.646-0.444,0.714
                    c4.568-4.299,10.558-8.206,16.8-9.309c4.465-0.789,9.542,0.305,13.582,2.283c1.716,0.84,5.118,2.705,5.118,5.025"/>
                  <path fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M-165,1570.5
                    c-8.062,2.09-15.297,7.457-21.062,13.3c-6.875,6.967-12.348,15.654-14.62,25.243c-2.209,9.327-0.885,19.108,2.21,28.076
                    c1.534,4.444,3.454,8.893,5.971,12.881c-4.7,1.828-8.163,6.461-9.386,11.228c-1.165,4.537-0.856,9.755,0.688,14.171
                    c0.8,2.288,2.017,4.568,3.796,6.251c0.129,0.122,0.262,0.24,0.402,0.351"/>
                  <path fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M-153.5,1648
                    c-7.214,1.353-14.788,5.354-17.938,12.312c-1.524,3.367-2.203,7.68-1.537,11.343c0.208,1.143,0.589,2.52,1.475,3.346
                    c-5.443,0.877-11.746,2.919-15.567,7.063c-2.961,3.211-3.781,8.002-3.855,12.221c-0.072,4.086,0.469,8.6,2.373,12.276
                    c0.167,0.322,0.348,0.638,0.549,0.939c-8.274,2.983-15.317,10.276-18.019,18.592c-2.472,7.611-1.941,16.794,1.723,23.945
                    c3.001,5.856,8.211,10.114,14.144,12.788c6.193,2.79,13.09,4.085,19.862,4.197c1.554,0.026,2.614-1.76,3.175-2.959
                    c0.905-1.935,1.357-4.078,1.688-6.176c0.598-3.793,0.75-7.655,0.765-11.489c0.02-5.369-0.449-10.715-0.527-16.079
                    c-0.073-5.005,0.145-10.014,0.542-15.003c0.794-9.98,2.3-19.897,4.148-29.733c1.395-7.427,3.011-14.809,4.698-22.174
                    c0.559-2.441,1.132-4.881,1.659-7.33c0.26-1.208,0.643-2.507,0.643-3.753"/>
                  <path fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M-184.378,1972.105
                    c5.915,8.408,11.829,16.816,17.744,25.225c9.564,13.597,19.131,27.193,28.693,40.791c2.388,3.395,4.775,6.788,7.162,10.18
                    c-2.24-0.491-4.504-1.245-6.733-2.146c-3.873-1.561-8.09-3.698-11.323-7.398c-0.717-0.821-1.375-1.729-1.922-2.72
                    c-0.321-0.582-0.23-0.493-0.622-0.305c-0.971,0.464-1.995,0.81-3.051,1.048c-3.3,0.75-7.248,0.423-10.504-2.734
                    c-4.136-4.018-6.649-10.706-7.156-16.44c-0.087-0.996,0.038-1.082-0.554-1.839c-1.103-1.411-2.138-2.908-3.099-4.46
                    c-2.809-4.531-5.399-10.224-4.863-15.351c0.113-1.083-0.744-2.026-1.296-3.074c-1.058-2.012-2.01-4.108-2.729-6.27
                    c-0.531-1.594-0.943-3.245-1.06-4.86c-0.052-0.723-0.042-1.442,0.055-2.12c0.073-0.528,0.335-1.016,0.362-1.548
                    c0.115-2.001,0.232-3.999,0.347-5.999"/>
                  <path fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M-262.666,1788.549
                    c1.168-5.839,2.336-11.679,3.505-17.518c1.889-9.442,3.78-18.885,5.668-28.326c0.471-2.355,0.943-4.713,1.415-7.069
                    c-1.714,1.048-3.327,2.216-4.839,3.437c-2.63,2.117-5.285,4.613-6.347,7.418c-0.235,0.622-0.378,1.26-0.385,1.892
                    c-0.004,0.371,0.032,0.299-0.396,0.364c-1.062,0.16-2.116,0.393-3.148,0.684c-3.222,0.904-6.5,2.518-7.837,5.095
                    c-1.697,3.278-0.721,7.107,1.547,9.774c0.395,0.465,0.545,0.453,0.389,1.003c-0.292,1.02-0.485,2.053-0.587,3.081
                    c-0.3,3.007,0.137,6.433,3.026,8.449c0.61,0.426,0.311,1.153,0.325,1.812c0.033,1.264,0.194,2.524,0.591,3.727
                    c0.291,0.887,0.712,1.755,1.374,2.495c0.296,0.334,0.645,0.64,1.049,0.896c0.313,0.202,0.771,0.314,1.047,0.535
                    c1.046,0.822,2.091,1.642,3.135,2.464"/>
                </g>
                 { /*  Nuages  */  }

              <path fill="none" stroke="#FFFFFF" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" d="M92.493,1504.876
                c-14.873,8.429-27.299,17.438-37.817,26.792"/>
              <path fill="none" stroke="#FFFFFF" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" d="M-5.305,1624.538
                c-5.586,13.479-11.091,32.627-14.874,55.576"/>

                 { /*  shark  */  }
                <g id="calque_shark" >
                  <path fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M778.459,2053.618c3.681,1.83,5.15,5.446,3.584,9.22c-0.849,2.043-2.767,3.709-5.029,3.812c-3.992,0.18-8.795-3.449-7.497-7.759
                    c0.634-2.1,2.16-3.929,4.109-4.931c1.288-0.664,3.139-1.186,4.526-0.496"/>
                  <path fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M833.166,2013.62c-2.795,3.024-6.649,3.398-9.841,0.858c-1.866-1.483-2.902-4.01-2.138-6.331c1.302-3.954,6.161-7.128,9.883-4.269
                    c1.686,1.296,2.864,3.222,3.18,5.329c0.202,1.343,0.127,3.101-0.852,4.159"/>
                  <path fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M815.783,2005.905c4.082,0.455,6.729,3.359,6.527,7.448c-0.099,2.023-1.137,4.102-2.964,5.075c-3.028,1.611-8.513,0.622-9.822-2.867
                    c-1.35-3.599,1.181-9.014,5.169-9.668c0.247-0.04,0.5-0.055,0.748-0.026"/>
                  <path fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M160.944,2112.569
                    c2.441,0.456,5.103,0.335,7.483-0.354c0.928-0.269,1.829-0.638,2.665-1.123c0.072,0.021,0.208,0.251,0.258,0.306
                    c0.644,0.705,1.413,1.299,2.219,1.808c3.107,1.96,7.154,2.866,10.799,2.452c1.563-0.177,3.415-0.648,4.558-1.819
                    c1.9,2.355,5.086,3.704,7.973,4.263c3.253,0.63,7.393,0.505,10.243-1.453c0.002-0.001,1.762,1.439,1.878,1.519
                    c1.759,1.199,3.738,2.071,5.797,2.604c3.106,0.805,7.678,1.283,10.259-1.172c0.386-0.367,1.823,1.018,2.237,1.28
                    c2.211,1.402,4.677,2.637,7.288,3.039"/>
                  <path fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M189.986,2104.229
                    c1.679,0.423,3.533,0.459,5.22,0.08c0.572-0.129,1.637-0.896,2.019-0.42c0.423,0.527,0.943,0.98,1.491,1.373
                    c2.02,1.445,4.687,2.229,7.165,2.148c1.135-0.038,2.474-0.276,3.358-1.057c1.704,2.397,5.041,3.568,7.855,3.682
                    c1.488,0.06,3.076-0.159,4.419-0.838c0.372-0.188,1.143,0.844,1.461,1.089c2.65,2.042,7.268,3.759,10.456,1.991
                    c0.216-0.12,0.439-0.26,0.612-0.439c0.004-0.004,0.768,0.632,0.816,0.67c1.601,1.251,3.444,2.398,5.449,2.842"/>
                  <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M209.458,2105.363c0.688-4.755-0.277-10.397-3.675-13.96c5.502-1.1,11.714,0.479,16.135,3.849c3.34,2.545,5.184,5.961,5.914,10.062
                    c0.366,2.056,0.517,4.203,0.341,6.289"/>
                  <path fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M45.752,2081.478
                    c2.589,1.121,5.614,1.624,8.417,1.334c0.226-0.023,2.414-0.457,2.407-0.473c0.073,0.16,0.16,0.313,0.249,0.465
                    c0.505,0.858,1.153,1.633,1.859,2.332c2.703,2.676,6.551,4.505,10.316,4.979c1.711,0.215,3.819,0.188,5.329-0.788
                    c1.362,2.786,4.219,4.884,6.96,6.139c3.315,1.518,7.65,2.362,11.185,1.014"/>
                  <path fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M293.046,2101.423
                    c1.351,0.125,2.779-0.052,4.054-0.511c0.383-0.138,1.5-0.959,1.754-0.717c0.387,0.367,0.843,0.664,1.313,0.913
                    c2.312,1.224,6.875,1.94,8.833-0.365c1.299,1.332,3.329,1.937,5.132,2.054"/>
                  <path fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M316.898,2135.313
                    c1.928,0.185,3.979-0.081,5.792-0.753c0.775-0.287,1.519-0.663,2.195-1.14c0.056,0.01,0.186,0.188,0.227,0.226
                    c0.562,0.517,1.218,0.936,1.896,1.284c2.592,1.333,5.837,1.771,8.694,1.216c1.268-0.246,2.738-0.753,3.594-1.787
                    c1.815,1.874,4.68,2.72,7.205,2.884"/>
                </g>
                 { /*  shark  */  }

              <path fill="none" stroke="#FFFFFF" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" d="M-22.984,1781.201
                c2.124,19.825,5.954,40.302,11.986,60.864"/>  
              <path fill="none" stroke="#FFFFFF" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" d="M26.544,1924.198
                c10.717,16.479,23.426,32.488,38.416,47.705"/>
              <path fill="none" stroke="#FFFFFF" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" d="M151.293,2026.123
                c15.586,6.271,33.172,12.403,52.479,18.043"/>

                 { /*  Palmier  */  }
                <g id="calque_plamier2" >
                  <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M810.621,2083.031c1.088-2.539,2.085-5.119,3.056-7.703c0.257-0.685,2.153-4.949,1.692-5.213c-0.721-0.413-1.466-0.787-2.202-1.17
                    c-2.594-1.351-5.196-2.681-7.794-4.022c-0.291-0.149-4.696-2.271-4.634-2.452c-0.021,0.062,4.838,2.515,5.146,2.679
                    c2.669,1.418,5.331,2.848,8.01,4.249c0.433,0.228,1.134,0.783,1.637,0.824c0.146,0.012,1.05-3.053,1.148-3.347
                    c0.491-1.463,0.971-2.931,1.438-4.4c0.453-1.423,0.894-2.85,1.32-4.28c0.109-0.365,1.011-2.77,0.755-2.924
                    c-0.769-0.463-1.598-0.838-2.397-1.241c-3.28-1.647-6.576-3.262-9.874-4.874c1.934,0.988,3.869,1.972,5.807,2.951
                    c2.122,1.072,4.256,2.365,6.486,3.206c0.066,0.025,1.787-6.713,1.944-7.379c0.342-1.459,0.67-2.922,0.979-4.388
                    c0.092-0.435,0.827-2.747,0.492-2.975c-2.207-1.492-4.713-2.641-7.055-3.898c-0.813-0.438-1.626-0.875-2.441-1.31
                    c1.904,1.091,3.814,2.175,5.729,3.246c1.067,0.596,2.133,1.197,3.219,1.758c0.133,0.067,0.479,0.322,0.64,0.29
                    c0.235-0.046,0.593-3.313,0.653-3.678c0.438-2.67,0.817-5.351,1.103-8.042c0.14-1.319,0.256-2.64,0.336-3.963
                    c0.03-0.489-0.22-0.568-0.621-0.819c-1.989-1.244-4.126-2.275-6.223-3.328c1.918,1.162,3.839,2.332,5.816,3.388
                    c0.303,0.162,0.624,0.364,0.959,0.455c0.132,0.036,0.128-1.732,0.132-1.957c0.038-2.168,0.091-4.353-0.048-6.518
                    c2.104,0.375,4.488-0.083,6.185-1.407c2.888-2.254,3.554-6.301,1.113-9.094c-2.059-2.356-5.803-3.586-8.612-1.819
                    c-3.224,2.025-4.333,6.942-1.858,9.919c0.706,0.848,1.581,1.443,2.48,2.058c-0.999-0.882-1.999-1.762-2.999-2.644
                    c-0.985,2.374-2.796,5.271-5.44,5.953c-3.9,1.008-8.613-1.652-8.902-5.806c-0.245-3.519,2.7-7.14,6.396-7.056
                    c1.876,0.041,3.846,1.402,5.16,2.63c0.783,0.729,1.48,1.593,1.889,2.591c0.016,0.018,0.185,0.02,0.222,0.035
                    c0.556,0.237,0.334,1.198,0.234,1.619c-0.193,0.807-0.521,1.583-0.865,2.335c-0.358,0.782-0.984,1.599-1.225,2.412
                    c-0.261,0.896-0.537,1.786-0.817,2.675c-1.577,5.025-3.306,10.004-5.173,14.929c-2.556,6.735-5.366,13.4-8.733,19.773
                    c-2.853,5.402-6.146,11.144-10.905,15.107c-1.185,0.986-2.499,1.856-3.957,2.383"/>
                  <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M835.646,2011.105c1.379,0.176,2.757,0.352,4.137,0.527c0.272,0.033,1.265-1.1,1.471-1.281c0.535-0.471,1.07-0.941,1.606-1.412
                    c0.509,0.829,1.019,1.658,1.527,2.488c0.18,0.292,0.729,1.606,1.089,1.665c1.661,0.271,3.32,0.541,4.982,0.811
                    c4.05,0.66,8.1,1.319,12.15,1.98c-0.807-3.748-3.653-7.1-6.621-9.351c-3.634-2.756-8.052-4.32-12.511-5.107
                    c-3.848-0.678-8.131-0.854-11.831,0.624c-0.215,0.087-1.122,0.707-1.329,0.656c-1.185-0.288-2.37-0.576-3.555-0.865
                    c-0.283-0.069-0.245-1.781-0.275-2.062c-0.215-1.939-0.558-3.867-1.039-5.757c-1.326-5.201-3.979-9.834-7.648-13.749
                    c-2.591-2.762-5.765-5.095-9.431-6.171c-1.97-0.578-4.256-0.895-6.285-0.391c2.14,3.261,4.279,6.521,6.418,9.78
                    c1.034,1.577,2.069,3.154,3.103,4.73c0.267,0.404,0.513,0.353,1.018,0.454c1,0.203,2.002,0.405,3.004,0.608
                    c-0.556,1.374-1.111,2.749-1.668,4.122c-0.108,0.271,1.977,2.349,2.176,2.584c2.174,2.565,4.347,5.131,6.521,7.697
                    c-5.159-5.039-12.073-8.789-19.187-9.973c-6.103-1.017-14.251-1.268-18.739,3.875c-0.65,0.745-1.249,1.622-1.55,2.576
                    c3.963,0.861,7.925,1.723,11.887,2.584c1.509,0.327,3.018,0.656,4.526,0.983c0.302,0.065,1.521-1.229,1.756-1.425
                    c0.728-0.602,1.454-1.202,2.18-1.804c0.478,0.967,0.955,1.936,1.433,2.903c0.395,0.797,0.363,1.119,1.195,1.287
                    c2.174,0.438,4.348,0.878,6.521,1.317"/>
                  <path fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M778.459,2053.618c3.681,1.83,5.15,5.446,3.584,9.22c-0.849,2.043-2.767,3.709-5.029,3.812c-3.992,0.18-8.795-3.449-7.497-7.759
                    c0.634-2.1,2.16-3.929,4.109-4.931c1.288-0.664,3.139-1.186,4.526-0.496"/>
                  <path fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M833.166,2013.62c-2.795,3.024-6.649,3.398-9.841,0.858c-1.866-1.483-2.902-4.01-2.138-6.331c1.302-3.954,6.161-7.128,9.883-4.269
                    c1.686,1.296,2.864,3.222,3.18,5.329c0.202,1.343,0.127,3.101-0.852,4.159"/>
                  <path fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M815.783,2005.905c4.082,0.455,6.729,3.359,6.527,7.448c-0.099,2.023-1.137,4.102-2.964,5.075c-3.028,1.611-8.513,0.622-9.822-2.867
                    c-1.35-3.599,1.181-9.014,5.169-9.668c0.247-0.04,0.5-0.055,0.748-0.026"/>
                </g>
                 { /*  Palmier  */  }

              <path fill="none" stroke="#FFFFFF" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" d="M308.888,2066.039
                c18.849,2.454,38.474,4.256,58.742,5.236"/>
               { /*  <path fill="none" stroke="#FFFFFF" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" d="M475.055,2068.404
                c19.682-2.032,39.695-4.947,59.945-8.866"/>
              <path fill="none" stroke="#FFFFFF" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" d="M616.39,2050.334
                c19.261-0.617,38.13-0.099,56.497,1.477"/>  */  }

                 { /*  Reunion  */  }
                <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeMiterlimit="10" d="M378.718,1082.799
                  c1.483,1.579,2.34,3.431,2.34,5.414c0,5.76-7.214,10.429-16.111,10.429c-8.896,0-16.109-4.668-16.109-10.429
                  c0-1.889,0.775-3.662,2.137-5.189"/>
                <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeMiterlimit="10" d="M381.421,1078.316
                  c5.164,2.953,8.421,7.271,8.421,12.083c0,8.9-11.146,16.115-24.896,16.115s-24.896-7.215-24.896-16.115
                  c0-4.646,3.039-8.833,7.898-11.775"/>
                <path fill="none" stroke="#FFFFFF" strokeWidth="4" strokeMiterlimit="10" d="M365.616,1086.867
                  c-0.018,0.035-0.294,0.117-0.294,0.117s-17.444-13.011-17.444-29.946s14.851-17.25,17.25-17.25l0.393,0.091
                  c2.4,0,17.25,0.315,17.25,17.25c0,15.581-14.938,28.006-17.299,29.855"/>
                <path fill="none" stroke="#FFFFFF" strokeWidth="4" strokeMiterlimit="10" d="M365.393,1044.421
                  c4.656,0.078,8.406,3.877,8.406,8.552c0,4.724-3.83,8.554-8.555,8.554s-8.555-3.83-8.555-8.554c0-4.725,3.83-8.554,8.555-8.554
                  L365.393,1044.421"/>
                <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeMiterlimit="10" d="M386.489,1075.699
                  c7.192,3.957,11.756,9.854,11.756,16.444c0,11.915-14.924,21.574-33.331,21.574c-18.406,0-33.33-9.659-33.33-21.574
                  c0-6.59,4.563-12.49,11.76-16.446"/>
                <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeMiterlimit="10" d="M381.747,1078.51"/>
                <path fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                  M714.058,2124.895c7.681-7.814,6.766-14.652-4.279-14.693c-8.659-0.033-95.75,11.838-187,7.838
                  c-21.846-0.959-49.461-9.557-53.065-10.939c-4.874-1.867-14.502-6.107-18.677-6.045c-0.778,0.012-7.783,1.902-2.142,5.205
                  c5.641,3.301,7.324-0.418,10.082-3.334c2.756-2.918,10.348-35.7,10.348-35.7c2.006-8.54,14.944-7.614,12.784,1.737
                  c-1.519,6.574-8.059,12.849-16.106,10.916c-28.474-6.84-15.486-18.875-15.486-18.875"/>
                <path fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                  M503.029,2090.039c0,0-0.25-8-10.75-1c-12.189,8.126-8,17.25-6,17.75s7.25,0.75,15-12.25c0,0-7.5,11.5,0.5,11s10-9.256,10-9.256"/>
                <path fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                  M544.279,2064.039l-15.5,42.92l7-19.92c0,0,7.574,13.512,10.487,16.578s7.763,8.172,7.763,8.172s-6.55-5.748-10.078-11.617
                  c-3.528-5.87-7.172-14.258-7.172-14.258s21.5,1.375,22.25-13.125s-17.75-11.75-26-6.75"/>
                <path fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                  M561.529,2098.539c0,0,10.75-1.75,13-8.5s-5.75-7.68-13,2.41s0.238,14.496,5,14.51c6,0.016,11.201-4.621,13.851-11.021l3.149-6.898
                  c0,0-8,16.75,0,16.5s12.25-15.25,12.25-15.25s-6.498,15.092-1,15.25c8.75,0.25,11.5-4.5,13.5-14.75l-3,14.75
                  c0,0,6.75-18.634,16.5-15.75c6.596,1.95-4,14.75,3.25,15.75s14-15.361,16-18.932c0,0-11.75,19.182-3,18.932s11.5-13.43,14.5-13.09
                  c0,0-6.75,13.84,0,13.09s12-10.75,12-16.5s-7.75-4-7.75,1.75s4.5,15.488,15,5.494l1.5-6.494l-4.25,15.75c0,0,11.974-17.672,17-16
                  c8.75,2.91-4.75,15.5,3,16s10-9.256,10-9.256"/>
                <line fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="582.529" y1="2071.539" x2="576.779" y2="2077.789"/>
                <line fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="646.529" y1="2075.789" x2="644.529" y2="2076.789"/>
                <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeMiterlimit="10" d="M596.333,2013.248
                  c1.483,1.58,2.34,3.432,2.34,5.414c0,5.761-7.214,10.43-16.111,10.43c-8.896,0-16.109-4.668-16.109-10.43
                  c0-1.889,0.775-3.662,2.137-5.188"/>
                <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeMiterlimit="10" d="M599.036,2008.766
                  c5.164,2.953,8.421,7.271,8.421,12.083c0,8.899-11.146,16.115-24.896,16.115s-24.896-7.216-24.896-16.115
                  c0-4.646,3.039-8.833,7.898-11.774"/>
                <path fill="none" stroke="#FFFFFF" strokeWidth="4" strokeMiterlimit="10" d="M583.232,2017.316
                  c-0.018,0.034-0.294,0.116-0.294,0.116s-17.444-13.011-17.444-29.946c0-16.935,14.851-17.25,17.25-17.25l0.393,0.092
                  c2.4,0,17.25,0.314,17.25,17.25c0,15.58-14.938,28.006-17.299,29.854"/>
                <path fill="none" stroke="#FFFFFF" strokeWidth="4" strokeMiterlimit="10" d="M583.008,1974.871
                  c4.656,0.078,8.406,3.877,8.406,8.552c0,4.724-3.83,8.554-8.555,8.554s-8.555-3.83-8.555-8.554c0-4.726,3.83-8.554,8.555-8.554
                  L583.008,1974.871"/>
                <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeMiterlimit="10" d="M604.104,2006.148
                  c7.192,3.956,11.756,9.854,11.756,16.443c0,11.915-14.924,21.574-33.331,21.574c-18.406,0-33.33-9.659-33.33-21.574
                  c0-6.59,4.563-12.49,11.76-16.446"/>
                <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeMiterlimit="10" d="M599.363,2008.959"/>
                 { /*  Reunion  */  }

              <path fill="none" stroke="#FFFFFF" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" d="M756.46,2067.072
                c18.08,5.198,35.337,11.513,51.625,18.837"/>
              <path fill="none" stroke="#FFFFFF" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" d="M877.379,2127.724
                c14.119,11.254,26.787,23.523,37.807,36.667"/>

                 { /*  mountains  */  }
                <g id="mountains" >
                  <path fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M160.944,2112.569
                    c2.441,0.456,5.103,0.335,7.483-0.354c0.928-0.269,1.829-0.638,2.665-1.123c0.072,0.021,0.208,0.251,0.258,0.306
                    c0.644,0.705,1.413,1.299,2.219,1.808c3.107,1.96,7.154,2.866,10.799,2.452c1.563-0.177,3.415-0.648,4.558-1.819
                    c1.9,2.355,5.086,3.704,7.973,4.263c3.253,0.63,7.393,0.505,10.243-1.453c0.002-0.001,1.762,1.439,1.878,1.519
                    c1.759,1.199,3.738,2.071,5.797,2.604c3.106,0.805,7.678,1.283,10.259-1.172c0.386-0.367,1.823,1.018,2.237,1.28
                    c2.211,1.402,4.677,2.637,7.288,3.039"/>
                  <path fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M189.986,2104.229
                    c1.679,0.423,3.533,0.459,5.22,0.08c0.572-0.129,1.637-0.896,2.019-0.42c0.423,0.527,0.943,0.98,1.491,1.373
                    c2.02,1.445,4.687,2.229,7.165,2.148c1.135-0.038,2.474-0.276,3.358-1.057c1.704,2.397,5.041,3.568,7.855,3.682
                    c1.488,0.06,3.076-0.159,4.419-0.838c0.372-0.188,1.143,0.844,1.461,1.089c2.65,2.042,7.268,3.759,10.456,1.991
                    c0.216-0.12,0.439-0.26,0.612-0.439c0.004-0.004,0.768,0.632,0.816,0.67c1.601,1.251,3.444,2.398,5.449,2.842"/>
                  <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M209.458,2105.363c0.688-4.755-0.277-10.397-3.675-13.96c5.502-1.1,11.714,0.479,16.135,3.849c3.34,2.545,5.184,5.961,5.914,10.062
                    c0.366,2.056,0.517,4.203,0.341,6.289"/>
                  <path fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M45.752,2081.478
                    c2.589,1.121,5.614,1.624,8.417,1.334c0.226-0.023,2.414-0.457,2.407-0.473c0.073,0.16,0.16,0.313,0.249,0.465
                    c0.505,0.858,1.153,1.633,1.859,2.332c2.703,2.676,6.551,4.505,10.316,4.979c1.711,0.215,3.819,0.188,5.329-0.788
                    c1.362,2.786,4.219,4.884,6.96,6.139c3.315,1.518,7.65,2.362,11.185,1.014"/>
                  <path fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M293.046,2101.423
                    c1.351,0.125,2.779-0.052,4.054-0.511c0.383-0.138,1.5-0.959,1.754-0.717c0.387,0.367,0.843,0.664,1.313,0.913
                    c2.312,1.224,6.875,1.94,8.833-0.365c1.299,1.332,3.329,1.937,5.132,2.054"/>
                  <path fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M316.898,2135.313
                    c1.928,0.185,3.979-0.081,5.792-0.753c0.775-0.287,1.519-0.663,2.195-1.14c0.056,0.01,0.186,0.188,0.227,0.226
                    c0.562,0.517,1.218,0.936,1.896,1.284c2.592,1.333,5.837,1.771,8.694,1.216c1.268-0.246,2.738-0.753,3.594-1.787
                    c1.815,1.874,4.68,2.72,7.205,2.884"/>
                  <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M999.068,2109.017c-0.642-0.397-1.284-0.796-1.927-1.194c-5.051-3.13-10.102-6.261-15.152-9.391
                    c-0.816-0.506-1.632-1.012-2.447-1.518c-0.152-0.094-0.536-0.458-0.714-0.354c-1.663,0.978-3.325,1.954-4.988,2.931
                    c-4.568,2.685-9.137,5.369-13.705,8.054c-0.334,0.196-0.592,0.505-0.869,0.771c-0.994,0.948-1.988,1.897-2.982,2.846
                    c-1.705,1.628-3.41,3.256-5.115,4.884c-0.004,0.004-0.004,0.004,0,0c1.983-1.894,3.967-3.786,5.95-5.68
                    c1.042-0.995,2.041-2.247,3.294-2.983c2.02-1.187,4.039-2.373,6.058-3.56c4.145-2.435,8.288-4.87,12.432-7.305
                    c-3.844-2.285-7.688-4.57-11.53-6.856c-0.215-0.127-0.661-0.544-0.922-0.453c-0.609,0.212-1.218,0.425-1.826,0.637
                    c-2.556,0.892-5.111,1.783-7.668,2.675c-2.205,0.77-4.41,1.538-6.615,2.308c-0.798,0.278-1.578,1.429-2.164,2
                    c-4.296,4.188-8.592,8.377-12.889,12.565c-1.715,1.672-3.43,3.344-5.145,5.016c-0.323,0.315-0.646,0.631-0.97,0.946
                    c-0.284,0.276-1.415,0.277-1.788,0.347c-2.795,0.518-5.59,1.035-8.385,1.553c-0.756,0.14-1.115-0.526-1.672-1.031
                    c-0.885-0.803-1.769-1.604-2.653-2.406c4.744-5.23,9.487-10.461,14.231-15.69c-3.601,3.969-7.27,8.015-10.893,12.01
                    c-0.798,0.88-1.596,1.759-2.394,2.639c-0.126,0.139-0.746,1.038-0.945,1.042c-0.231,0.005-0.932-0.845-1.093-0.991
                    c-0.911-0.825-1.821-1.651-2.732-2.478c-3.643-3.303-7.286-6.606-10.929-9.909c0.957-1.056,1.914-2.11,2.871-3.166
                    c-0.9,0.992-1.877,2.069-2.804,3.092c-0.142,0.156-2.789-2.393-3.105-2.68c-0.684-0.619-0.74-0.571-0.65-1.443
                    c0.138-1.339,0.275-2.677,0.412-4.016c0.161-1.567,0.322-3.135,0.483-4.703c0.069-0.677-0.004-1.618,0.232-2.263
                    c0.104-0.283,0.41-0.582,0.582-0.825c0.521-0.739,1.043-1.478,1.564-2.217c1.659-2.352,3.318-4.702,4.978-7.054
                    c3.224-4.567,6.447-9.135,9.671-13.702c0.617-0.875,0.825-1.309,1.081-2.356c0.701-2.872,1.402-5.743,2.104-8.614
                    c0.329-1.346,0.658-2.691,0.986-4.038c0.038-0.155,0.507-1.499,0.393-1.616c-1.238-1.265-2.477-2.53-3.715-3.795
                    c-3.435-3.507-6.868-7.015-10.302-10.521c-0.528,1.125-1.057,2.25-1.585,3.374c-2.416,5.142-4.831,10.283-7.247,15.424
                    c-0.604,1.287-1.525,2.348-2.375,3.488c-1.479,1.984-2.957,3.969-4.436,5.953c-0.004,0.005-0.004,0.005,0,0
                    c1.327-1.78,2.653-3.562,3.98-5.342c0.837-1.123,1.969-2.267,2.568-3.542c2.062-4.391,4.126-8.781,6.188-13.172
                    c0.929-1.976,1.856-3.952,2.785-5.928c0.155-0.331,0.059-0.38-0.111-0.725c-0.445-0.899-0.89-1.8-1.335-2.7
                    c-2.196-4.444-4.393-8.89-6.59-13.335l-22.402-19.345l-8.536-2.484l-3.242,4.578l3.242-4.578l-24.969-9.854"/>
                  <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M958.118,2154.176c-5.272-4.78-10.544-9.562-15.816-14.342c4.728-1.447,9.456-2.896,14.184-4.343
                    c0.411-0.126,1.197-0.204,1.542-0.474c0.577-0.452,1.153-0.903,1.73-1.355c2.393-1.873,4.785-3.746,7.178-5.619
                    c0.537-0.421,1.074-0.842,1.612-1.263c0.012-0.009-0.017,0.013-0.009,0.008c-1.829,1.432-3.657,2.863-5.486,4.294
                    c-1.176,0.921-2.352,1.842-3.527,2.763c-0.4,0.313-1.009,1.024-1.504,1.176c-4.766,1.459-9.532,2.919-14.298,4.378
                    c-0.756,0.231-1.196,0.641-1.771,0.118c-0.777-0.704-1.555-1.409-2.331-2.114c-3.254-2.95-6.508-5.9-9.762-8.852
                    c-9.453-8.571-18.905-17.143-28.358-25.714c-6.039-5.478-12.079-10.954-18.119-16.431c-0.034-0.031-0.248-0.183-0.225-0.23
                    c0.432-0.859,0.862-1.719,1.293-2.578c1.067-2.13,2.136-4.26,3.203-6.39c-1.502,2.995-3.003,5.991-4.505,8.986
                    c-7.325-6.642-14.65-13.284-21.975-19.926c-0.688-0.624-1.376-1.247-2.063-1.871"/>
                  <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M908.612,2090.854c3.057-3.371,6.113-6.742,9.17-10.113"/>
                  <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M923.859,2074.038c2.275-2.51,4.551-5.019,6.826-7.528c-4.271-3.873-8.542-7.745-12.813-11.618c3.287,2.981,6.575,5.962,9.862,8.942
                    c2.26,2.05,4.521,4.1,6.78,6.148c0.745,0.676,1.489,1.351,2.234,2.026c0.123,0.111,0.835,0.592,0.848,0.769
                    c0.009,0.128-0.519,0.571-0.599,0.66c-0.528,0.583-1.057,1.165-1.585,1.748c-1.662,1.833-3.325,3.667-4.987,5.501
                    c-2.478,2.731-4.955,5.464-7.432,8.196c0.003-0.005-0.012,0.012-0.005,0.005c4.869-5.37,9.738-10.74,14.607-16.11
                    c2.575,2.336,5.151,4.671,7.727,7.007c-3.338,3.681-6.677,7.362-10.015,11.043c0.005-0.006-0.017,0.019-0.008,0.008
                    c3.251-3.584,6.501-7.168,9.751-10.752c0.35-0.385,0.306-0.268,0.692,0.083c0.795,0.721,1.589,1.44,2.384,2.161
                    c2.326,2.11,4.652,4.22,6.979,6.329c0.894,0.811,1.786,1.621,2.68,2.431"/>
                  <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M912.67,2100.963c1.315-1.45,2.63-2.9,3.944-4.351"/>
                  <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M939.885,2125.642c1.433-1.361,2.8-2.723,4.127-4.187"/>
                  <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M880.006,2071.344c1.206-1.552,2.404-3.107,3.593-4.672"/>
                  <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M863.365,2081.779c5.096,4.621,10.192,9.242,15.289,13.863"/>
                  <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M885.004,2101.4c7.369,6.682,14.737,13.363,22.106,20.045c3.686,3.342,7.371,6.685,11.057,10.026"/>
                  <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M924.988,2137.657c1.42,1.288,2.84,2.576,4.26,3.864"/>
                  <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M878.422,2108.658c1.568,1.422,3.137,2.844,4.704,4.266"/>
                  <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M889.241,2118.47c6.312,5.723,12.622,11.445,18.934,17.168"/>
                  <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M968.551,2329c6.141-5.443,12.28-10.887,18.421-16.33c8.457-7.497,16.914-14.993,25.371-22.49c0.725-0.643,1.449-1.285,2.174-1.928
                    c0.343-0.304,0.938-0.422,1.354-0.589c4.135-1.661,8.27-3.322,12.404-4.983c10.826-4.351,21.652-8.701,32.479-13.052
                    c0.952-0.382,1.903-0.765,2.855-1.146c0.234-0.095,0.307-0.753,0.376-0.967c0.424-1.317,0.849-2.635,1.272-3.952
                    c0.729-2.26,1.456-4.52,2.185-6.779c0.06-0.188-1.269-1.424-1.401-1.572c-1.036-1.159-2.072-2.318-3.108-3.477
                    c-0.324-0.362-3.059-3.859-3.423-3.67c-1.022,0.529-2.045,1.059-3.067,1.588c-4.872,2.523-9.745,5.047-14.617,7.571
                    c-5.573,2.886-11.146,5.772-16.719,8.658c-1.897,0.983-3.794,1.966-5.691,2.948c-0.521,0.27-1.482,1.04-2.084,1.075
                    c-9.074,0.531-18.149,1.062-27.224,1.594c-0.116,0.007-0.379,0.497-0.441,0.583c-0.489,0.674-0.979,1.348-1.47,2.021
                    c-1.53,2.105-3.062,4.21-4.593,6.315c-2.192,3.015-4.425,6.085-6.598,9.073c2.893-3.979,5.785-7.956,8.679-11.935
                    c1.369-1.883,2.738-3.766,4.107-5.648c0.295-0.405,0.518-0.549,0.944-0.82c7.312-4.653,14.624-9.307,21.937-13.96
                    c10.762-6.849,21.523-13.697,32.285-20.546c3.7-2.354,7.401-4.71,11.102-7.064c0.909-0.579,1.819-1.158,2.729-1.737
                    c0.526-0.335,0.478-0.296,0.443-0.953c-0.524-9.998-1.049-19.996-1.573-29.994c-0.018-0.33-0.177-0.4-0.425-0.645
                    c-0.836-0.823-1.673-1.646-2.509-2.469c-2.117-2.083-4.234-4.166-6.351-6.249c-0.213-0.21-0.686-0.937-0.982-0.737
                    c-0.922,0.621-1.844,1.241-2.767,1.861c-2.05,1.379-4.1,2.758-6.149,4.137c-0.001,0.001-0.003,0.002-0.004,0.003
                    c1.999-1.345,3.998-2.689,5.997-4.034c0.958-0.645,1.916-1.289,2.875-1.934c0.349-0.235,0.194-0.771,0.198-1.166
                    c0.035-2.937,0.069-5.872,0.104-8.808c0.015-1.299,0.03-2.599,0.046-3.897c0.003-0.276,0.15-0.909-0.071-1.118
                    c-3.778-3.554-7.558-7.106-11.337-10.66c-1.533-1.441-3.066-2.884-4.6-4.325c-0.214-0.201-0.287-0.42-0.412-0.692
                    c-0.425-0.93-0.85-1.859-1.274-2.789c-2.08-4.552-4.16-9.103-6.24-13.654c-4.083,3.611-8.166,7.223-12.249,10.834
                    c0.006-0.005-0.017,0.016-0.008,0.007c3.74-3.309,7.48-6.616,11.221-9.924c0.154-0.137,1.036-0.721,1.036-0.942
                    c0-0.648,0-1.297,0-1.944c0-3.005,0-6.009,0-9.013c0-2.331,0-4.663,0-6.994c0-0.432-0.509-0.658-0.82-0.923
                    c-1.104-0.938-2.206-1.875-3.31-2.812c-1.103-0.938-2.205-1.875-3.309-2.812c-0.39-0.332-0.652-0.876-1.041-0.463
                    c-1.889,2.01-3.777,4.02-5.667,6.029c-0.892,0.949-1.783,1.897-2.675,2.847c-0.012,0.012,0.016-0.017,0.009-0.009
                    c2.851-3.034,5.702-6.068,8.554-9.102c-4.667-1.716-9.333-3.433-14-5.148"/>
                  <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M981.857,2234.407c4.019-2.498,8.036-4.997,12.055-7.495c2.788-1.733,5.522-3.627,8.432-5.153
                    c9.439-4.952,18.878-9.903,28.316-14.855c0.451-0.236,0.902-0.474,1.354-0.71"/>
                  <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M1023.759,2195.495c-8.646,2.9-17.293,5.802-25.939,8.702c-5.485,1.841-10.971,3.681-16.456,5.521
                    c-1.48,0.496-2.961,0.993-4.442,1.49c-0.737,0.247-1.14,0.191-1.95,0.116c-6.184-0.578-12.366-1.157-18.55-1.735"/>
                  <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M948.905,2190.885c7.812-1.738,15.625-3.477,23.438-5.213"/>
                  <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M986.814,2182.309c1.239-0.044,2.479-0.089,3.719-0.133c1.07-0.038,1.758-0.314,2.771-0.721"/>
                  <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M916.615,2029.092c9.093,1.297,18.186,2.594,27.279,3.892c0.684,0.098,1.425,0.017,2.112,0.017c2.164,0,4.328,0,6.491,0
                    c6.34,0,12.68,0,19.021,0c4.75,0,9.5,0,14.251,0c0.744,0,0.96-0.065,1.528,0.372c1.532,1.179,3.065,2.357,4.598,3.536
                    c4.988,3.836,9.976,7.672,14.964,11.508c1.738,1.337,3.477,2.675,5.216,4.012c0.305,0.234,0.609,0.469,0.914,0.703
                    c0.145,0.111-0.115,1.449-0.131,1.624c-0.351,3.968-0.7,7.935-1.051,11.902c-6.83-5.825-13.66-11.65-20.49-17.476
                    c-6.325-5.394-12.649-10.788-18.974-16.182c6.83,5.825,13.66,11.65,20.49,17.476c4.547,3.877,9.093,7.755,13.639,11.632
                    c1.392,1.187,2.783,2.373,4.175,3.561c0.604,0.516,1.185,0.712,1.114,1.509c-0.241,2.729-0.482,5.458-0.724,8.187
                    c-0.128,1.455,0.267,3.028,0.435,4.47c0.365,3.137,0.73,6.273,1.097,9.41c0.153,1.317,0.117,2.826,0.566,4.086
                    c1.621,4.543,3.242,9.087,4.863,13.63"/>
                </g>
                 { /*  mountains  */  }

              <path fill="none" stroke="#FFFFFF" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" d="M951.68,2225.473
                c5.226,13.53,8.953,27.585,11.049,42.065c0.791,5.461,1.407,10.865,1.851,16.209"/>
              <path fill="none" stroke="#FFFFFF" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" d="M951.674,2380.399
                c-5.795,14.83-13.373,28.707-22.644,41.494"/>

                 { /*  palmier 2  */  }
                <g id="palmier_2" >
                  <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M962.476,2387.273c12.108-8.511,17.789-24.848,13.667-39.039c-0.548-1.883-1.263-3.853-2.396-5.476"/>
                  <line fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="970.254" y1="2336.77" x2="972.266" y2="2339.96"/>
                  <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M994.09,2366.701c-1.104-0.229-2.204-0.472-3.299-0.735c-0.665-0.159-0.604-0.228-0.847,0.337c-0.193,0.449-0.387,0.898-0.579,1.348
                    c-0.498,1.156-0.75,2.487-1.048,3.648c0.498-1.94,1.048-3.649,1.839-5.488c-4.232-1.179-8.322-2.901-12.143-5.069"/>
                  <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M975.717,2371.759c1.184,0.584,2.366,1.169,3.55,1.753c0.386-1.595,0.771-3.189,1.156-4.783c0.361-1.491,1.179-2.98,1.744-4.408
                    c-0.578,1.477-1.156,2.952-1.734,4.428c-0.529,1.353-0.724,2.903-1.06,4.315c-0.066,0.28-0.206,0.411,0.082,0.521
                    c0.645,0.243,1.294,0.475,1.945,0.696c1.554,0.529,3.125,1.005,4.709,1.431c3.925,1.057,7.942,1.826,11.998,2.142
                    c0.056-1.195-0.021-2.475,0.175-3.653c0.224-1.35,0.448-2.698,0.672-4.048c-0.22,1.308-0.438,2.614-0.658,3.922
                    c-0.204,1.219-0.127,2.544-0.188,3.779c2.99,0.118,5.997,0.248,8.99,0.161c0.526-0.016,0.381-0.274,0.374-0.757
                    c-0.011-0.706-0.038-1.412-0.063-2.118c-0.008-0.217-0.156-3.257-0.047-3.261c0.159-0.008,0.037,3.126,0.03,3.355
                    c-0.02,0.728-0.048,1.457-0.03,2.185c0.019,0.79,0.276,0.573,1.053,0.524c1.271-0.082,2.541-0.172,3.81-0.288
                    c0.766-0.07,0.909-0.202,1.474-0.702c1.794-1.585,3.553-3.348,4.805-5.401c-4.017,6.186-11.344,10.787-18.846,10.271
                    c3.006,3.969,8.625,4.443,13.016,2.905c1.736-0.608,3.531-1.335,4.622-2.885c0.872-1.239,1.356-2.727,1.678-4.193
                    c0.423-1.935,0.604-3.959,0.541-5.94c3.029,5.665,1.837,12.773-2.313,17.52c4.291-4.668,4.74-11.906,2.556-17.598
                    c4.841,1.293,8.373,5.684,9.038,10.567c0.532,3.912-0.451,7.747-3.215,10.629c-2.466,2.571-5.969,4.128-9.527,4.259
                    c-3.956,0.146-7.693-1.6-10.107-4.742c2.775,0.245,6.006-0.051,8.404-1.561"/>
                  <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M1017.796,2370.922c-2.341-2.173-5.817-3.172-8.938-3.233c-3.985-0.078-8.304,0.694-12.036,2.131c1.279-5.435,6.57-8.79,11.96-8.793
                    c5.09-0.003,8.77,3.872,9.181,8.83c0.832-3.559-0.06-7.662-2.558-10.359c-4.65-5.021-12.195-4.55-17.778-1.438
                    c0.528-6.431,6.937-9.905,12.729-10.423"/>
                  <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M1013.319,2348.1c4.065,1.301,8.257,4.247,9.719,8.392c0.649,1.841,0.609,3.727,0.402,5.644c-0.204,1.899-0.574,3.825-1.357,5.579
                    c-0.492,1.101-1.252,2.456-2.412,2.984c1.705-1.972,3.664-4.646,3.79-7.337c1.833,1.141,3.48,2.966,4.084,5.062
                    c0.475,1.646,0.27,3.611-0.05,5.277c-0.079,0.414-0.162,0.92-0.444,1.253"/>
                  <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M969.97,2343.369c-0.074,0.506,0.744,0.714,0.83,0.155c0.079-0.527-0.729-0.736-0.806-0.208"/>
                  <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M971.93,2346.728c-0.079,0.504,0.743,0.713,0.829,0.155c0.086-0.527-0.728-0.734-0.806-0.209"/>
                  <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M969.044,2349.82c-0.083,0.502,0.744,0.713,0.828,0.156c0.089-0.529-0.723-0.736-0.806-0.209"/>
                  <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M967.218,2374.145c-0.079,0.504,0.746,0.715,0.829,0.155c0.082-0.527-0.728-0.735-0.805-0.208"/>
                  <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M964.211,2379.747c-0.083,0.504,0.74,0.712,0.83,0.156c0.079-0.527-0.729-0.737-0.806-0.209"/>
                </g>
                 { /*  palmier 2  */  }

              <path fill="none" stroke="#FFFFFF" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" d="M874.143,2472.915
                c-15.822,10.048-33.549,18.498-53.06,25.165"/>


                { /* New Zealand */ }
                <g id="point_map">
                  <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeMiterlimit="10" d="M629.661,2460.942
                    c1.483,1.58,2.343,3.432,2.343,5.414c0,5.761-7.214,10.43-16.111,10.43c-8.896,0-16.109-4.668-16.109-10.43
                    c0-1.889,0.775-3.662,2.14-5.188"/>
                  <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeMiterlimit="10" d="M632.364,2456.46
                    c5.164,2.953,8.422,7.271,8.422,12.083c0,8.899-11.146,16.115-24.896,16.115s-24.896-7.216-24.896-16.115
                    c0-4.646,3.039-8.833,7.898-11.771"/>
                  <path fill="none" stroke="#FFFFFF" strokeWidth="4" strokeMiterlimit="10" d="M616.562,2465.011
                    c-0.018,0.031-0.294,0.113-0.294,0.113s-17.444-13.011-17.444-29.943c0-16.938,14.854-17.25,17.25-17.25l0.396,0.092
                    c2.397,0,17.25,0.312,17.25,17.25c0,15.58-14.938,28.006-17.299,29.854"/>
                  <path fill="none" stroke="#FFFFFF" strokeWidth="4" strokeMiterlimit="10" d="M616.339,2422.565
                    c4.653,0.078,8.403,3.877,8.403,8.552c0,4.724-3.827,8.554-8.555,8.554c-4.725,0-8.555-3.83-8.555-8.554
                    c0-4.726,3.83-8.554,8.555-8.554L616.339,2422.565"/>
                  <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeMiterlimit="10" d="M637.434,2453.843
                    c7.192,3.953,11.756,9.854,11.756,16.44c0,11.915-14.924,21.574-33.331,21.574c-18.406,0-33.33-9.659-33.33-21.574
                    c0-6.59,4.563-12.49,11.761-16.446"/>
                </g>
              

                
                  <path fill="none" stroke="#FFFFFF" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M633.25,2560.86c-3.336,0.074-7.971,2.199-7.652-7.1c0.549-16.083,20.066-20.5,16.316-11.75s-13.75,10-13.75,10
                    c8-0.5,18.75-12,10.75-12.858c-8-0.859-19.75,20.858-9,21.782s15-9.174,15-9.174c2.75-4.5,7.25-13,14.75-12.64
                    c7.5,0.361,3.25,7.14,3.25,5.39c0-6.649-7-5.25-11.5-0.25s-9,13.5-5.25,16.15c3.75,2.651,10-5.65,10-5.65c3-3.75,3.699-5.813,6-7
                    c4.639-2.395-5.5,12.5-1,12.648c4.5,0.149,11-9.648,11-9.648c5.375-9.125,8.125-6.5,13.75-16.5c9.528-16.942,6.5-23.25-3-8.5
                    s-9.75,31.75-8.25,33.5s7,1.148,9.25-3.25c8.857-17.321,15.75-15.75,15.75-15.75c3.875-0.375,1.75,2.375,4,3.75
                    c2.176,1.328,1.5-3.5-4-3.833c-5.5-0.332-18.5,16.833-12,19.825s15-11.492,15-11.492s-9,12-1,11.475c8-0.522,11-13.975,13-14.975
                    s-4.5,15-3.5,14.975c1-0.022,3-10.475,13-15.975c8.23-3.17,4,9.889,4,10.5c0,11,10.5,5.25,15.18-7
                    c4.945-13.25,11.731-6.545,11.731-6.545c0.964-2.206-5.911-5.956-11.411,5.545c-6.5,14.5,2.253,13.713,9,5.5
                    c5.75-7,18.104-31.737,16-32c-1-0.125-18,35.5-13,39.25s13-8.25,13-8.25c6-10.757,16.5,15.364-8.5,24.746
                    c-38,15-82.344-1.387-153.973,1c-75.027,2.5-124.702,16.5-124.702,38.5"/>
                  <path fill="none" stroke="#FFFFFF" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M595.914,2526.51c0,0-1.083-10.764,5.5-10.201c6.479,0.555,16.5,5.951,22.75,4.201s-4.159,0.359-5.5,3.75
                    c-1.921,4.854-9.75,23.75-21.25,35s-16.86-8.5-2.556-2.5c14.306,6,20.056,6.25,24.556,2.5s0,4.066-8,3.533
                    s-12.25-4.783-12.25-4.783s15.333-17.757,25-37.5c0,0-4.898,0.469-6.75-0.5c-1.853-0.969-5.585-3.486-11.212-4.154
                    c-8.538-1.013-16.788,5.154-10.038,10.654"/>
                  <path fill="none" stroke="#FFFFFF" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M573.164,2550.511c0,0-2.5,6.25-6.75,4.25s-3.75-7.75-3.5-11.5s6-3.75,2.25,1.5s-8.75,17.082-14.25,16.416s0-15.916,0-17.666
                    s4.455-2.205,1,2c0,0-8,15.082-13,15.666s-3.75-14.416,0-18.666s3.13,1.398,0.5,3.75c-3.23,2.887-6.25,14.137-18,14.943
                    c-11.75,0.806-6.75-11.693-2.75-16.943s9-6.786,11.5-5.019c2.5,1.769,1.333,9.435-10.5,12.769c0,0,8.25-2.5,10.5-7.75
                    s-3-8.75-10.5,3.25c-5.076,10.801-4.75,14.082,3.5,13.666"/>
                  <path fill="none" stroke="#FFFFFF" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M516.914,2517.309c0,0-14.75,36.413-13.5,43.933c1.25,7.519-2.667-40.647-6.75-43.933c-5.112-4.111-18.167,8.451-11,13.201
                    c0,0-0.25-3.5,0.917-6.25s6.083-9.75,8.583-3c0.128,0.348-14.065,43.038-13.667,42.75c3.833-2.77,14.078-43.255,14.179-42.838
                    c2.383,9.885,5.417,39.133,7.238,40.897c2.75,1.939,9.379-31.811,13.188-42.811"/>
                

                { /* End New Zealand */ }


                { /* Flag */ }
                  <path fill="none" stroke="#FFFFFF" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10"  d="
                    M363.999,2573.105l6.143-15.983L363.999,2573.105l-5.475-7.84l13.919-9.757l-13.919,9.757l-3.292-4.713l13.885-9.696l-13.885,9.696
                    l-5.368-7.688l17.525-0.8l-17.525,0.8c0,0-1.262-1.492-0.947-2.654c0.316-1.16,0.882-1.529,0.882-1.529l17.454-0.505l-17.454,0.505
                    l11.983-8.368l7.335,10.544l-7.335-10.544l4.902-3.424l7.339,10.484l-7.339-10.484l12.119-8.461l-6.662,16.258l6.662-16.258
                    c0,0,0.654-0.383,1.376-0.257c0.928,0.161,2.071,2.08,2.071,2.08l-6.617,15.991l6.617-15.991l5.314,7.61l-13.541,9.512
                    l13.541-9.512l3.317,4.751l-13.635,9.541l13.635-9.541l5.27,7.549l-16.983,0.65l16.983-0.65c0,0,1.199,1.571,1.039,2.493
                    c-0.148,0.855-0.78,1.452-0.78,1.452l-17.413,0.56l17.413-0.558l-11.953,8.397l-7.21-10.353l7.21,10.353l-4.781,3.361
                    l-7.233-10.359l7.233,10.359l-7.782,5.469l1.415,6.338l-5.562-3.425l6.467-16.204l-6.467,16.205c0,0-0.674,0.184-1.738-0.002
                    C364.947,2574.061,363.999,2573.105,363.999,2573.105"/>
                { /* End */ }

                { /* Kiwi */ }
                  <g>
                    <path fill="none" stroke="#FFFFFF" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10"  d="M842.366,2484.832l2.989-2.698l-3.68,0.013l3.68-0.013l0.223-0.202
                      l-2.287-5.908c0,0,4.391-4.988,2.595-9.956c-1.794-4.966-6.036-8.814-11.71-9.043c-5.677-0.229-5.918-2.062-6.892-3.577
                      c-1.971-3.071-6.287-3.051-7.988-2.409c-1.702,0.644-6.194,3.548-3.475,9.41c0,0,1.701-1.772,3.041-0.47
                      c1.338,1.304,0.378,3.021,0.378,3.021s-1.893,4.187-1.435,15.274c0,0-3.385-8.057-1.992-17.622c0,0,0.983-1.864,2.426-1.066
                      c1.71,0.945,1.542,2.21,1.069,3.31c0,0,3.397,0.17,5.437,3.701c2.038,3.529,1.567,7.438,4.599,9.949
                      c2.645,2.189,5.778,2.547,8.248,2.129l1.902,5.612l-0.191,0.158l-3.736,0.082l3.736-0.082l-2.941,2.438
                      c0,0,3.121-2.596,3.134-2.598c0.071-0.014-1.975-5.597-1.902-5.612c2.069-0.446,3.995-1.473,5.652-2.549"/>
                    <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M821.982,2458.153c0.786-0.298,0.763-1.401,0.592-1.854
                      s-0.729-1.343-1.728-0.965c-0.676,0.254-0.772,1.146-0.545,1.749S821.306,2458.408,821.982,2458.153z"/>
                  </g>
                { /* End */ }
                

              
              <path fill="none" stroke="#FFFFFF" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" d="M419.261,2574.424
                c-29.647,14.08-46.261,25.114-46.261,25.114"/>

              <path fill="none" stroke="#FFFFFF" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" d="M326.085,2636.335
          			c-25.057,21.199-38.292,36.118-38.292,36.118"/>


          </g>

          </svg>
      
    )
  }
}