import React from 'react';

export class HideGallery extends React.Component{  
  constructor({props}){
    super(props)
    this.state = {props}
  }

  componentWillReceiveProps({someProp}) {
    this.setState({...this.state,someProp})
  }

  componentDidMount(){

    /* Open/close gallery 
     * ------------------- */
    var galleryContainer = document.querySelector('.gallery');
    var svgIntro = document.querySelector('.svg__intro') ? document.querySelector('.svg__intro') : false;
    var buttonShowHide = document.querySelector('.gallery__button--circle');
    // console.log({galleryContainer})

    if (svgIntro) svgIntro.classList.add('state--open');
    galleryContainer.classList.add('no--height');

    // Add open by default
    galleryContainer.classList.add('state--close')
    //buttonShowHide.classList.add('gallery__button--state-open')

    // Container animation
    function openContainer(e){
      e.classList.toggle('state--close')
      e.classList.toggle('state--open')     
      e.classList.toggle('no--height')
    }

    // Button animation
    function moveTo(e){
      e.parentElement.classList.toggle('gallery--go-to-right')
      e.classList.toggle('gallery__button--state-open')
      e.classList.toggle('state-open')
    }

    buttonShowHide.onclick = () => { 
                openContainer(galleryContainer);  
                moveTo(buttonShowHide);
                if (svgIntro){
                  setTimeout(() => {
                    openContainer(svgIntro)
                  }, 300); 
                }
              };

  }

  render(){
    return(
      <div className="gallery__controller" >
        <button className="gallery__button--circle" aria-label="Show projects" >
          <div className="gallery__button--lines" ></div>
        </button>
      </div>
    )
  }
}