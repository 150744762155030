
export const SortFilter = (e)=>{

  let filter = e.target.getAttribute('data-slug')

  /* ---- Add selected state on filter ---- */
  // Reset styles
  document.querySelectorAll('.gallery__filters ul li').forEach(
    (element)=>{
      element.classList.remove('gallery__filters--selected')
    }
  )

  // Apply state on selected filter
  e.target.classList.add('gallery__filters--selected')
  /* ---- */

  if (filter) {

    let galleryAllChildren = document.querySelectorAll(".gallery__children-container > li");
  
    galleryAllChildren.forEach(children => {     
      
      if ( filter === "all" ) {
        children.classList.remove('gallery__childs--hide') 
      } else {
      
        if ( children.getAttribute('data-filter') !== filter ) {
          // Hide items
          children.classList.add('gallery__childs--hide')
        } else {
          // Show items
          children.classList.remove('gallery__childs--hide')
        }
      }      
    });

  }  

}