/**
 * Set SVG to draw on scroll drawing function
*/

import React from 'react';
import {SvgHistory} from '../components/SvgHistory'; // Svg to draw
import {SvgHistoryMobile} from '../components/SvgHistoryMobile'; // Svg to draw
// import {DrawSvg} from '../components/DrawSvg';       // Scroll drawing function
import {AutoDrawSvg} from '../components/AutoDrawSvg'; // Auto drawing function

if(window.matchMedia('screen and (max-width: 400px)').matches) { console.log('kikooooooo')}

const svg = {
  id: (window.matchMedia('screen and (max-width: 420px)').matches) ? '#draw--mobile' : '#draw',
  object: (window.matchMedia('screen and (max-width: 420px)').matches) ? SvgHistoryMobile : SvgHistory
};

// Init <path/> selector & drawing options
const selectPaths = () => {

  let draws = {
    element: document.querySelectorAll(svg.id+' > g * > path'),
    speedDraw: 20, // 3000
    displaySpeed: 10000 // 200
  }
  let roadLine = {
    element: document.querySelectorAll(svg.id+' > g > path'),
    speedDraw: 20, // 3000
    displaySpeed: 10000 // 200
  }
  
  return [draws, roadLine]
  // return [draws, roadLine]
}



export class History extends React.Component{  
  constructor(props){
   super(props)
   this.state = {
    selector: {}
   }
  }


  componentDidMount(){
    this.setState({ selector: selectPaths() })
  }


  render(){
    return(
      <div>
        <AutoDrawSvg svg={svg.object} selector={this.state.selector} />
        {/*<DrawSvg svg={SvgHistory} selector={this.state.selector} />*/}
      </div>
    )
  }


}