import React from 'react';


export class SvgHistoryMobile extends React.Component{  

  render(){
    return(
      
        <svg id="draw--mobile" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100%" height="100%" viewBox="180 -20 420 2000" preserveAspectRatio="xMaxYMin meet" >
        <g>
            <path fill="none" stroke="#FFFFFF" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" d="M392.145,973.969
	            c2.002,31.831-15.292,56.834-34.396,83.467"/>
            <path fill="none" stroke="#FFFFFF" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" d="M366.42,851.824
              c-6.029,23.432-4.293,49,11.08,76.678"/>
            <path fill="none" stroke="#FFFFFF" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" d="M441.09,749.04
              c-10.752,11.76-22.294,19.49-30.156,28.383c-6.462,7.311-12.798,14.965-18.634,22.963"/>
            <path fill="none" stroke="#FFFFFF" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" d="M469.1,652.026
              c1.209,27.045-1.942,47.207-7.461,62.804"/>
            <path fill="none" stroke="#FFFFFF" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" d="M363.192,456.127
              c14.75,15.153,29.849,29.688,43.785,44.917"/>
            <path fill="none" stroke="#FFFFFF" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" d="M293.781,355.538
              c9.029,24.591,22.004,45.367,36.842,64.137"/>
            <path fill="none" stroke="#FFFFFF" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" d="M283.54,225.692
              c-1.962,15.863-3.039,32.777-3.04,50.81c-0.001,8.576,0.391,16.785,1.14,24.659"/>
            <path fill="none" stroke="#FFFFFF" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" d="M408.06,7.403
              c-11.408,10.425-34.054,27.872-57.14,56.088"/>
            <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeMiterlimit="10" d="M339.46,117.357
              c1.312,1.4,2.073,3.043,2.073,4.801c0,5.108-6.396,9.248-14.286,9.248c-7.889,0-14.281-4.139-14.281-9.248
              c0-1.675,0.685-3.248,1.895-4.602"/>
            <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeMiterlimit="10" d="M341.857,113.382
              c4.577,2.619,7.468,6.448,7.468,10.715c0,7.892-9.885,14.29-22.077,14.29s-22.075-6.397-22.075-14.29
              c0-4.12,2.693-7.833,7.001-10.441"/>
            <path fill="none" stroke="#FFFFFF" strokeWidth="4" strokeMiterlimit="10" d="M327.842,120.964
              c-0.017,0.031-0.262,0.104-0.262,0.104s-15.466-11.538-15.466-26.555c0-15.017,13.169-15.296,15.296-15.296l0.35,0.081
              c2.128,0,15.296,0.279,15.296,15.296c0,13.816-13.246,24.834-15.339,26.474"/>
            <path fill="none" stroke="#FFFFFF" strokeWidth="4" strokeMiterlimit="10" d="M327.644,83.325
              c4.129,0.069,7.454,3.438,7.454,7.584c0,4.189-3.396,7.585-7.588,7.585c-4.19,0-7.586-3.396-7.586-7.585
              c0-4.19,3.396-7.585,7.586-7.585L327.644,83.325"/>
            <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeMiterlimit="10" d="M346.351,111.06
              c6.378,3.509,10.424,8.738,10.424,14.582c0,10.566-13.232,19.131-29.556,19.131c-16.319,0-29.556-8.565-29.556-19.131
              c0-5.844,4.047-11.076,10.431-14.583"/>
            <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeMiterlimit="10" d="M342.146,113.554"/>
            <g id="Calque_2_3_">
              <path fill="none" stroke="#FFFFFF" strokeWidth="5" strokeLinecap="round" strokeMiterlimit="10" d="M222.964,84.089"/>
              <path fill="none" stroke="#FFFFFF" strokeWidth="5" strokeLinecap="round" strokeMiterlimit="10" d="M281.064,83.72"/>
            </g>
            <g id="Calque_2_4_">
              <path fill="none" stroke="#FFFFFF" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                M234.657,166.528c20.552-13.62,29.138-3.161,13.455,2.089l-1.896,1.353l-6.98,16.75c0,0-7.878,1.122-5.558,1.524
                c9.881,1.709,33.715-11.079,15.627-17.366"/>
              <path fill="none" stroke="#FFFFFF" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                M250.768,158.064"/>
              <path fill="none" stroke="#FFFFFF" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                M372.113,162.773c0,0-3.127,23.146,13.813,8.64c0,0,7.003-12.068,11.437,2.691c4.435,14.76-16.405,15.646-21.282,16.09
                c-4.877,0.444-112.618,0.483-154.295,17.312"/>
              <path fill="none" stroke="#FFFFFF" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                M381.824,161.719c0,0-14.688,16.062-18.339,15.164c-3.646-0.896,2.136-12.634,2.136-12.634l-4.58,9.984
                c-17.74,10.768-7.089-10.004-7.089-10.004l-3.89,11.493c-13.036,11.06-7.423-9.736-7.423-9.736l-1.538,1.971
                c-8.934-1.864-19.648,19.539-0.75,7.578"/>
              <path fill="none" stroke="#FFFFFF" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                M329.143,175.545c0,0-8.718,9.603-13.864,3.079c-5.146-6.522,10.741-15.874,8.359-8.978c-2.383,6.896-15.405,13.526-16.521,10.59
                c0,0-4.619,5.067,3.605-22.303l-7.095,19.633c0,0-6.279,10.835-7.147-1.979c-0.516-7.643,10.318-4.706,10.318-4.706"/>
              <path fill="none" stroke="#FFFFFF" strokeWidth="5" strokeLinecap="round" strokeMiterlimit="10" d="M313.303,155.036"/>
              <path fill="none" stroke="#FFFFFF" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                M294.584,177.726c0,0-3.177,4.778-8.101,7.276c-4.923,2.5,1.903-14.257,3.891-14.802c1.982-0.543-6.074,2.81-6.074,2.81
                s-1.646-1.976-4.69-1.381l0.692,1.943c1.592,4.994-3.423,8.855-3.423,8.855l1.296-3.568l-8.706,0.798c0,0,1.344-7.257-4.38-7.344
                c-5.723-0.086-10.021,13.556-1.352,15.215c5.131,0.979,5.732-7.87,5.732-7.87"/>
            </g>
            <path fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
              M423.911,639.664c36.539,1.955,70.707-13.188,79.367-13.153c11.045,0.042,10.56,8.864,1.279,14.691"/>
            <path fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
              M374.44,587.379c0,0-7.682,13.32,15.486,18.875c8.047,1.929,12.586-4.345,14.104-10.916c2.16-9.354-8.778-10.277-10.785-1.738
              c0,0-7.59,32.782-10.348,35.699c-2.756,2.917-4.441,6.636-10.082,3.334c-5.641-3.306,1.363-5.192,2.146-5.205
              c4.176-0.062,13.803,4.178,18.678,6.045c3.604,1.383,7.25,2.504,10.908,3.396"/>
            <path fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
              M481.413,614.653c0,0-3.202,9.33-9.225,9.322c-6.025-0.007-0.924-7.729-0.938-10.738c-0.013-3.011-4.123-10.114-14.974,6.728
              c-1.455,2.26-4.262,5.793-4.262,5.793l3.912-15.583l-3.076,7.89c0,0-3.754,5.89-9.332,2.359c-5.576-3.529-3.402-13.142,0.35-13.272
              c3.752-0.135,5.359,10.153-4.146,18.623c-4.658,4.147-12.115-0.796-3.81-12.013c0,0-7.149,12.966-14.403,16.105
              c-7.258,3.143-14.142,11.771-12.646,13.899c1.492,2.128,4.73,1.849,8.011-3.709c3.28-5.556,7.438-26.997,7.438-26.997
              s-5.285,14.245-11.803,14.319c-6.521,0.075-2.99-11.273-0.326-15.271"/>
            <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeMiterlimit="10" d="M457.809,554.484
              c1.479,1.578,2.34,3.432,2.34,5.414c0,5.76-7.213,10.429-16.111,10.429c-8.895,0-16.104-4.668-16.104-10.429
              c0-1.89,0.771-3.662,2.137-5.189"/>
            <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeMiterlimit="10" d="M460.512,550.002
              c5.164,2.953,8.422,7.271,8.422,12.083c0,8.897-11.146,16.112-24.896,16.112s-24.896-7.215-24.896-16.112
              c0-4.646,3.039-8.833,7.896-11.775"/>
            <path fill="none" stroke="#FFFFFF" strokeWidth="4" strokeMiterlimit="10" d="M444.704,558.553
              c-0.018,0.035-0.293,0.117-0.293,0.117s-17.44-13.014-17.44-29.946c0-16.938,14.85-17.25,17.25-17.25l0.393,0.092
              c2.4,0,17.25,0.312,17.25,17.25c0,15.581-14.938,28.006-17.299,29.854"/>
            <path fill="none" stroke="#FFFFFF" strokeWidth="4" strokeMiterlimit="10" d="M444.485,516.106
              c4.656,0.077,8.406,3.877,8.406,8.552c0,4.727-3.83,8.557-8.56,8.557c-4.725,0-8.555-3.83-8.555-8.557
              c0-4.726,3.83-8.555,8.555-8.555L444.485,516.106"/>
            <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeMiterlimit="10" d="M465.579,547.385
              c7.191,3.957,11.757,9.854,11.757,16.441c0,11.915-14.925,21.574-33.332,21.574c-18.402,0-33.326-9.659-33.326-21.574
              c0-6.59,4.561-12.487,11.76-16.443"/>
            <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeMiterlimit="10" d="M460.836,550.195"/>
            <path fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
              M469.037,1229.816c7.682-7.812,6.767-14.649-4.278-14.69c-8.659-0.033-95.75,11.838-187,7.838
              c-21.846-0.959-49.461-9.56-53.062-10.938c-4.873-1.865-14.502-6.105-18.68-6.045c-0.774,0.014-7.78,1.9-2.142,5.204
              c5.641,3.304,7.324-0.418,10.082-3.334c2.756-2.918,10.348-35.698,10.348-35.698c2.006-8.539,14.944-7.613,12.784,1.735
              c-1.52,6.573-8.06,12.852-16.104,10.916c-28.477-6.841-15.485-18.875-15.485-18.875"/>
            <path fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
              M258.009,1194.961c0,0-0.25-8-10.75-1c-12.188,8.126-8,17.25-6,17.75s7.25,0.75,15-12.25c0,0-7.5,11.5,0.5,11s10-9.256,10-9.256"/>
            <path fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
              M299.259,1168.961l-15.5,42.92l7-19.92c0,0,7.573,13.512,10.486,16.578c2.913,3.062,7.764,8.172,7.764,8.172
              s-6.55-5.748-10.078-11.617c-3.525-5.869-7.172-14.258-7.172-14.258s21.5,1.375,22.25-13.125s-17.75-11.75-26-6.75"/>
            <path fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
              M316.509,1203.461c0,0,10.75-1.75,13-8.5s-5.75-7.68-13,2.41s0.237,14.496,5,14.51c6,0.017,11.201-4.621,13.854-11.021l3.146-6.896
              c0,0-8,16.75,0,16.5s12.25-15.25,12.25-15.25s-6.498,15.093-1,15.25c8.75,0.25,11.5-4.5,13.5-14.75l-3,14.75
              c0,0,6.75-18.634,16.5-15.75c6.597,1.95-4,14.75,3.25,15.75s14-15.36,16-18.932c0,0-11.75,19.182-3,18.932s11.5-13.43,14.5-13.09
              c0,0-6.75,13.84,0,13.09s12-10.75,12-16.5s-7.75-4-7.75,1.75s4.5,15.488,15,5.494l1.5-6.494l-4.25,15.75c0,0,11.977-17.672,17-16
              c8.75,2.91-4.75,15.5,3,16s10-9.256,10-9.256"/>
            <line fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="337.509" y1="1176.461" x2="331.759" y2="1182.711"/>
            <line fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="401.509" y1="1180.711" x2="399.509" y2="1181.711"/>
            <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeMiterlimit="10" d="M351.311,1118.17
              c1.483,1.58,2.343,3.432,2.343,5.414c0,5.761-7.214,10.43-16.11,10.43s-16.109-4.668-16.109-10.43c0-1.889,0.775-3.662,2.14-5.188"
              />
            <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeMiterlimit="10" d="M354.014,1113.688
              c5.164,2.953,8.422,7.271,8.422,12.084c0,8.898-11.146,16.114-24.896,16.114s-24.896-7.216-24.896-16.114
              c0-4.646,3.039-8.834,7.898-11.771"/>
            <path fill="none" stroke="#FFFFFF" strokeWidth="4" strokeMiterlimit="10" d="M338.212,1122.238
              c-0.019,0.031-0.294,0.113-0.294,0.113s-17.444-13.012-17.444-29.943c0-16.938,14.854-17.25,17.25-17.25l0.396,0.092
              c2.397,0,17.25,0.312,17.25,17.25c0,15.58-14.938,28.006-17.299,29.854"/>
            <path fill="none" stroke="#FFFFFF" strokeWidth="4" strokeMiterlimit="10" d="M337.989,1079.793
              c4.653,0.078,8.403,3.877,8.403,8.552c0,4.725-3.827,8.554-8.555,8.554c-4.726,0-8.556-3.829-8.556-8.554
              c0-4.726,3.83-8.554,8.556-8.554L337.989,1079.793"/>
            <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeMiterlimit="10" d="M359.084,1111.07
              c7.191,3.953,11.756,9.854,11.756,16.439c0,11.915-14.924,21.574-33.331,21.574c-18.406,0-33.33-9.659-33.33-21.574
              c0-6.59,4.562-12.49,11.761-16.446"/>
            <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeMiterlimit="10" d="M354.343,1113.881"/>
            <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
              M339.63,367.086c-1.389,1.357-2.778,2.711-4.167,4.065c-0.059,0.057-0.37,0.282-0.299,0.358c0.288,0.297,0.576,0.596,0.866,0.894
              c0.704,0.716,1.4,1.435,2.105,2.154"/>
            <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
              M339.654,376.942c0.5-0.483,0.997-0.974,1.498-1.457c0.323-0.321,0.761-0.589,0.874-1.038c0.35-1.396,0.696-2.794,1.044-4.186
              c-0.379,1.486-0.755,2.974-1.138,4.461c1.506-0.333,3.014-0.662,4.518-0.997"/>
            <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
              M345.065,381.66c3.201,3.284,7.582-3.05,3.347-4.828C345.553,375.634,342.895,379.436,345.065,381.66
              C346.392,383.022,343.188,379.735,345.065,381.66z"/>
            <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
              M348.346,385.855c1.701-1.657,3.396-3.313,5.092-4.968c-0.658,2.682-1.314,5.365-1.973,8.047c1.678-1.635,3.355-3.272,5.036-4.909"
              />
            <path fill="none" stroke="#FFFFFF" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
              M339.004,359.461c6.801,7.282,13.603,14.562,20.407,21.84c1.178,1.262,2.353,2.519,3.528,3.776c0.234,0.253,0.295,0.239,0.223,0.579
              c-0.243,1.15-0.488,2.295-0.732,3.445c-0.512,2.389-1.022,4.775-1.526,7.165c-0.021,0.093-2.008,0.104-2.148,0.109
              c-1.566,0.075-3.136,0.152-4.702,0.228c-1.326,0.064-2.652,0.133-3.979,0.196c-0.347,0.013-0.61,0.14-0.82-0.133
              c-0.942-1.234-1.878-2.47-2.815-3.703c-3.738-4.908-7.478-9.82-11.211-14.73c-2.447-3.217-4.896-6.435-7.345-9.65
              c-0.167-0.22,9.156-8.468,10.094-9.327c0.139-0.129,0.28-0.259,0.422-0.387"/>
            <path fill="none" stroke="#FFFFFF" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
              M350.831,369.406c0.477-0.617,0.95-1.239,1.428-1.855c1.331,1.303,2.663,2.608,3.994,3.91c-0.931,0.685-1.856,1.369-2.785,2.053"/>
            <path fill="none" stroke="#FFFFFF" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
              M341.14,386.428c-3.473,3.261-6.945,6.522-10.417,9.784c-1.821,1.71-3.641,3.42-5.463,5.13c-0.329,0.309-0.485,0.709-0.864,0.411
              c-0.968-0.757-1.938-1.515-2.906-2.272c5.591-5.67,11.185-11.344,16.773-17.015"/>
            <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
              M328.993,854.56c-3.87,0.773-8.181,0.271-11.839-1.124c-3.564-1.356-6.643-3.73-8.926-6.781c-2.629-3.514-4.884-8.232-4.666-12.713
              c0.28-5.78,4.131-10.879,8.265-14.631c0.311-0.279,1.664-1.793,2.071-1.496c2.27,1.647,5.633,2.156,8.294,2.666
              c-4.919-0.609-10.517-2.825-13.354-7.07c-4.129-6.172-0.688-14.213,5.253-17.738c1.216,2.354,3.07,4.917,5.541,6.037
              c-3.72-2.275-6.663-6.438-6.718-10.877c-0.05-4.026,2.114-8.261,5.108-10.891c2.31-2.028,5.291-2.931,8.338-2.687
              c2.057,0.164,4.182,0.762,5.987,1.774c-1.53-0.888-3.303-1.448-5.045-1.708c-1.609-0.24-3.265-0.189-4.836,0.236
              c-0.861,0.234-1.69,0.58-2.464,1.025c-0.237,0.137-2.509,1.753-2.412,1.85c-2.166-2.166-4.18-4.62-5.711-7.273
              c-0.482-0.837-0.916-1.709-1.225-2.626c-0.218-0.648-0.133-0.643-0.765-0.743c-1.951-0.316-3.956-0.352-5.927-0.248
              c-7.729,0.402-15.122,5.583-19.355,11.891c-3.034,4.522-4.157,9.756-4.104,15.147c0.044,4.482,1.007,8.994,3.337,12.864
              c3.157,5.239,8.472,8.666,14.22,10.474c2.255,0.71,4.583,1.194,6.933,1.439c1.277,0.133,2.67,0.292,3.949,0.083
              c-10.502,0.016-22.258-4.921-26.512-15.079c-1.926-4.601-2.229-9.711-1.704-14.622c0.561-5.249,2.817-10.131,6.46-13.949
              c2.753-2.884,6.161-5.142,9.821-6.703c2.308-0.985,4.312-1.464,6.814-1.408c2.263,0.05,4.523,0.185,6.782,0.349
              c-1.237-4.602-0.044-9.909,2.334-13.92c2.28-3.849,5.849-7.229,10.006-8.951c3.369-1.396,7.093-1.623,10.654-0.916
              c3.731,0.741,8.127,2.464,10.761,5.351c1.364-1.55,3.27-2.724,5.203-3.399c4.796-1.68,9.69,0.14,13.521,3.164
              c-2.92-2.533-7.031-4.3-10.931-3.963c-7.045,0.606-12.916,8.631-11.621,15.521c0.523,2.789,2.197,6.049,4.54,7.784"/>
            <path fill="none" stroke="#FFFFFF" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
              M294.457,827.275c0.276-0.735,0.557-1.472,0.835-2.206c0.243-0.645,0.227-1.496,0.329-2.18"/>
            <path fill="none" stroke="#FFFFFF" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
              M285.721,816.446c-0.908,1.264-1.816,2.526-2.726,3.79c-0.744,1.033-1.746,1.927-2.612,2.859"/>
            <path fill="none" stroke="#FFFFFF" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
              M275.37,809.565c0.634-0.005,1.308,0.139,1.85-0.21c0.751-0.483,1.5-0.967,2.251-1.449"/>
            <path fill="none" stroke="#FFFFFF" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
              M276.846,796.933c-3.959-0.039-7.911-0.055-11.87-0.018"/>
            <path fill="none" stroke="#FFFFFF" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
              M278.912,785.871c-1.647-0.719-3.226-1.329-4.941-1.854"/>
            <path fill="none" stroke="#FFFFFF" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
              M284.821,775.914c-2.127-1.973-4.252-3.945-6.378-5.918"/>
            <path fill="none" stroke="#FFFFFF" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
              M294.153,769.445c-0.923-1.406-1.834-2.814-2.729-4.239"/>
            <path fill="none" stroke="#FFFFFF" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
              M304.944,766.649c-0.302-3.948-0.491-7.847-0.575-11.806"/>
            <path fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M459.772,1027.694
              c1.719,0.211,3.563,0.014,5.189-0.572c0.552-0.199,1.513-1.094,1.95-0.669c0.485,0.47,1.059,0.854,1.65,1.176
              c2.185,1.182,4.929,1.627,7.377,1.237c1.121-0.181,2.421-0.582,3.199-1.468c1.99,2.165,5.447,2.91,8.255,2.672
              c1.483-0.126,3.031-0.542,4.279-1.383c0.346-0.232,1.238,0.693,1.585,0.896c2.885,1.695,7.681,2.822,10.623,0.67
              c0.198-0.146,0.402-0.312,0.553-0.513c0.003-0.004,0.841,0.532,0.893,0.562c1.745,1.042,3.718,1.95,5.763,2.141"/>
            <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
              M479.234,1026.389c0.089-4.804-1.574-10.281-5.39-13.392c5.321-1.779,11.683-0.988,16.488,1.804
              c3.633,2.107,5.889,5.268,7.124,9.245c0.62,1.993,1.038,4.105,1.124,6.197"/>
            <path fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M439.17,1037.274
              c1.336,0.228,2.773,0.157,4.08-0.201c0.393-0.11,1.567-0.845,1.802-0.583c0.358,0.395,0.791,0.726,1.239,1.01
              c2.216,1.395,6.711,2.452,8.837,0.303c1.195,1.426,3.174,2.184,4.963,2.437"/>
            <path fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M496.582,1044.132
              c1.937,0.093,3.973-0.271,5.75-1.028c0.762-0.322,1.486-0.734,2.139-1.243c0.057,0.007,0.193,0.179,0.238,0.215
              c0.586,0.489,1.261,0.877,1.954,1.191c2.653,1.207,5.916,1.489,8.741,0.798c1.256-0.307,2.699-0.883,3.505-1.957
              c1.902,1.784,4.807,2.492,7.335,2.535"/>
            <g id="_x3C_NewZealand_x3E_">
              <path fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                M406.25,1722.86c-3.34,0.073-7.975,2.198-7.652-7.104c0.549-16.083,20.062-20.5,16.312-11.75s-13.75,10-13.75,10
                c8-0.5,18.75-12,10.75-12.854c-8-0.859-19.75,20.854-9,21.781c10.75,0.925,15-9.177,15-9.177c2.75-4.5,7.25-13,14.75-12.64
                c7.5,0.361,3.25,7.14,3.25,5.39c0-6.646-7-5.25-11.5-0.25s-9,13.5-5.25,16.15c3.75,2.647,10-5.65,10-5.65c3-3.75,3.699-5.812,6-7
                c4.643-2.395-5.5,12.5-1,12.648c4.5,0.146,11-9.648,11-9.648c5.375-9.125,8.125-6.5,13.75-16.5c9.528-16.938,6.5-23.25-3-8.5
                s-9.75,31.75-8.25,33.5s7,1.148,9.25-3.25c8.857-17.317,15.75-15.75,15.75-15.75c3.875-0.375,1.75,2.375,4,3.75
                c2.18,1.328,1.5-3.5-4-3.833c-5.5-0.332-18.5,16.833-12,19.825s15-11.492,15-11.492s-9,12-1,11.479c8-0.522,11-13.979,13-14.979
                s-4.5,15-3.5,14.979c1-0.022,3-10.479,13-15.979c8.23-3.17,4,9.892,4,10.5c0,11,10.5,5.25,15.184-7
                c4.941-13.25,11.729-6.545,11.729-6.545c0.964-2.206-5.911-5.956-11.411,5.545c-6.5,14.5,2.253,13.713,9,5.5
                c5.75-7,18.104-31.733,16-32c-1-0.125-18,35.5-13,39.25s13-8.25,13-8.25c6-10.757,16.5,15.364-8.5,24.746
                c-38,15-82.344-1.387-153.973,1c-75.027,2.5-124.702,16.5-124.702,38.5"/>
              <path fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                M368.91,1688.51c0,0-1.083-10.764,5.5-10.201c6.479,0.559,16.5,5.951,22.75,4.201s-4.155,0.359-5.5,3.75
                c-1.921,4.854-9.75,23.75-21.25,35s-16.856-8.5-2.556-2.5c14.306,6,20.056,6.25,24.556,2.5s0,4.066-8,3.533
                s-12.25-4.783-12.25-4.783s15.333-17.757,25-37.5c0,0-4.896,0.469-6.75-0.5c-1.853-0.969-5.585-3.486-11.212-4.154
                c-8.538-1.013-16.788,5.154-10.038,10.654"/>
              <path fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                M346.16,1712.511c0,0-2.5,6.25-6.75,4.25s-3.75-7.75-3.5-11.5s6-3.75,2.25,1.5s-8.75,17.082-14.25,16.416s0-15.916,0-17.666
                s4.455-2.205,1,2c0,0-8,15.082-13,15.666s-3.75-14.416,0-18.666s3.134,1.398,0.5,3.75c-3.229,2.888-6.25,14.138-18,14.943
                s-6.75-11.693-2.75-16.943s9-6.786,11.5-5.019c2.5,1.769,1.333,9.435-10.5,12.769c0,0,8.25-2.5,10.5-7.75s-3-8.75-10.5,3.25
                c-5.072,10.802-4.75,14.082,3.5,13.666"/>
              <path fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                M289.91,1679.309c0,0-14.75,36.414-13.5,43.937s-2.667-40.646-6.75-43.937c-5.108-4.107-18.167,8.451-11,13.201
                c0,0-0.25-3.5,0.917-6.25s6.083-9.75,8.583-3c0.129,0.348-14.062,43.038-13.667,42.75c3.833-2.77,14.078-43.255,14.183-42.838
                c2.383,9.885,5.417,39.133,7.234,40.897c2.75,1.938,9.383-31.812,13.188-42.812"/>
            </g>
            <path fill="none" stroke="#FFFFFF" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" d="M322.534,1507.58
              c0,0,3.39,13.914,13.154,30.915c9.766,17.001,14.971,23.692,14.971,23.692"/>
            <path fill="none" stroke="#FFFFFF" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" d="M472.488,1835.105
              c0,0,0.123-14.32-5.178-33.195c-5.302-18.876-8.709-26.639-8.709-26.639"/>
              <path fill="none" stroke="#FFFFFF" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" d="M458.284,1942.016
		          c0,0,6.131-12.942,9.254-32.297c3.122-19.356,3.293-27.832,3.293-27.832"/>
            <path fill="none" stroke="#FFFFFF" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" d="M283.764,1393.08
              c2.454,13.851,6.035,28.302,10.944,43.354c2.336,7.16,4.941,13.89,7.799,20.23"/>
            <path fill="none" stroke="#FFFFFF" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" d="M285.133,1267.008
              c-3.467,15.604-6.152,32.338-7.873,50.289c-0.819,8.536-1.213,16.746-1.219,24.654"/>
            <g>
              <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeMiterlimit="10" d="M397.311,1634.17
                c1.483,1.58,2.343,3.432,2.343,5.414c0,5.761-7.214,10.43-16.11,10.43s-16.109-4.668-16.109-10.43c0-1.889,0.775-3.662,2.14-5.188"
                />
              <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeMiterlimit="10" d="M400.014,1629.688
                c5.164,2.953,8.422,7.271,8.422,12.084c0,8.898-11.146,16.114-24.896,16.114s-24.896-7.216-24.896-16.114
                c0-4.646,3.039-8.834,7.898-11.771"/>
              <path fill="none" stroke="#FFFFFF" strokeWidth="4" strokeMiterlimit="10" d="M384.212,1638.238
                c-0.019,0.031-0.294,0.113-0.294,0.113s-17.444-13.012-17.444-29.943c0-16.938,14.854-17.25,17.25-17.25l0.396,0.092
                c2.397,0,17.25,0.312,17.25,17.25c0,15.58-14.938,28.006-17.299,29.854"/>
              <path fill="none" stroke="#FFFFFF" strokeWidth="4" strokeMiterlimit="10" d="M383.989,1595.793
                c4.653,0.078,8.403,3.877,8.403,8.552c0,4.725-3.827,8.554-8.555,8.554c-4.726,0-8.556-3.829-8.556-8.554
                c0-4.726,3.83-8.554,8.556-8.554L383.989,1595.793"/>
              <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeMiterlimit="10" d="M405.084,1627.07
                c7.191,3.953,11.756,9.854,11.756,16.439c0,11.915-14.924,21.574-33.331,21.574c-18.406,0-33.33-9.659-33.33-21.574
                c0-6.59,4.562-12.49,11.761-16.446"/>
            </g>
            <g id="_x3C_kiwi_x3E_">
              <path fill="none" stroke="#FFFFFF" strokeMiterlimit="10" d="M477.173,1827.062l2.603,4.185l0.781-4.434l-0.781,4.434l0.193,0.311
                l7.61-1.473c0,0,5.054,6.369,11.425,5.284c6.369-1.084,11.922-5.358,13.429-12.143c1.507-6.786,3.765-6.678,5.801-7.523
                c4.125-1.707,5.036-6.907,4.631-9.095c-0.406-2.189-2.93-8.228-10.576-6.225c0,0,1.764,2.432-0.094,3.763
                c-1.858,1.329-3.719-0.199-3.719-0.199s-4.632-3.187-18.083-5.039c0,0,10.437-2.328,21.652,1.422c0,0,2.031,1.588,0.758,3.152
                c-1.509,1.854-2.995,1.378-4.217,0.568c0,0-0.942,4.053-5.636,5.745c-4.69,1.688-9.297,0.274-12.977,3.383
                c-3.21,2.71-4.321,6.404-4.352,9.47l-7.171,1.073l-0.15-0.265l0.714-4.516l-0.714,4.516l-2.296-4.069c0,0,2.449,4.322,2.449,4.338
                c0,0.088,7.166-1.167,7.17-1.074c0.089,2.588,0.907,5.129,1.843,7.357"/>
              <path fill="none" stroke="#FFFFFF" strokeMiterlimit="10" d="M513.716,1808.3c0.188,1.011,1.522,1.222,2.105,1.115
                c0.583-0.108,1.775-0.587,1.536-1.871c-0.159-0.87-1.213-1.179-1.988-1.035C514.594,1806.651,513.556,1807.432,513.716,1808.3z"/>
            </g>
            <path id="_x3C_Bull_x5F_flag_x3E_" fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
              M218.036,1573.499l14.357-16.771L218.036,1573.499l-3.271-11.891l21.12-5.853l-21.12,5.853l-1.968-7.145l21.053-5.793l-21.053,5.793
              l-3.206-11.656l21.64,6.585l-21.64-6.585c0,0-0.891-2.356-0.008-3.633c0.886-1.272,1.734-1.479,1.734-1.479l21.424,6.914
              l-21.424-6.914l18.166-4.999l4.364,15.977l-4.364-15.977l7.433-2.047l4.396,15.904l-4.396-15.904l18.374-5.055l-15.104,16.883
              l15.104-16.883c0,0,0.958-0.184,1.783,0.281c1.058,0.597,1.619,3.422,1.619,3.422l-14.936,16.575l14.936-16.575l3.174,11.538
              l-20.556,5.717l20.556-5.717l1.98,7.203l-20.682,5.711l20.682-5.711l3.148,11.444l-20.915-6.536l20.915,6.536
              c0,0,0.78,2.426,0.187,3.478c-0.547,0.972-1.572,1.426-1.572,1.426l-21.4-6.828l21.398,6.83l-18.145,5.048l-4.296-15.688
              l4.296,15.688l-7.256,2.024l-4.322-15.707l4.322,15.707l-11.813,3.288l-1.015,8.311l-5.281-6.56l14.848-16.898l-14.849,16.899
              c0,0-0.897-0.068-2.108-0.751C218.777,1575.068,218.036,1573.499,218.036,1573.499"/>

            </g>

          </svg>
      
    )
  }
}