
/**
 * Masonry grid
 * Srouce : https://github.com/eiriklv/react-masonry-component
 */

import React from 'react';
import Masonry from 'react-masonry-component';
import {ImageLightbox} from './Lightbox';


// -------------------------
// Init Masonry params
const maxWidth            = 300; // colone grid width en px
const masonryOptions      = { 
  transitionDuration: 200
  /*itemSelector: ".gallery",
  columnWidth: 300*/
};
const imagesLoadedOptions = {  }
// -------------------------

const style={
  marginLeft: 1,
  marginRight: 1,
  marginTop: 0,
  marginBottom:0
}


// -------------------------
// Build child html gallery element width Lightbox component
const childHtmlElement = (element)=>{
  
    let lightbox = element.lightbox.length > 0 ? <ImageLightbox images={element.lightbox} content={element.content} /> : <ImageLightbox images={[element]} content={element.content} />;
  
    return (
        <li key={element.id}  style={style} data-filter={element.category.map( (e,i)=>{ return i>0 || i<element.length ? e.slug+',' : e.slug } )} >
            
            <img src="data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" data-src={element.src} width={element.width} height={element.height} alt={element.caption} />
            {lightbox}
        </li>
    )
}
// -------------------------


export class MasonryGrid extends React.Component {  

  render() {

    setTimeout(() => {
      this.masonry.layout();  
    }, 800);    
    
        // -------------------------
        // Build children elements
        const childElements = this.props.photos.map(function(element){
      
            // Re-set imgs sizes
            let widthPerct = (element.width/maxWidth) * 100; // get percent real width from maxWidth
            let height = Math.round((element.height/widthPerct) * 100);
            element.height = height;
            element.width = maxWidth;

            // Build children html
            return childHtmlElement(element);      

        });
        // End children elements
        // -------------------------
  

      // -------------------------
      // Return Masonry Gallery
      return (
          <Masonry
              className={'gallery__children-container'} // default ''
              elementType={'ul'} // default 'div'
              options={masonryOptions} // default {}
              imagesLoadedOptions={imagesLoadedOptions} // default {}  
              ref={c => (this.masonry = this.masonry || c.masonry)}                             
          >
              {childElements}
          </Masonry>
      );
      // -------------------------

  } // End render
} // End Class MasonryGallery
