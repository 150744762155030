import React from 'react';
import {BrowserRouter, Route, Switch } from 'react-router-dom';

import Home from './Home';
import Historic from './Historic';

export class Routes extends React.Component{

  render() {
    return (
      <BrowserRouter>        
          {/* <Navigation/>*/}
          <Switch>{/*The switch is to don't duplicate error's content bug on the screen */}
            <Route path="/" component={Home} exact />{/*exact is to no duplicate the home content '/' */}
            <Route path="/historic" component={Historic} />
            {/*<Route component={Error} />*/}
          </Switch>        
      </BrowserRouter>
    );
  }

}