import React from 'react';
import {ImageLightbox} from '../modules/gallery/components/Lightbox';
import {GetPhotos} from '../modules/gallery/components/apiCall/containers/GetPhotos';
import {Skills} from './Skills';

var categoriesId = "3,7,6,8";
let urlRoot      = "https://wp.derrecalde.fr/";
var urlApi       = urlRoot+"wp-json/wp/v2/posts?_embed&per_page=20&categories="+categoriesId;

async function buildDataPoints(url){
  return await GetPhotos(url, 'thumbnail').then(
    (data)=>{
      
      let dataFiltred = []      
      
      data.map( (img) => {
        
        // Store data in array on categ key's name match
          if(dataFiltred[img.category[0].slug]){
            dataFiltred[img.category[0].slug].push(img)
          }else{
            // Init array
            dataFiltred[img.category[0].slug] = [img]
          }                     
          return true
      })
     
     // Img by filter
     return dataFiltred

    }
  )
}


 // ---- Push skill ---- //
 function pushSkill(valSkill){
  let nodeLi   = document.createElement("li");               // Create a <li> node
  let textnode = document.createTextNode(valSkill);          // Create a text node
  nodeLi.appendChild(textnode);                              // Append the text to <li>
  document.querySelector('.skills__container').appendChild(nodeLi); 

  setTimeout(() => {  
    nodeLi.style.height = 15+"px"
  }, 10);
}
// ---- //


// ---- Parse skills to push ---- //
function parseAndPushSkills(skills){
  let nbrTot   = document.querySelector('.skills__container').childElementCount
  let nbrToAdd = skills.length

  if ( nbrTot <= nbrToAdd ){ // Limit loops iterations

  skills.forEach( (e) => {
    
    let valSkilltoPush = e;                                             
    let pushState = false
    
    // Check if already exist
    document.querySelector('.skills__container').childNodes.forEach(
      (e) => {                
        
        if ( e.innerHTML === valSkilltoPush ){
          pushState = true
        }

      }
    )  
    
    // if doesn't exist
    if( pushState === false ) {    
      pushSkill(valSkilltoPush)
    }

  });  

  }
}
// ---- //


function scrollPoping(points){

  let i = 0
  let WorkPoints = {}
  points.map( (f)=>{ f.map( (e) => { i++; WorkPoints[i] = e; return true; } ) })  
  let nbrPoints = i
  let popedPrev = 0
  var lastScrollTop = 0;
  let skills = []

  window.addEventListener("scroll", poping);

  function poping() {
    
    if (window.location.pathname === '/historic') {

      let scrollpercent  = (document.body.scrollTop + document.documentElement.scrollTop) / (document.documentElement.scrollHeight - document.documentElement.clientHeight) * 100;
      let poped = Math.round(scrollpercent * nbrPoints / 100)

      // -------------------------
        // Scroll direction detection
        let st = window.pageYOffset || document.documentElement.scrollTop;
        let scrollDown = st > lastScrollTop ? true : false ;
        if (scrollDown){
          // console.log('ScrollDown ')
        } else {
          // console.log('ScrollUp ')
        }
        
        lastScrollTop = st <= 0 ? 0 : st;
        // -------------------------
        // console.log(scrollpercent)
        // Show all
        if (scrollpercent >= 95) {
          // console.log('Show'+nbrPoints)
          for (let l = 1; l <= nbrPoints; l++) { 
            document.querySelector(".bubbles__points[data-id='"+i+"']").classList.add("bubbles__points--show")
          }
        }

        // Hide all
        if (scrollpercent <= 5) {
          // console.log('Hide'+nbrPoints)
          for (let l = 1; l <= nbrPoints; l++) { 
            document.querySelector(".bubbles__points[data-id='"+i+"']").classList.remove("bubbles__points--show")
          }
        }
        
        
      if ( poped !== popedPrev && scrollDown ) {    

        // Add a workPoint
        document.querySelector(".bubbles__points[data-id='"+poped+"']").classList.add("bubbles__points--show")
        
        // Add the skills in array
        WorkPoints[poped].tags.map( (e) => { 
          if (skills.indexOf(e.name) === -1) { 
            skills.push(e.name) 
          } 
          return true
        })

        // Push skills
        parseAndPushSkills(skills);               

      }

      if( poped !== popedPrev && !scrollDown){
        document.querySelector(".bubbles__points[data-id='"+poped+"']").classList.remove("bubbles__points--show")
      }

    }
    
  }

}


export class WorkPoints extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      data: [],
      content:""
    }
  }


  componentWillMount(){
    buildDataPoints(urlApi).then(
      (e)=>{
        this.setState({ data: e })
      }
    );
  }

  
  componentDidUpdate(prevProps, prevState){    

    if (this.state.data !== prevState.data) {

      // ---- Set sizes containers filter ---- //
      let container = document.querySelector('.bubbles');

      let i = 0
      let p = 0
      this.state.data.map( e => i++)
      let containerHeight = container.offsetHeight
      let containerWidth = container.offsetWidth
      let sizeContainers = Math.round(containerHeight / i)

      // ---- Styles containers Filters ---- //
      let stylesContainers = {
        height: sizeContainers+'px',
        width: 100+'%'
      }
      // ---- //
      
      // console.log(sizeContainers)
      // ---- //


      // ---- Build html JSX content ---- //
      let content = this.state.data.map( (categ, index)=>{
      
        // Container filter JSX
        return <div data-filter={index} key={index} className="bubbles__containers-filter" style={stylesContainers} >{
          categ.map(
            
            (e)=>{

              // ---- Styles points random positions ---- //
              let stylePoint = {
                left: Math.floor((Math.random() * (containerWidth/1.8) ) + 1),
                top:  Math.floor((Math.random() * sizeContainers/1.9) + 1)
              }
              // ---- //
              
              // Set Lightbox
              let lightbox = e.lightbox.length > 0 ? <ImageLightbox images={e.lightbox} /> : <ImageLightbox images={[e]} />;

              // Points JSX
              p++
              return <div className='bubbles__points' key={e.id} style={stylePoint} data-id={p} ><img src={e.src} alt={e.caption} />{lightbox}</div>
            }
          )         
        }</div>
        
      })
      // ---- //
      
      //console.log({content})
      this.setState({content:content})      
      scrollPoping(this.state.data)
    }
    
  }

  render(){
    
    return(
      <div>
        <div className="bubbles" >{this.state.content}</div>
        <Skills />
      </div>
    )
  }
}

export default WorkPoints;