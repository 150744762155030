/**
 * Lightbox images
 * Source : https://www.npmjs.com/package/react-image-lightbox
 */

import React, { Component } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";


// -------------------------
// Build output html element
const styles = {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'none',
            border: 0,
            cursor: 'pointer'
          }

const returnedElement = (e) => {  
  return ( 
        <button type="button" style={styles} onClick={() => e.setState({ isOpen: true })} aria-label="Details" ></button>
        )    
};
// -------------------------


export class ImageLightbox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 0,
      isOpen: false
    };
  }

  render() {

    // Init mains vars
    const { photoIndex, isOpen } = this.state;
    let images = this.props.images;
    let content = <div dangerouslySetInnerHTML={{ __html: this.props.content}} />;
    return (
      <div>

        {returnedElement(this)}

        {isOpen && (
          
          <Lightbox
            mainSrc={images[photoIndex].src}            
            nextSrc={ images.length > 1 ? images[(photoIndex + 1) % images.length].src : null}
            prevSrc={ images.length > 1 ? images[(photoIndex + images.length - 1) % images.length].src : null}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length
              })
            }
            imageCaption={content}
          />
        )}
      </div>
    );
  } // End render
} // End class Lightbox
