import React from 'react';


export class Skills extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      data: [],
      content:""
    }
  }

  render(){
    
    
    return(
      <div className="skills__container" >
      </div>
    )
  }
}

export default Skills;