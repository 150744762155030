import React from 'react';
import {HideGallery} from './HideGallery';
import {Intro} from '../modules/svg/containers/Intro';
import {GalleryPhotos} from '../modules/gallery/containers/GalleryPhotos';
import {Header} from './Header';

var categoriesId = "3,6,7,8";
var urlApi = process.env.REACT_APP_API_URL+"posts?_embed&per_page=30&categories="+categoriesId;

export class Home extends React.Component{
  render(){
    
    // Set title
    document.title = "Derrecalde : Home";

    return(
      <div className="App" >        
        <Header />
        <main>
          <Intro /> 
          <HideGallery />
          <GalleryPhotos url={urlApi} categories={categoriesId} />         
        </main>   
      </div>
    )
  }
}

export default Home;