import React from "react";
import {GetPhotos} from "../components/apiCall/containers/GetPhotos";
import {MasonryGrid} from '../components/MasonryGrid';
import {Filters} from '../components/Filters';
import {SortFilter} from '../components/FiltersSort';


// Init mains vars
var html = "";

export class GalleryPhotos extends React.Component{

  constructor(props){
    super(props)
    let loadingImg = <img src={require('../../../assets/img/logo.svg')} className="App-logo" alt="Loading..." />;
    this.state = { 
                  data: "",
                  gallery: loadingImg
                };
    this.handleClick  = this.handleClick.bind(this)
    this.deferingImgs = this.deferingImgs.bind(this)
  }

  handleClick(e){   
    SortFilter(e)
    html = <MasonryGrid photos={this.state.data} direction={"column"} />
    this.setState({
      gallery: html
    });
     
  }

  /* Defering loading :: https://varvy.com/pagespeed/defer-images.html
  * src = the real src is a base 64 fake
  * the real src is a data-src 
  * example : <img src="data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" data-src="<?php echo get_the_post_thumbnail_url(get_the_id(),'large'); ?>">
  */
  deferingImgs(){
    let imgDefer = document.getElementsByTagName('img');
    
    for (var i=0; i<imgDefer.length; i++) {
      if(imgDefer[i].getAttribute('data-src')) {
        imgDefer[i].setAttribute('src',imgDefer[i].getAttribute('data-src'));
      } 
    }

  }

  componentDidMount(){ 
      // Loading photos data through Api, then store the results data in a data state
      GetPhotos(this.props.url).then(
          (res)=>{
              this.setState({
                  data: res
              });
          }
      );   
  }


  componentDidUpdate(prevProps, prevState){
      
      if (this.state.data !== prevState.data) {
          // If state gallerry is loaded, use gallery in component and store it in state
          html = <MasonryGrid photos={this.state.data} direction={"column"} />
          this.setState({
              gallery: html
          });                             
      } 

      if (this.state.gallery !== prevState.gallery) {
        this.deferingImgs()  
      }
            
  }


  render(){
    //console.log( this.state.gallery )
    //console.log( JSON.stringify(this.state.gallery) )
    
      return(
          <div className="gallery" >
              <Filters categories={this.props.categories} handleClick={this.handleClick} />
              {this.state.gallery}
          </div>
      )
  }


}  
