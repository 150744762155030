/**
 * Set SVG to draw on auto drawing function
*/

import React from 'react';
import {SvgIntro} from '../components/SvgIntro';       // Svg to draw
import {AutoDrawSvg} from '../components/AutoDrawSvg'; // Auto drawing function

// Init <path/> selector & drawing options
const selectPaths = () => {
  let draws    = {
    element: document.querySelectorAll('#intro > g > path'),
    speedDraw: 25,
    displaySpeed: 2200
  }
  
  return [draws]
}



export class Intro extends React.Component{  
  constructor(props){
   super(props)
   this.state = {
      selector: {}
   }
  }


  componentDidMount(){
    this.setState({ selector: selectPaths() })
  }


  render(){
    
    return(
      <div className="svg__intro" >
        <AutoDrawSvg svg={SvgIntro} selector={this.state.selector} />
      </div>
    )
  }


}