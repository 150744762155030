import React from 'react';
import {Call} from './Call'
import Navigation from './Navigation';


export class Header extends React.Component{  
  state = {links:""}

  componentDidMount(){

  console.log(process.env)
  var jsonLocal = require('./../dataHeader.json');

  if (jsonLocal.length > 1){    
    // Get local json    
    console.log('Got from local')
    let linksHtml = jsonLocal.map( element => {
      return (
      <li key={element.id} >
        <a href={element.acf.external_link} title={element.title.rendered} target="_blank" rel="noopener noreferrer" >
          <img src={element.media_details.sizes.full.source_url} alt={element.alt_text} />
        </a>              
      </li>)
    });

    this.setState({links:linksHtml});

  } else {
    console.log('Got from api')

    let mediaIds = '326,329'
    let url      = process.env.REACT_APP_API_URL+'media/?_embed&include='+mediaIds+'&orderby=id&order=asc&per_page=2'
    let call     = new Call();

    call.callApi(url).then(
      (respond) => {

        var linksHtml = ""

        if (respond !== false){
          // console.log(respond)
          
          // Success
          linksHtml = respond.map( element => {
            return (
            <li key={element.id} >
              <a href={element.acf.external_link} title={element.title.rendered} target="_blank" rel="noopener noreferrer" >
                <img src={element.media_details.sizes.full.source_url} alt={element.alt_text} />
              </a>              
            </li>)
          });

        } else {

          console.log('Error calling API')
          linksHtml = <p>'Error'</p>

        } // End if

        this.setState({links:linksHtml})

      }) // End then respond

    } // End if local data

  } // End componentDidiMount

  render(){
    
    return(
      <header className="header">
        <ul className="header__links">
          {this.state.links}
        </ul>
        <Navigation />
      </header>
    )
  }
}