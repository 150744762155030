import React from 'react';

export class Call extends React.Component{

  callApi = async (url)=>{

    // -------------------------
    // NATIVE fetch METHODE CALL
    let apiCall = await fetch( url ).then( respond =>{

      if( respond.ok ){
        return respond.json();
      }else{
        return false
      }

    });
    // -------------------------

    return apiCall
    
  }


}